import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'

import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'

import Budget from 'common/components/job_wizard/steps/Budget'
import SiteVisit from 'common/components/job_wizard/steps/SiteVisit'

import coreUtils from 'common/src/utils/coreUtils'

const Schedule = {
  displayName: 'Schedule',

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion) {
    onStepProgression()
  },

  component: React.createClass({
    getInitialState: function() {
      const job = data;

      return {
        schedule:     job.schedule,
        scheduleDate: job.scheduleDate
      }
    },

    validator: undefined,

    componentDidMount: function() {
      $('#job-modal').bootstrapValidator({ /* */ })
      this.validator = $('#job-modal').data('bootstrapValidator')

      $('#schedule-radio-group').radioButtonGroup({activeRowClass: 'active'})

      $('#schedule-date').datepicker({
        container: '#job-modal',
        format: 'dd/mm/yyyy',
        disableTouchKeyboard: true,
        todayHighlight: true,
        orientation: 'top left',
        startDate: '+1d'
      }).on('changeDate', function(event){
        $(this).datepicker('hide')
        this.validator.$form.data('bootstrapValidator').revalidateField("scheduleDate") //revalidating removes error message
        actions.updateScheduleDate(event.currentTarget.value)
      })

      this.setupValidation()
    },

    setupValidation: function() {
      const validator = this.validator;

      $(function() {
        validator.addField(
          'schedule',
          {
            validators: {
              notEmpty: {
                message: 'Please select a schedule for when you want the job done'
              }
            }
          }
        )
        validator.addField(
          'scheduleDate',
          {
            validators: {
              notEmpty: {
                message: 'Please select a date for when you want the job done'
              }
            }
          }
        )
        validator.$form.on('error.field.bv', function(e, data) {
          data.element
            .data('bv.messages')
            .find('.help-block[data-bv-for="' + data.field + '"]').show();
        })
      })
    },

    handleClick: function(e) {
      const $selectedItem = $(e.currentTarget).find('[type="radio"]')[0]
      actions.updateSchedule($selectedItem.value)
      hideScheduleDatePanel()

      function hideScheduleDatePanel() {
        $('#schedule-panel').addClass('fade hidden')
      }
    },

    handleDateClick: function(e) {
      const actions = actions
      actions.updateSchedule("on_a_particular_date")
      actions.updateScheduleDate($('#schedule-date').val())
      $('#schedule-panel').removeClass('fade hidden')
      coreUtils.scrollModalTo('#schedule-panel')
    },

    handleScheduleRadioClick: function() {
      this.validator.$form.data('bootstrapValidator').revalidateField("schedule") //revalidating removes error message
    },

    render: function() {
      const radioButtonVisible = "col-xs-2 col-sm-2 circle"
      let radioButtonHidden = `${radioButtonVisible} hidden`

      return (
        <div>
          <div className="form-group" onClick={this.handleScheduleRadioClick}>
            <h3 className="mb15 mt10">When do you need the job done?</h3>
            <div className="radio-button-box minor-bold" id="schedule-radio-group">
              <div className={"radio this-week " + ((this.state.schedule === "this_week") ? "active" : "")} onClick={this.handleClick}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">This week</div>
                  <div className={(this.state.schedule === "this_week") ? radioButtonVisible : radioButtonHidden}>
                    <i className="ficon-circle-x pull-right" />
                  </div>
                  <input type="radio" defaultChecked={(this.state.schedule === "this_week") ? 'checked' : ''} id="this_week" name="schedule" value="this_week" className="form-control invisible"/>
                </div>
              </div>
              <div className={"radio next-couple-of-weeks " + ((this.state.schedule === "next_couple_of_weeks") ? "active" : "")} onClick={this.handleClick}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Next couple of weeks</div>
                  <div className={(this.state.schedule === "next_couple_of_weeks") ? radioButtonVisible : radioButtonHidden}>
                    <i className="ficon-circle-x pull-right" />
                  </div>
                  <input type="radio"  defaultChecked={(this.state.schedule === "next_couple_of_weeks") ? 'checked' : ''} id="next_couple_of_weeks" name="schedule" value="next_couple_of_weeks" className="form-control invisible"/>
                </div>
              </div>
              <div className={"radio asap-urgent " + ((this.state.schedule === "asap_urgent") ? "active" : "")} onClick={this.handleClick}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Urgently</div>
                  <div className={(this.state.schedule === "asap_urgent") ? radioButtonVisible : radioButtonHidden}>
                    <i className="ficon-circle-x pull-right"/>
                  </div>
                  <input type="radio" defaultChecked={(this.state.schedule === "asap_urgent") ? 'checked' : ''} id="asap_urgent" name="schedule" value="asap_urgent" className="form-control invisible"/>
                </div>
              </div>
              <div className={`radio on-a-particular-date ${((this.state.schedule === 'on_a_particular_date') ? 'active' : '')}`} href="#schedule-panel" role="tab" data-toggle="tab" onClick={this.handleDateClick} >
                <div className="row">
                  <div className='col-xs-10 col-sm-10'>On a Particular Date</div>
                  <div className={(this.state.schedule === 'on_a_particular_date') ? radioButtonVisible : radioButtonHidden}>
                    <i className='icon-circle-x pull-right' />
                  </div>
                  <input type="radio" defaultChecked={(this.state.schedule === 'on_a_particular_date') ? 'checked' : ''} name="schedule" value="on_a_particular_date" className="form-control invisible" />
                </div>
              </div>
            </div>
          </div>

          <div className="tab-content">
            <div id="schedule-panel" className={"tab-pane form-group " +((this.state.schedule === "on_a_particular_date") ? "active" : "")} role="tabpanel" >
              <h3 className="mb15 mt30">Best date for work</h3>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-addon bg-white"><i className="ficon-calendar"></i></div>
                  <input id="schedule-date" name="scheduleDate" ref="schedule-date" type="textbox" className="form-control" placeholder="What Day?" defaultValue={this.state.scheduleDate} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default Schedule
