import React from 'react'

import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'

const Origin = React.createClass({
  getInitialState: function() {
    return {
      originName: data.originName
    }
  },

  originNameChanged: function(event) {
    let originName = $(event.currentTarget).find('[type="radio"]')[0].value
    actions.updateOriginName(originName)
    this.setState({ originName: originName })
  },

  isHidden: function() {
    if (!location.host.includes('admin.')) {
      return 'hidden'
    }
  },

  render: function() {
    const radioButtonVisible = "col-xs-2 col-sm-2 circle"
    let radioButtonHidden = `${radioButtonVisible} hidden`

    return (
      <div className={this.isHidden()}>
        <h3 className="mb15 mt30">Track as: </h3>
        <div className="form-group">
          <div className="col-xs-12">
            <div className="radio-button-box minor-bold">
              <div className={`radio ${(this.state.originName === "ss") ? "active" : ""}`} onClick={this.originNameChanged}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">ServiceSeeking</div>
                  <div className={(this.state.originName === "ss") ? radioButtonVisible : radioButtonHidden}>
                    <i className="ficon-circle-x pull-right"></i>
                  </div>
                  <input type="radio" defaultChecked={(this.state.originName === "ss") ? 'checked' : ''} id="origin-name" name="origin-name" value="ss" className="form-control invisible" />
                </div>
              </div>
              <div className={"radio " + ((this.state.originName === "service_central") ? "active" : "")} onClick={this.originNameChanged}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Service Central</div>
                  <div className={(this.state.originName === "service_central") ? radioButtonVisible : radioButtonHidden}>
                    <i className="ficon-circle-x pull-right"></i>
                  </div>
                  <input type="radio" defaultChecked={(this.state.originName === "service_central") ? 'checked' : ''} id="origin-name" name="origin-name" value="service_central" className="form-control invisible" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default Origin