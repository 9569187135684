const steps = {
  "130": { // New Home Builders
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "What type of building do you need done?",
            "jobDescriptionKey": "Type of building",
            "validation": {
              "groupName": "type_of_building",
              "message": "Please specify the type of building."
            },
            "options": [
              {
                "value": "New home",
                "text": "New home"
              },
              {
                "value": "Home renovation",
                "text": "Home renovation"
              },
              {
                "value": "Bathroom renovation",
                "text": "Bathroom renovation"
              },
              {
                "value": "Shop-fit-out",
                "text": "Shop-fit-out"
              },
              {
                "value": "Building inspection",
                "text": "Building inspection"
              },
              {
                "value": "Other",
                "text": "Other"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you need help with council approval?",
            "jobDescriptionKey": "Council approval",
            "validation": {
              "groupName": "council_approval",
              "message": "Please specify whether you need help with council approval."
            },
            "options": [
              {
                "value": "Yes, I need help getting council approval",
                "text": "Yes, I need help getting council approval"
              },
              {
                "value": "No, I don't need council approval for this job",
                "text": "No, I don't need council approval for this job"
              },
              {
                "value": "No, I already have council approval",
                "text": "No, I already have council approval"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you have plans drawn up?",
            "jobDescriptionKey": "Plans",
            "validation": {
              "groupName": "plans_drawn_up",
              "message": "Please specify if you have plans drawn up."
            },
            "options": [
              {
                "value": "No plans required",
                "text": "No plans required"
              },
              {
                "value": "No, I need a draftsman/architect",
                "text": "No, I need a draftsman/architect"
              },
              {
                "value": "Yes, I already have the plans drawn up",
                "text": "Yes, I already have the plans drawn up"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you need materials, fixtures and fittings?",
            "jobDescriptionKey": "Materials, fixtures and fittings",
            "validation": {
              "groupName": "materials_fixtures_fittings",
              "message": "Please specify if you need any materials, fixtures and fittings."
            },
            "options": [
              {
                "value": "Yes, I need materials for the job",
                "text": "Yes, I need materials for the job"
              },
              {
                "value": "No, I already have the materials for this job",
                "text": "No, I already have the materials for this job"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
