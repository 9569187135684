import React from 'react'

import coreUtils from 'common/src/utils/coreUtils'

const Eula = {
  handleClick: function(e) {
    if (coreUtils.urlInspector.hasParam('platform')) {
      coreUtils.appMsg({showEULA: e.target.href}) // href from below
      e.preventDefault();
    }
  },

  renderApp: function(setStyle) {
    return (
      <div id="jw-eula" style={setStyle}>
        By pressing 'Continue' you agree to receive lead alerts and marketing messages by email & SMS and to our <a className='no-wrap' href='https://www.serviceseeking.com.au/blog/terms-and-conditions/' onClick={this.handleClick} target='_blank'>Terms & Conditions</a>
      </div>
    )
  }
}

export default Eula
