const steps = {
  "155": { //Home Handiwork
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "What specific jobs do you need done? (Tick all that apply)",
            "jobDescriptionKey": "Services needed",
            "validation": {
              "groupName": "services_needed",
              "message": 'Select "Other" if none of the above apply.'
            },
            "options": [
              {
                "subcategoryId": "2481",
                "value": "Painting",
                "text": "Painting"
              },
              {
                "value": "General Repairs",
                "text": "General Repairs"
              },
              {
                "subcategoryId": "168",
                "value": "Basic Plumbing",
                "text": "Basic Plumbing"
              },
              {
                "subcategoryId": "569",
                "value": "Tiling",
                "text": "Tiling"
							},
              {
                "subcategoryId": "2499",
                "value": "Plastering",
                "text": "Plastering"
              },
              {
                "subcategoryId": "2324",
                "value": "Carpentry",
                "text": "Carpentry"
              },
              {
                "subcategoryId": "2723",
                "value": "Rubbish Removal",
                "text": "Rubbish Removal"
              },
              {
                "value": "Other",
                "text": "Other"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
