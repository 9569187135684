import React from 'react'
import actions from 'common/components/job_wizard/data/actions'
import store from 'common/components/job_wizard/data/store'
import LocationInput from 'common/components/LocationInput'
import coreUtils    from 'common/src/utils/coreUtils'

const Location = React.createClass({

  getInitialState: function() {
    return store.getSuburb()
  },

  componentDidMount: function() {
    this.onValidation()
    store.addChangeListener(this.updateState)
  },

  componentWillUnmount: function() {
    store.removeChangeListener(this.updateState)
  },

  updateState: function(data) {
    this.setState(store.getSuburb())
  },

  handleAutocompleteSelection: function( event, response ) {
    actions.updateLocation({ id: response.item.id, value: response.item.value })
  },

  handleLocationChange: function(event) {
    actions.updateLocation({ id: 0, value: event.target.value })
  },

  onValidation: function() {
    let validator = $('#job-modal').data('bootstrapValidator')

    validator.addField(
      'location',
      {
        threshold: 99999,
        validators: {
          callback: {
            callback: (fieldValue) => {
              return this.state.suburb_id !== 0
            },
            message: "Please enter your Postcode or Suburb and select the matching one from the drop-down menu"
          }
        }
      }
    )
  },

  render: function() {
    return (
      <LocationInput
        suburb={this.state.suburb}
        suburbId={this.state.suburb_id}
        handleAutocompleteSelection={this.handleAutocompleteSelection}
        handleLocationChange={this.handleLocationChange}
        guId={coreUtils.generateGuid()}
      />
    )
  },
})

export default Location
