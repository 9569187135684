import chatDispatcher from 'common/components/chat/data/dispatcher'
import conversationStore from 'common/components/chat/data/conversation_store/consversationStore'
import messageStore from 'common/components/chat/data/message_store/messageStore'
import assign from 'object-assign'
import { EventEmitter } from 'events'

let count = 0

const unseenConversationStore = assign({}, EventEmitter.prototype, {
  fetchUnseenCount: function() {
    let jobConversationUrl = `${window.App.url}/jobs_conversations/${conversationStore.getPageContextType()}`

    $.ajax({
      url: jobConversationUrl,
      data: { 'page[size]': 1 },
      method: 'GET',
      headers: { 'Accept': 'application/vnd.api+json; version=2' },
    }).done((response) => {
      console.log(response)
      chatDispatcher.dispatch({
        type: 'UNSEEN_COUNT_FETCH_SUCCESS',
        data: response
      })
    })
  },

  getCount: function () {
    return count
  },

  // events
  emitChange: function () {
    this.emit('change')
  },

  addChangeListener: function (callback) {
    this.on('change', callback)
  },

  removeChangeListener: function (callback) {
    this.removeListener('change', callback)
  }
})


// Register dispatcher callback (single place where all events that "modify" your Stores are handled) ---------------------------------------------------------------------------
unseenConversationStore.dispatchToken = chatDispatcher.register(function (payload) {
  chatDispatcher.waitFor([conversationStore.dispatchToken, messageStore.dispatchToken])

  switch (payload.type) {
    case 'SET_UNSEEN_COUNT':
      count = payload.data
      break

    case 'NEW_CONVERSATION_RECEIVED':
      count++
      break

    case 'MARK_ALL_AS_READ_SUCCESS': // triggered from MessageStore
      unseenConversationStore.fetchUnseenCount()
      break

    case 'UNSEEN_COUNT_FETCH_SUCCESS':
      count = payload.data.meta['total-unread-messages']
      break

    case 'RECEIVED_WEBSOCKET_MESSAGE':
      count = payload.data.meta[`total-unread-${window.App.sessionNav}-conversations`]
      break

    case 'NEW_UNREAD_MESSAGE_RECEIVED':
      count = payload.data.meta[`total-unread-${window.App.sessionNav}-conversations`]
      break

    case 'RECEIVED_WEBSOCKET_NEW_EOI_CONVERSATION': // trigged when a new eoi is created see concepts/eoi/crud.rb for broadcast
      count++
      break

    default:
      return true;
  }

  // If action was acted upon, emit change event
  console.log('unseenMessagesStore: ', payload.type)
  unseenConversationStore.emitChange()
  return true
})

export default unseenConversationStore
