import React from 'react'

import ExistingCreditCard from 'common/components/credit_card/ExistingCreditCard'
import NewCreditCardForm from 'common/components/credit_card/NewCreditCardForm'

const CreditCardForm = React.createClass({
  getInitialState: function() {
    return {
      useExistingCreditCard: !!this.props.ccNumber,
      isUpdatingCreditCard: false,
    }
  },

  toggleCreditCardInput: function(e) {
    e.preventDefault()
    this.setState(
      {
        useExistingCreditCard: !this.state.useExistingCreditCard,
        isUpdatingCreditCard: !this.state.isUpdatingCreditCard
      },
      this.enableSubmitWhenUsingExitingCard)
  },

  enableSubmitWhenUsingExitingCard: function() {
    if(this.state.useExistingCreditCard) {
      $(`#${this.props.formId}`).data('bootstrapValidator').disableSubmitButtons(false)
    }
  },

  render: function() {
    return(
      <div>
        <form id={this.props.formId} action={this.props.action} acceptCharset="UTF-8" method="post" noValidate="novalidate" className="bv-form">
          {this.props.hiddenFields}
          {this.state.useExistingCreditCard ?
            <ExistingCreditCard
              ccNumber={this.props.ccNumber}
              isUpdatingCreditCard={this.state.isUpdatingCreditCard}
              toggleCreditCardInput={this.toggleCreditCardInput}
              disableUpdateButton={this.props.submitting}
            />
            :
            <NewCreditCardForm
              formId={this.props.formId}
              isUpdatingCreditCard={this.state.isUpdatingCreditCard}
              toggleCreditCardInput={this.toggleCreditCardInput}
            />
          }
          {this.props.disclaimer}
          <div className="row submit-button-container">
            <div className="form-group">
              <div className="col-xs-12">
                {this.props.submitButton}
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
})

export default CreditCardForm
