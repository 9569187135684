import React, { Component } from 'react'
import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns'

class TimeAgo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timeAgo: ''
    }
  }

  updateTimeAgo = () => {
    const { timestamp, isStrict } = this.props
    const strictTimeAgo = formatDistanceToNowStrict(new Date(timestamp), { addSuffix: true })
    const nonStrictTimeAgo = formatDistanceToNow(new Date(timestamp), { addSuffix: true })

    this.setState({
      timeAgo: isStrict ? strictTimeAgo : nonStrictTimeAgo
    })
  }

  componentDidMount() {
    this.updateTimeAgo()
    this.intervalId = setInterval(this.updateTimeAgo, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  render() {
    const { timeAgo } = this.state
    return <span>{timeAgo}</span>
  }
}

export default TimeAgo
