import Dispatcher from 'common/src/dispatcher'

const actions = {
  dispatcher: function() {
    return Dispatcher
  },

  resetData: function() {
    Dispatcher.dispatch({
      type: 'job:data:reset'
    })
  },

  resetDataMobile: function() {
    Dispatcher.dispatch({
      type: 'job:data:resetMobile'
    })
  },

  updateConsumerLeads: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:consumer_lead_changed',
      value: val
    })
  },

  updateLocation: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:location_changed',
      value: val
    })
  },

  updateCategory: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:category_changed',
      value: val
    })
  },

  updateDescription: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:description_changed',
      value: val
    })
  },

  uploadFiles: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:file_uploaded',
      value: val
    })
  },

  updateBudgetInMind: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:budgetinmind_changed',
      value: val
    })
  },

  updateBudgetType: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:budgettype_changed',
      value: val
    })
  },

  updateBudgetPriceRange: function(min, max) {
    Dispatcher.dispatch({
      type: 'job:data:budgetpricerange_changed',
      value: { minimumBudget: min, maximumBudget: max }
    })
  },

  updateBudgetSetPriceRange: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:budgetsetpricerange_changed',
      value: val
    })
  },

  updateSubCategory: function(val, action) {
    var type = (action ===  'add') ? 'job:data:subcategory_added' : 'job:data:subcategory_removed'

    Dispatcher.dispatch({
      type: type,
      value: val
    })
  },

  resetCategories: function() {
    Dispatcher.dispatch({
      type: 'job:data:categories_reset'
    })
  },

  updateKeyword: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:keyword_changed',
      value: val
    })
  },

  updateContactOptions: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:contactoptions_update',
      value: val
    })
  },

  updateSchedule: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:schedule_changed',
      value: val
    })
  },

  updateScheduleDate: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:scheduledate_changed',
      value: val
    })
  },

  updateEmailAddress: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:email_changed',
      value: val
    })
  },

  addExtraDetails: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:extra_details_add',
      value: val
    })
  },

  removeExtraDetails: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:extra_details_remove',
      value: val
    })
  },

  updateSiteVisit: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:sitevisit_changed',
      value: val
    })
  },

  updateSiteVisitTime: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:sitevisittime_changed',
      value: val
    })
  },

  updateState: function() {
    Dispatcher.dispatch({
      type: 'job:data:update_state',
      value: null
    })
  },

  urgentProductAdd: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:urgent_product_add',
      value: val
    })
  },

  updateOriginName: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:originname_changed',
      value: val
    })
  },

  updateContactPreference: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:contact_preference_changed',
      value: val
    })
  },

  updateQuoteEstimate: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:quote_estimate_changed',
      value: val
    })
  },

  updatePhoneNumber: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:phone_number_update',
      value: val
    })
  },

  updateOmniAuthDetails: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:omni_auth_details_update',
      value: val
    })
  },

  resetOmniAuthDetails: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:omni_auth_details_reset',
      value: val
    })
  },

  updateMustCreateAccount: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:must_create_account_update',
      value: val
    })
  },

  updateFirstName: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:first_name_update',
      value: val
    })
  },

  updateBackToSubmit: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:back_to_submit_update',
      value: val
    })
  },

  updateFileNames: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:file_names_update',
      value: val
    })
  },

  updateOmniAuthProvider: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:omni_auth_provider_update',
      value: val
    })
  },

  updateLastSlide: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:last_slide_update',
      value: val
    })
  },

  updateOtp: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:otp_update',
      value: val
    })
  },

  updateAdditionalQuestion: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:additional_question_update',
      value: val
    })
  },

  updateClosureSurvey: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:closure_survey_update',
      value: val
    })
  },

  updateProgressWidth: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:progress_width_update',
      value: val
    })
  },

  updatePreferredOutcome: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:preferred_outcome_update',
      value: val
    })
  },

  updateHostedFieldsToken: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:hosted_fields_token_update',
      value: val
    })
  },

  updateHostedFieldsUserId: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:hosted_fields_user_id_update',
      value: val
    })
  },

  updateEnvironment: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:environment_update',
      value: val
    })
  },

  updatePremiumListingAmount: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:premium_listing_amount_update',
      value: val
    })
  },

  updatePopularJobs: function(val) {
    Dispatcher.dispatch({
      type: 'job:data:popular_jobs_update',
      value: val
    })
  }
}

export default actions
