import React, { Component } from 'react'
import HostedFields from 'common/components/credit_card/HostedFields'

export default class CreditCardFormHostedFields extends Component {
  constructor(props) {
    super(props)

    this.state = {
      creditCardId: '',
      submitting: false
    }
  }

  handleSubmitButtonOnclick = (creditCardId) => {
    let $submitButton = $('.js-submit-credit-card')

    if (!$submitButton.prop('disabled')) {
      this.setState({ creditCardId: creditCardId, submitting: true })

      document.getElementById('credit-card-form').submit()
    }
  }

  hiddenFields() {
    return (
      <div className='hidden-fields'>
        <input type='hidden' name='credit_card_id' value={this.state.creditCardId} />
      </div>
    )
  }

  render() {
    return(
      <HostedFields
        hostedFieldsToken={this.props.hostedFieldsToken}
        hostedFieldsUserId={this.props.hostedFieldsUserId}
        buttonClass={this.props.buttonClass || 'js-submit-credit-card'}
        buttonText={this.props.buttonText || 'Save Changes'}
        callback={this.props.submitButtonClickHandler || this.handleSubmitButtonOnclick}
        withExistingCard={this.props.withExistingCard}
        ccNumber={this.props.ccNumber}
        action={this.props.formAction}
        hiddenFields={this.props.hiddenFields || this.hiddenFields()}
        environment={this.props.environment}
        spinnerText={this.props.spinnerText || 'Saving...'}
        submitting={this.props.submitting || this.state.submitting}
      />
    )
  }
}
