import React from 'react'
import coreUtils from 'common/src/utils/coreUtils'

const JobUpgradeCard = function(props) {
    return (
        <div className="col-xs-12">
            <div className="card card-flat">
                <div className={`ss-checkbox ss-checkbox-clear ss-checkbox-reverse ${props.type}`}>
                  <input data-price={props.price} type="checkbox" className="mt0" id={props.id} onClick={props.onUpgradeCardClick} defaultChecked={props.checked} name="purchase_params[addons][]" value={props.value}></input>
                  <label className="ss-checkbox-text m0" htmlFor={props.id}>
                    <div className="container-fluid">
                      <div className="pull-left">{props.upgradeName}</div>
                      <div className="pull-right">${props.price}</div>
                    </div>
                  </label>
                </div>
                <div className="card-container card-pad-sm bg-white">
                  <div className="font-14 text-gray pr40 ju-text-icon">
                    {descriptionTitle()}
                    {props.description}
                  </div>
                </div>
            </div>
        </div>
    )

    function descriptionTitle() {
      let descriptionTitle = props.descriptionTitle
      if (coreUtils.doesObjectExists(descriptionTitle)) {
        return (
          <div className="mb15">
            <strong>{descriptionTitle}</strong>
          </div>
        )
      }
    }
}

export default JobUpgradeCard