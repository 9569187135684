import data from 'common/components/job_wizard/data/data'

const Utils = {
  products: {
    hasSelectedJobUpgrades: function() {
      return (Object.keys(data.products).filter(function(productType) { return data.products[productType] === true }).length > 0)
    }
  },

  initJob: function(job) {
    data.id             = job.id
    data.description    = job.description
    data.referrer.cta   = job.cta
    data.category       = job.subcategoryName
    data.category_id    = job.subcategoryId

    data.preferredOutcome = job.preferredOutcome
    data.showSuppressants = job.showSuppressants

    if (job.exclusiveFor) {
      data.exclusiveFor = job.exclusiveFor
    }

    data.canCall = job.canCall
    data.quoteEstimate = job.quoteEstimate
  }
}

export default Utils
