!function ($) {
  'use strict';
  function checkBoxGroup($checkBoxGroupContainer, options){

    function bindEvents() {
      $checkBoxGroupContainer.on('click', ".check-box", $checkBoxGroupContainer, setCheckBoxToCheck);
    }

    function setCheckBoxToCheck(e) {
      var $checkBoxRow = $(e.currentTarget);
      var $groupContainer = $(e.data);
      var $parentForm = $groupContainer.parents('form');
      var $checkBox = $checkBoxRow.find("input[type='checkbox']");
      var $masterCheckBox = $groupContainer.find("input.master-value");
      var activeClass = activeRowClass();
      var newCheckboxValue = !$checkBox.prop('checked');

      $checkBoxRow
        .toggleClass(activeClass, newCheckboxValue)
        .find('.tick')
        .toggleClass('hidden', !$checkBoxRow.hasClass(activeClass));
      $checkBox.prop('checked', newCheckboxValue);

      if (isParentFormWithValidation() && isCheckedCheckboxValidateable()) {
        forceValidateField();
      }

      if (isMasterValueSelectedAsActive()) {
        uncheckNonMasterCheckBoxes();
      } else if (isGroupWithMasterValue() && !isCurrentSelectedCheckboxMaster()) {
        uncheckMasterCheckBox();
      }

      function uncheckNonMasterCheckBoxes() {
        var $nonMasterCheckBoxes = $checkBoxGroupContainer.find("input").not($masterCheckBox);
        var _activeClass = activeClass;
        $nonMasterCheckBoxes.each(function(index, checkBox) {
          var $checkBoxForUnchecking = $(checkBox);
          var $containingRow = $checkBoxForUnchecking.parents('.check-box');
          $checkBoxForUnchecking.prop('checked', false);
          $containingRow
            .toggleClass(_activeClass, false)
            .find('.tick')
            .toggleClass('hidden', true);
        });
      }

      document.dispatchEvent(new Event('ccb:master:value:toggled', {originalTarget: $checkBoxGroupContainer}));

      function uncheckMasterCheckBox() {
        var _activeClass = activeClass;
        var $containerRow = $masterCheckBox.parents('.check-box');
        $masterCheckBox.prop('checked', false);
        $containerRow
          .toggleClass(_activeClass, false)
          .find('.tick')
          .toggleClass('hidden', true);
      }

      function isGroupWithMasterValue() {
        return $groupContainer.find("input").hasClass('master-value');
      }

      function isCurrentSelectedCheckboxMaster() {
        return $checkBox.hasClass('master-value');
      }

      function currentValue() {
        return $checkBox.is(":checked");
      }

      function isMasterValueSelectedAsActive() {
        return isGroupWithMasterValue() && isCurrentSelectedCheckboxMaster() && currentValue();
      }

      function isParentFormWithValidation() {
        if ($parentForm){
          return !!$parentForm.data('bootstrapValidator');
        } else {
          return false;
        }
      }

      function isCheckedCheckboxValidateable() {
        return !!$checkBox.data('bv.messages');
      }

      function forceValidateField() {
        var fieldName = $checkBox.prop('name');
        $parentForm.data('bootstrapValidator').revalidateField(fieldName);
      }

    }

    function activeRowClass() {
      var activeClass = options.activeRowClass;
      return activeClass ? activeClass : '';
    }

    function getCheckedCheckBoxInputs(){
      return $checkBoxGroupContainer.find("input[type='checkbox']:checked");
    }

    function getCheckedCheckBoxInputValue() {
      var $checkedCheckBoxInputs = getCheckedCheckBoxInputs();
      var checkedCheckBoxInputValues = [];

      $checkedCheckBoxInputs.each(function(index, checkBoxInput){
        var $checkBoxInput = $(checkBoxInput);
        var checkedCheckBoxInputValue = $checkBoxInput.val();
        if (checkedCheckBoxInputValue.toLowerCase() === 'other'){
          var $checkedCheckBoxInputRow = $checkBoxInput.next();
          checkedCheckBoxInputValues.push($checkedCheckBoxInputRow.find("input[type='text']").val());
        } else {
          checkedCheckBoxInputValues.push(checkedCheckBoxInputValue);
        }
      });
      return checkedCheckBoxInputValues;
    }

    return {
      init: function() {
        bindEvents();
      },
      currentCheckedValue: function() {
        return getCheckedCheckBoxInputValue();
      }
    }
  }

  function plugin() {
    var $this = $(this);
    var data = $this.data('checkBoxGroup');
    var options = arguments[0] || {};

    if (!data){
      data = checkBoxGroup(this, options);
      data.init();
      $this.data('checkBoxGroup', data);
    }
    return data;
  }

  $.fn.checkBoxGroup = plugin;

}(window.jQuery);
