import React from 'react'

const CreditCardDownForMaintenance = function(props) {
  const isBusiness = !!window.App.providerId

  return (
    <div className="pt10 pb10">
      <div className="credit-card-down-for-maintenance alert alert-warning">
        <div className="m5">
          {isBusiness ? <BusinessMessage agentsPhone={window.App.providerAgentsPhone} /> : <UserMessage />}
        </div>
      </div>
    </div>
  )
}

function BusinessMessage(props) {
  return (
            <div>
              <p className="mb10">Credit card payments are unable to be processsed.</p>
              <p className="mb10">
                {`Call your account manager so they can take payment over the phone on ${props.agentsPhone}.`}
              </p>
              <p>Sorry for any inconvenience.</p>
            </div>
  )
}

function UserMessage(props) {
  return (<div>Credit card payments are unable to be processsed. Please make alternative payment arrangements. Sorry for any inconvenience.</div>)
}

export default CreditCardDownForMaintenance