const steps = {
  "541": { //Roof Repairs
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "Which of the following best match your job requirements?",
            "jobDescriptionKey": "Services required",
            "validation": {
              "groupName": "roof_repairs_services",
              "message": "Tick all that apply.",
            },
            "options": [
              {
                "value": "New Roof",
                "text": "New Roof"
              },
              {
                "value": "Roof Restoration",
                "text": "Roof Restoration"
              },
              {
                "value": "Roof Repairs",
                "text": "Roof Repairs"
              },
              {
                "value": "Roof Painting",
                "text": "Roof Painting"
              },
              {
                "value": "Gutter Repair/Installation",
                "text": "Gutter Repair/Installation"
              },
              {
                "value":"Skylights/Sun Roof",
                "text":"Skylights/Sun Roof"
              },
              {
                "value":"Solar Panels",
                "text":"Solar Panels"
              },
              {
                "value":"Roof Tiling",
                "text":"Roof Tiling"
              },
              {
                "value":"Other",
                "text":"Other"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Please specify the roof type:",
            "jobDescriptionKey": "Roof type",
            "validation": {
              "groupName": "roof_type",
              "message": "Please select the roof type you require."
            },
            "options": [
              {
                "value": "Colorbond/Metal",
                "text": "Colorbond/Metal"
              },
              {
                "value": "Concrete Tile",
                "text": "Concrete Tile"
              },
              {
                "value": "Terracotta Tile",
                "text": "Terracotta Tile"
              },
              {
                "value": "Bitumen/Tar",
                "text": "Bitumen/Tar"
              },
              {
                "value": "Plastic/Polycarbonate Sheet",
                "text": "Plastic/Polycarbonate Sheet"
              },
              {
                "value":"Other",
                "text":"Other"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
