import React from 'react'

const Alert = (props) => {
  const determineColor = () => {
    switch(props.name) {
    case 'info':
      return 'blue'
      break
    case 'success':
      return 'green'
      break
    case 'warning':
      return 'yellow'
      break
    default:
      return 'red'
    }
  }

  const defaultMessage = () => {
    return (
      <span>
        An unknown error has occurred. Please contact support at{' '}
        <a href='mailto:support@serviceseeking.com.au' className='font-semibold'>
          support@serviceseeking.com.au
        </a>
      </span>
    )
  }

  return (
    <div id='alert-banner' className={`flex p-4 mb-4 text-${determineColor()}-800 border-t-4 border-${determineColor()}-300 bg-${determineColor()}-50`} role='alert'>
      <hero-icon-solid name={`${props.icon || 'information-circle'}`} width='20' height='20' class={`flex-shrink-0 text-${determineColor()}-600`}></hero-icon-solid>
      <div className='ms-3 text-sm font-medium'>
        { props.message || defaultMessage()}
      </div>
    </div>
  )
}

export default Alert
