import React from 'react'

import jobStore from 'common/components/job_wizard/data/store'
import actions from 'common/components/job_wizard/data/actions'
import data from 'common/components/job_wizard/data/data'

import StepSequence from 'common/components/job_wizard/StepSequence'
import Details from 'common/components/job_wizard/steps/Details'
import SubCategory from 'common/components/job_wizard/steps/SubCategory'
import Description from 'common/components/job_wizard/steps/Description'
import EmailAddress from 'common/components/job_wizard/steps/EmailAddress'
import SelectCategory from 'job_wizard_mobile/components/steps/SelectCategory'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import LocationInput from 'common/components/LocationInput'
import CategoryInput from 'common/components/job_wizard/CategoryInput'
import jobWizardActions from 'common/components/job_wizard/data/actions'

import coreUtils from 'common/src/utils/coreUtils'

const GetQuotes = React.createClass({
  getInitialState: function() {
    return this.getCategorySuburb()
  },

  componentDidMount: function() {
    jobStore.addChangeListener(this.updateState)
    this.prefillCategoryInfo()
  },

  componentWillUnmount: function() {
    jobStore.removeChangeListener(this.updateState)
  },

  getCategorySuburb: function() {
    return $.extend(jobStore.getCategory(), jobStore.getSuburb())
  },

  updateState: function(data) {
    this.setState(this.getCategorySuburb())
  },

  prefillCategoryInfo: function() {
    if(this.hasSuppliedCategory()) {
      const selectedCategoryDetails = { item: { id: this.props.subcategoryId, value: this.props.subcategoryName } }
      jobWizardActions.updateCategory(selectedCategoryDetails.item)
      jobWizardActions.updateSubCategory(selectedCategoryDetails.item, 'add')
    }
  },

  hasSuppliedCategory: function() {
    return this.props.subcategoryName && this.props.subcategoryId
  },

  handleSelectedCategory: function( event, selected ) {
    event.preventDefault()
    actions.updateCategory(selected.item)
    actions.updateSubCategory(selected.item, 'add')
    actions.updateKeyword(selected.item.keyword)
  },

  handleCategoryOnChange: function(event) {
    actions.updateSubCategory({id: data.category_id, values: data.category}, 'remove')
    actions.updateCategory({ id: 0, value: event.target.value })
    actions.updateKeyword(null)
  },

  handleAutocompleteSelection: function( event, response ) {
    actions.updateLocation({ id: response.item.id, value: response.item.value })
  },

  handleLocationChange: function(event) {
    actions.updateLocation({ id: 0, value: event.target.value })
  },

  handleClick: function() {
    var showJobWizardModal = () => {
      if (jobLocationAutocompleteSelected() && categoryValuePresent()) {
        if (coreUtils.mobilecheck()) {
          advanceMobileWizardToNextStep()
        }
        else {
          advanceWizardToNextStep()
        }
      }

      $('#job-modal').modal({keyboard: false, backdrop: 'static'}, {cta: this.props.cta})
      coreUtils.googleAnalytics({hitType: 'event', eventCategory: 'jobpost', eventAction: 'openmodal'})

      function advanceWizardToNextStep() {
        const steps = StepSequence.getSteps()
        let details          = Details
        let detailsStepIndex = details.detailsStepIndex(steps)
        let nextStep         = steps[detailsStepIndex + 1]

        if (nextStep === SubCategory && details.hasSubcategory()) {
          JobWizardEventEmitter.emit('job:wizard:advanceToStep', Description)
        } else {
          JobWizardEventEmitter.emit('job:wizard:advanceToNextStep')
        }
      }

      function advanceMobileWizardToNextStep() {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', SelectCategory)
      }

      function jobLocationAutocompleteSelected() {
        return data.suburb_id !== 0
      }

      function categoryValuePresent() {
        return data.category.trim() !== ''
      }
    }

    showJobWizardModal()

  },

  selectExactMatchForSubcategory: function(callback) {
    $.ajax({
      url: '/api/sub_categories/lookup.json',
      dataType: 'json',
      data: { 'q': this.searchTerm(), 'prefer_keywords': '1' }
    }).done((response) => {
      if ('keyword' in response) {
        let category = { id: response.subcategory_id, value: response.subcategory_name }
        actions.updateKeyword(response.keyword)
        actions.updateCategory(category)
        actions.updateSubCategory(category, 'add')
        JobWizardEventEmitter.emit('job:wizard:categorySelected', null, { item: category })
      } else {
        for (let item of response) {
          if (this.searchTerm().toLowerCase() == item.name.toLowerCase()) {
            let category = { id: item.id, value: item.name }
            actions.updateCategory(category)
            actions.updateSubCategory(category, 'add')
            JobWizardEventEmitter.emit('job:wizard:categorySelected', null, { item: category })
          }
        }
      }

      callback()
    })
  },

  searchTerm: function() {
    return $('input[name="category"]').val()
  },

  render: function() {
    let buttonSize = this.props.buttonSize || 'btn-lg'

    return (
      <div className="row">
        <div className="col-sm-5 home-text-area hidden-xs sm-pr0 category-input-container">
          <div className="text-center">
            <CategoryInput
              autoFocus
              category={this.state.keyword || this.state.category}
              handleSelectedCategory={this.handleSelectedCategory}
              handleCategoryChange={this.handleCategoryOnChange}
              preferKeywords="1"
              guId={coreUtils.generateGuid()}
            />
          </div>
        </div>
        <div className="col-md-5 col-sm-4 home-text-area hidden-xs sm-pr0 sm-pl0 location-input-container">
          <div className="text-center">
            <LocationInput
              suburb={this.state.suburb}
              handleAutocompleteSelection={this.handleAutocompleteSelection}
              handleLocationChange={this.handleLocationChange}
              guId={coreUtils.generateGuid()}
              autoFocus={this.hasSuppliedCategory()}
            />
          </div>
        </div>
        <div className="col-sm-3 col-md-2 home-page-btn sm-pl0 submit-container">
          <div className="mb0">
            <button className={`btn btn-main submit-button ${buttonSize}`} onClick={this.handleClick}>Get Quotes</button>
          </div>
        </div>
      </div>
    )
  }
})

export default GetQuotes
