import React from 'react'

const NavigationButtons = function(props) {
  const {onStepProgression, onStepRetreat, navButtonLayout, onSecondaryNavButtonClick, onModalExit} = props
  const {leftButton, rightButton} = props.buttons || {}
  const label = props.label || ''
  const layoutChooser = {
    'oneButton'      :  oneButton,
    'submitButton'   :  submitButton,
    'okButton'       :  okButton,
    'skipButton'     :  skipButton,
    'upButton'       :  upButton,
    'downButton'     :  downButton,
    'upAndDownButton':  upAndDownButton,
    'default'        :  twoButton,
    'threeButton'    :  threeButton,
    'leftOnlyButton' :  leftOnlyButton,
    'continueButton' :  continueButton,
    'exitButton'     :  exitButton,
    'submitButtonClosure' : submitButtonClosure,
    'none'           :  () => null
  }

  function oneButton() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <a href="#" id="modal-nav-one-button" className="btn btn-block btn-main" onClick={onStepProgression}>
            Get Started
          </a>
        </div>
      </div>
    )
  }

  function submitButtonClosure() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <a href="#" id="modal-nav-one-button" className="btn btn-block btn-main" style={{'whiteSpace': 'normal'}} onClick={onStepProgression}>
            {label}
          </a>
        </div>
        <div className="col-xs-12">
          <a href="#" id="modal-nav-one-button" className="btn btn-block btn-clear" style={{'whiteSpace': 'normal'}} onClick={onStepRetreat}>
            Back
          </a>
        </div>
      </div>
    )
  }

  function submitButton() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <a href="#" id="modal-nav-one-button" className="btn btn-block btn-main" onClick={onStepProgression}>
            Submit
          </a>
        </div>
      </div>
    )
  }

  function okButton() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <a href="#" id="modal-nav-ok-button" className="btn btn-secondary radius-6" onClick={onStepProgression}>
            OK
          </a>
        </div>
      </div>
    )
  }

  function skipButton() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <a href="#" id="modal-nav-ok-button" className="btn btn-clear radius-6" onClick={onStepProgression}>
            Skip
          </a>
        </div>
      </div>
    )
  }

  function exitButton() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <a href="#" id="modal-nav-exit-button" className="btn btn-block btn-main" onClick={onModalExit}>
          Exit
          </a>
        </div>
      </div>
    )
  }

  function leftOnlyButton() {
    return (
      <div className="row">
        <div className='col-sm-6 col-xs-6 pl4'>
          <a href="#" className="btn btn-block btn-clear" onClick={onStepRetreat}>
            <i className='ficon-arrow-left-shaft pr10 fs14' />
            {leftButton ? leftButton.text : 'Back'}
          </a>
        </div>
      </div>
    )
  }

  function continueButton() {
    return (
      <div className="row">
        <div className='col-sm-12 col-xs-12 pb12'>
          <a href="#" className="btn btn-block btn-secondary p12" onClick={onStepProgression}>
            {rightButton ? rightButton.text : 'Continue'}
            <i className='ficon-arrow-right-shaft pl10 fs14' />
          </a>
        </div>
      </div>
    )
  }

  function twoButton() {
    return(
      <div className="row two-button-footer">
        <div className='col-sm-6 col-xs-6 pl4 pull-right'>
          <a href="#" id="modal-nav-right-button" className={rightButton ? rightButton.className : 'btn btn-block btn-secondary'} onClick={onStepProgression}>
            {rightButton ? rightButton.text : 'Continue'}
            <i className={rightButton ? rightButton.iconClass : 'ficon-arrow-right-shaft pl10 fs14'} />
          </a>
        </div>
        <div className='col-sm-6 col-xs-6 pull-left pr4'>
          <a href="#" id="modal-nav-left-button" className="btn btn-block btn-clear" onClick={onStepRetreat}>
            <i className={leftButton ? leftButton.iconClass : 'ficon-arrow-left-shaft pr10 fs14'} />
            {leftButton ? leftButton.text : 'Back'}
          </a>
        </div>
      </div>
    )
  }

  function upButton() {
    return(
      <div className="up-button-footer pt30 pb30" style={{ position: 'absolute' }}>
        <span className='pl4 pull-left'>
          <a href="#" className="btn btn-lg btn-clear" id="modal-nav-up-button" onClick={onStepRetreat}>
            <i className='ficon-arrow-left fs14' />
          </a>
        </span>
      </div>
    )
  }

  function downButton() {
    return(
      <div className="down-button-footer pt30" style={{ position: 'absolute' }}>
        <span className='pull-left'>
          <a href="#" className="btn btn-lg" id="modal-nav-down-button" onClick={onStepProgression}>
            <i className='ficon-arrow-right fs14'/>
          </a>
        </span>
      </div>
    )
  }

  function upAndDownButton() {
    return(
      <div className="up-and-down-button-footer pt30" style={{ position: 'absolute' }}>
        <span className='pl4 pull-left'>
          <a href="#" className="btn btn-lg btn-clear" id="modal-nav-up-button" onClick={onStepRetreat}>
            <i className='ficon-arrow-left fs14' />
          </a>
        </span>
        <span className='pull-left'>
          <a href="#" className="btn btn-lg" id="modal-nav-down-button" onClick={onStepProgression}>
            <i className='ficon-arrow-right fs14'/>
          </a>
        </span>
      </div>
    )
  }

  function threeButton() {
    return(
      <div>
        <div className="three-button-footer">{twoButton()}</div>
        <div className="row">
          <div className="col-xs-12 text-center mt15">
            <a href="#" className="text-gray" onClick={onSecondaryNavButtonClick}>No Thanks</a>
          </div>
        </div>
      </div>
    )
  }

  return layoutChooser[navButtonLayout]()
}

export default NavigationButtons
