import React from 'react'

import Category from 'common/components/job_wizard/steps/Category'
import jobWizardData from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import CheckBoxRow from 'common/components/CheckBoxRow'

const SubCategory = {

  onSubmit: function(onStepProgression) {
    this.verifyCantFindSelection()
    this.verifySubcategorySelection()
    onStepProgression()
  },

  verifyCantFindSelection: function() {
    let $noMatchOption  = $('.js-subcategory-no-match .check-box')
    let noMatchSelected = $noMatchOption.hasClass('active')
    if(noMatchSelected === false && jobWizardData.category_id === 0) {
      $('#job-modal').bootstrapValidator('enableFieldValidators', 'category', true)
    }
  },

  verifySubcategorySelection: function() {
    let hasSelectedSubcategory = jobWizardData.categories.length > 0
    let validator = $('#job-modal').data('bootstrapValidator')
    let validationStatus = (hasSelectedSubcategory || this.selectedNoSubcategoryMatchesOption()) ? 'VALID' : 'INVALID'
    validator.updateMessage('category', 'notEmpty', 'Please select one of the options above, or try another search term.')
    validator.updateStatus('category', validationStatus, 'notEmpty')
  },

  selectedNoSubcategoryMatchesOption: function() {
    return $('.js-subcategory-no-match .check-box.active').length > 0
  },

  component: class SubCategory extends React.Component {
    constructor (props) {
      super(props)

      this.state = {
        isNoMatchCategory: false,
        emptySearch: 1,
        loading:      false,
        matchingIndustryList: []
      }

      this.validator = ''

      this.handleNoMatchingSubcategories = this.handleNoMatchingSubcategories.bind(this)
      this.handleSubcategoryChanged = this.handleSubcategoryChanged.bind(this)
    }

    componentDidMount() {
      $('#subcategory-check-box-group').checkBoxGroup({ activeRowClass: 'active' })
      this.getMatchingIndustryList()
      this.validator = $('#job-modal').data('bootstrapValidator')
    }

    handleSubcategoryChanged(e) {
      let $selectedItem  = $(e.currentTarget).find('[type="checkbox"]')
      let $noMatchOption = $('.js-subcategory-no-match .check-box')
      let subCategoryId  = Number($selectedItem.attr('id'))
      let action         = this.isChecked(subCategoryId) ? 'remove' : 'add'

      this.unselectOption($noMatchOption)
    
      actions.updateSubCategory({
        id:    subCategoryId,
        value: $selectedItem.val()
      }, action)
    }

    handleNoMatchingSubcategories(e) {
      let $noMatchOption  = $('.js-subcategory-no-match .check-box')
      let $categories     = $('#subcategory-check-box-group > .check-box')
      let noMatchSelected = $noMatchOption.hasClass('active')
      
      noMatchSelected ? this.unselectOption($noMatchOption) : this.selectOption($noMatchOption)
      
      actions.updateCategory({id: 0, value: ''})
      actions.updateKeyword(null)
      actions.resetCategories()

      if(noMatchSelected === false) {
        $('#job-modal').bootstrapValidator('enableFieldValidators', 'category', false)
      }
      
      this.setState({
        categories: [],
        isNoMatchCategory: noMatchSelected
      });
      this.unselectOption($categories)

      $categories.each( function(index, element) {
        actions.updateSubCategory({
          id:    Number(element.id),
          value: element.value
        }, 'remove')
      })
    }

    selectOption($element) {
      $element.addClass('active').find('.tick').removeClass('hidden')
    }

    unselectOption($element) {
      $element.removeClass('active').find('.tick').addClass('hidden')
    }

    getMatchingIndustryList() {
      const query = jobWizardData.category
      this.setState({loading: true})

      $.ajax({
        url: '/api/sub_categories/lookup.json',
        method: 'GET',
        data: {
          q: query,
          per_page: 5
        }
      })
        .always( () => this.setState({loading: false}) )
        .done( (data) => this.setState({ matchingIndustryList: data }) )
        .fail( () => { document.location = "/500" } )
    }

    isChecked(subcategoryId) {
      return jobWizardData.categories.find(category => category.id === subcategoryId)
    }

    renderCategoryCheckBoxRows() {
      return(
        this.state.matchingIndustryList.map((subcategory) =>
          <CheckBoxRow
            name="subcategory"
            key={subcategory.id}
            text={subcategory.name}
            id={subcategory.id}
            value={subcategory.name}
            isChecked={this.isChecked(subcategory.id)}
            onClickHandler={this.handleSubcategoryChanged}
            optional="1"
          />
        )
      )
    }

    render() {
      return (
        <div className="form-group">
          <h3 className="mb15 mt10">What best describes what you need?</h3>
          {
            this.state.loading ?
            <div className='row text-center mt30 mb10'>
              <div className='spinner-icon'/>
            </div>
            :
            <div className="check-box-container minor-bold" id="subcategory-check-box-group">
              { this.renderCategoryCheckBoxRows() }
            </div>
          }
          <div className="no-subcategory-match">
            <h3 className="mb15 mt10">Can't find what you need?</h3>
            <div className="industry-search">
              <i className="ficon-search"/>
              <Category
                emptySearch={this.state.emptySearch}
                isNoMatchCategory={this.state.isNoMatchCategory}
                placeholder="Click here to try another search term"
              />
            </div>
          </div>
          <div className="check-box-container minor-bold js-subcategory-no-match mt10">
            <div className="check-box" onClick={this.handleNoMatchingSubcategories}>
              <div className="row">
                <div className="col-xs-10 col-sm-10 major-bold">
                  I can't find a match for what I need.
                </div>
                <div className="col-xs-2 col-sm-2 tick hidden">
                  <i className="ficon-tick pull-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default SubCategory
