import React from 'react'

import API from 'common/src/api'

class PricingSurvey extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStep: 0,
      formData: {
        gst_included: true,
        price: '',
        unit: '',
        pricing_survey_answered: ''
      },
      submitting: false
    }
  }

  nextStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }))
  }

  prevStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }))
  }

  handleChange = (event) => {
    const { name, value, type, checked } = event.target

    let formattedValue = ['true', 'false'].includes(value) ? JSON.parse(value) : value

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: type === 'checkbox' ? checked : formattedValue,
      }
    }))
  }

  submit = () => {
    const { formData } = this.state

    let businessPricingCreate = API.V3.businessPricingCreate(formData)

    businessPricingCreate.beforeSend = () => {
      this.setState({ submitting: true })
    }

    $.ajax(businessPricingCreate)
      .done((response) => {
        this.setState({ currentStep: this.steps().length - 1, submitting: false })
      })
      .fail((error) => {
        document.location.href = '/500'
      })
  }

  getUnitLabel = () => {
    return this.props.unitLabels[this.state.formData.unit]
  }

  steps = () => {
    const { formData } = this.state
    const steps = [
      {
        component: () => (
          <div className='form-group'>
            <h1 className='bold fs22 text-center mb30'>Do you have a standard rate?</h1>
            <div>
              <div>
                <input type='radio' id='pricing_survey_answered_yes' name='pricing_survey_answered' value={true} checked={formData.pricing_survey_answered ? 'checked' : ''} onChange={(e) => this.handleChange(e)} />
                <span className='fs20 text-300 ml5'>Yes</span>
              </div>
              <div>
                <input type='radio' id='pricing_survey_answered_no' name='pricing_survey_answered' value={false} checked={!formData.pricing_survey_answered && formData.pricing_survey_answered !== ''  ? 'checked' : ''} onChange={(e) => this.handleChange(e)} />
                <span className='fs20 text-300 ml5'>No</span>
              </div>
            </div>
          </div>
        )
      },
      {
        component: () => (
          <div className='form-group'>
            <h1 className='bold fs22 text-center mb30'>How do you set your rates?</h1>
            <div>
              <input type='radio' id='unit_hourly' name='unit' value='hourly' checked={formData.unit === 'hourly' ? 'checked' : ''} onChange={(e) => this.handleChange(e)} />
              <span className='fs20 text-300 ml5'>Hourly</span>
            </div>
            <div>
              <input type='radio' id='unit_daily' name='unit' value='daily' checked={formData.unit === 'daily' ? 'checked' : ''} onChange={(e) => this.handleChange(e)} />
              <span className='fs20 text-300 ml5'>Daily</span>
            </div>
            <div>
              <input type='radio' id='unit_lineal_rate' name='unit' value='lineal_rate' checked={formData.unit === 'lineal_rate' ? 'checked' : ''} onChange={(e) => this.handleChange(e)} />
              <span className='fs20 text-300 ml5'>Per Lineal Rate</span>
            </div>
            <div>
              <input type='radio' id='unit_square_metre' name='unit' value='square_metre' checked={formData.unit === 'square_metre' ? 'checked' : ''} onChange={(e) => this.handleChange(e)} />
              <span className='fs20 text-300 ml5'>Per Square Metre</span>
            </div>
            <div>
              <input type='radio' id='unit_cubic_metre' name='unit' value='cubic_metre' checked={formData.unit === 'cubic_metre' ? 'checked' : ''} onChange={(e) => this.handleChange(e)} />
              <span className='fs20 text-300 ml5'>Per Cubic Metre</span>
            </div>
          </div>
        )
      },
      {
        component: () => (
          <div className='form-group'>
            <h1 className='bold fs22 text-center mb30'>Specify your minimum price</h1>
            <div className='row mb30'>
              <div className='col-xs-2 text-300 fs20 text-light-gray vcenter'>From</div>
              <div className='col-xs-5 col-sm-4 vcenter mr0 pr0'>
                <div className='input-group border-blue mb8'>
                  <div className='input-group-addon bg-white pr0 no-border'>
                    <i className='ficon-dollar text-300 fs20'></i>
                  </div>
                  <input
                    className='form-control text-300 fs20 no-border price-survey-input'
                    name='price'
                    onChange={(e) => this.handleChange(e)}
                    type='number'
                    value={formData.price}
                  />
                </div>
              </div>
              <div className='col-xs-5 vcenter fs18 text-300'>
                / {this.getUnitLabel()}
              </div>
            </div>
            <div>
              <input type='radio' id='gst_included_yes' name='gst_included' value={true} checked={formData.gst_included ? 'checked' : ''} onChange={(e) => this.handleChange(e)} />
              <span className='fs20 text-300 ml5'>Inc. GST</span>
              <input type='radio' id='gst_included_no' name='gst_included' value={false} checked={!formData.gst_included ? 'checked' : ''} onChange={(e) => this.handleChange(e)} className='ml20' />
              <span className='fs20 text-300 ml5'>Ex. GST</span>
            </div>
          </div>
        )
      },
      {
        component: () => (
          <div className='form-group text-center'>
            <h1 className='bold fs22 mb30'>Thank You!</h1>
            <i className='ficon-check-circle text-blue font-64'></i>
            {
              !formData.pricing_survey_answered ?
              <div className='fs16 text-300 mt30'>
                <div className='mb20'>
                  Thank you for answering the price survey.
                </div>
                To edit your price, please go to your business profile.
              </div>
              :
              <div className='fs16 text-300 mt30'>
                <div className='mb20'>
                  Your standard price of ${formData.price} per {this.getUnitLabel()} has been set. We show pricing on your Business Profile and use it to analyse pricing in your industry.
                </div>
                To edit your price, please go to your business profile.
              </div>
            }
          </div>
        )
      }
    ]

    return steps
  }

  render() {
    const { currentStep, formData, submitting } = this.state

    const isNextButtonDisabled = (() => {
      switch (currentStep) {
        case 0:
          return formData.pricing_survey_answered === ''
        case 1:
          return !formData.unit
        default:
          return false
      }
    })()

    const isSubmitButtonDisabled = (() => {
      if (currentStep === 2) {
        return !this.state.formData.price
      }
    })()

    const steps = this.steps()
    const currentStepComponent = steps[currentStep]
    const submitButtonText = submitting ? 'Submitting...' : 'Submit'

    return (
      <div id='pricing-survey-modal' className='modal fade' tabIndex='-1' role='dialog' data-backdrop='static'>
        <div className='modal-dialog pricing-survey-dialog' role='document'>
          <div className='modal-content p20'>
            <div className='modal-header no-border'>
              <h1 className='text-center bold fs22 text-blue'>Pricing Survey</h1>
            </div>
            <div className='modal-body'>
              {currentStepComponent.component()}
            </div>
            <div className='modal-footer no-border'>
              {
                (currentStep === steps.length - 1) ?
                <div>
                  <a href='/my-profile' className='btn btn-secondary btn-block radius-6 text-300 fs19'>Close</a>
                </div>
                :
                <div className='row'>
                  <div className='col-xs-6'>
                    {
                      (currentStep > 0) &&
                      <button className='btn btn-secondary btn-block radius-6 text-300 fs19' onClick={this.prevStep}>Previous</button>
                    }
                  </div>
                  {
                    (currentStep < steps.length && currentStep !== 2) &&
                    <div className='col-xs-6'>
                      <button className='btn btn-secondary btn-block radius-6 text-300 fs19' onClick={!this.state.formData.pricing_survey_answered ? this.submit : this.nextStep} disabled={isNextButtonDisabled}>Next</button>
                    </div>
                  }
                  {
                    (currentStep === 2) &&
                    <div className='col-xs-6'>
                      <button className='btn btn-secondary btn-block radius-6 text-300 fs19' onClick={this.submit} disabled={isSubmitButtonDisabled || submitting}>{submitButtonText}</button>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PricingSurvey
