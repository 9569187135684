import React from 'react'
import actions from 'common/components/job_wizard/data/actions'
import data from 'common/components/job_wizard/data/data'
import NavigationButtons from 'common/components/NavigationButtons'
import EmailAddress from 'common/components/job_wizard/steps/EmailAddress'

class OmniAuthLogin extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      provider: '',
      errorMessage: ''
    }
  }

  handleButtonClick = (e) => {
    this.setState({ provider: e.target.value })
    actions.updateOmniAuthDetails({'account_from_omni_auth': true})
    $('.two-button-footer').addHidden()
  }

  componentDidMount() {
    window.fbAsyncInit = function() {
      FB.init({appId: '482010146568223',
               cookie: true,
               version: 'v2.2',
               autoLogAppEvents : true,
               xfbml: true,
               status: true});
    };
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    $.ajax({
      url: 'https://apis.google.com/js/client:plus.js?onload=gpAsyncInit',
      dataType: 'script',
      cache: true
    })
    window.gpAsyncInit = function() {
    }
  }

  handleGoogleLogin = (e) => {
    if (e.target.checked) {
      this.setState({loading: true})
      gapi.auth2.authorize({
        response_type: 'code',
        client_id: '1044201461425-0kma4id2dmnmspp65el6223n7k3hr6l2.apps.googleusercontent.com',
        scope: 'email profile'
      }, (response) => {
        if (response && !response.error) {
          jQuery.ajax({
            type: 'POST',
            url: '/users/auth/google_oauth2/callback?origin=job_modal',
            dataType: 'json',
            data: response
          }).done((result) => {
            actions.updateOmniAuthDetails(result)
            actions.updateEmailAddress(result.email)
            $('.two-button-footer').rmHidden()
            $('#modal-nav-right-button')[0].click()
          }).fail((result) => {
            this.setState({ errorMessage: result.responseJSON.error })
          })
        }
      })
    }
    this.setState({loading: false})
  }

  handleFacebookLogin = (e) => {
    if (e.target.checked) {
      this.setState({loading: true})
      FB.login((response) => {
        if (response.authResponse) {
          jQuery.ajax({
            crossDomain: true,
            url: '/users/auth/facebook/callback?origin=job_modal',
            type: 'POST',
            data: response,
            headers: { 'Access-Control-Allow-Origin': '*' }
          }).done((result) => {
            actions.updateOmniAuthDetails(result)
            actions.updateEmailAddress(result.email)
            $('.two-button-footer').rmHidden()
            $('#modal-nav-right-button')[0].click()
          }).fail((result) => {
            this.setState({ errorMessage: result.responseJSON.error })
          })
        }
      }, {scope: 'email'});
    }
    this.setState({loading: false})
  }

  previous = (error) => {
    actions.updateOmniAuthDetails({ 'account_from_omni_auth': false })
    $('.two-button-footer').rmHidden()
    this.setState({ provider: '',
                    errorMessage: '' })
  }

  render() {
    return (
      <div className="other-login p30 pt0 pb40">
        {
          data.omniAuthDetails['account_from_omni_auth'] === false ?
          <div>
            <p className="text-copy-0 pb8 text-center"><i className="ficon-minus"/><b> OR </b><i className="ficon-minus"/></p>
            <button className="btn btn-facebook btn-clear btn-lg btn-block" type="button" id="btn-facebook-modal" onClick={this.handleButtonClick} value='Facebook'>
              <div className="i ficon-facebook text-white">
                <span className="pl8">Login with Facebook</span>
              </div>
            </button>
            <button className="btn btn-clear btn-block" type="button" id="btn-google-modal" onClick={this.handleButtonClick} value='Google'>
              <img className="img-google mr8" src={'/assets/google-logo-icon.png'} />
              Sign in with Google
            </button>
          </div>
          :
          <div className="checkbox">
            <label>
              <input type="checkbox" name="newsletter" onChange={this.state.provider === 'Google' ? this.handleGoogleLogin : this.handleFacebookLogin}/>
              Opt in to receive (via SMS & email) special offers, news and the latest updates
              from ServiceSeeking.com.au
              <br/><br/>
              By ticking off the checkbox, you agree to receive quotes via email & SMS as well as marketing messages and to our <a className='no-wrap' href='https://www.serviceseeking.com.au/blog/terms-and-conditions/' target='_blank'>Terms & Conditions</a>
              <br/><br/>
              Please opt-in to continue
            </label>
            {
              this.state.errorMessage &&
              <div className="pt20 text-center">
                <span className="help-block has-error text-red">
                  { this.state.errorMessage }
                </span>
              </div>
            }
            {
              this.state.loading == true &&
              <div className="text-center">
                <span className="spinner-icon "/>
              </div>
            }
            <div className="pt30 pb30">
              <div className="col-sm-12 col-xs-12">
                <NavigationButtons
                  navButtonLayout='leftOnlyButton'
                  onStepProgression={this.props.handleSubmit}
                  onStepRetreat={this.previous}
                />
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default OmniAuthLogin
