const steps = {
  "159": { //Kitchen Renovations
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "Which of the following best match your job requirements?",
            "jobDescriptionKey": "Services required",
            "validation": {
              "groupName": "kitchen_renovations_services",
              "message": "Tick all that apply.",
            },
            "options": [
              {
                "value": "Full Kitchen Renovation",
                "text": "Full Kitchen Renovation"
              },
              {
                "value": "Cooker/Ventilation/Sink Installation",
                "text": "Cooker/Ventilation/Sink Installation"
              },
              {
                "value": "Bench Tops Installation or Renovation",
                "text": "Bench Tops Installation or Renovation"
              },
              {
                "value": "Tiling",
                "text": "Tiling"
              },
              {
                "value": "Plumbing",
                "text": "Plumbing"
              },
              {
                "value":"Electrical and Lighting",
                "text":"Electrical and Lighting"
              },
              {
                "value":"D.I.Y. Kitchen Kits",
                "text":"D.I.Y. Kitchen Kits"
              },
              {
                "value":"Other",
                "text":"Other"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you need assistance with council approval?",
            "jobDescriptionKey": "Council approval",
            "validation": {
              "groupName": "council_approval",
              "message": "Please choose whether yes or no."
            },
            "options": [
              {
                "value": "Yes, I need help getting council approval.",
                "text": "Yes, I need help getting council approval."
              },
              {
                "value": "No, I don't need council approval for this job.",
                "text": "No, I don't need council approval for this job."
              },
              {
                "value": "No, I already have council approval.",
                "text": "No, I already have council approval."
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you have plans drawn up?",
            "jobDescriptionKey": "Plans",
            "validation": {
              "groupName": "plans",
              "message": "Please select whether you require a plan or not."
            },
            "options": [
              {
                "value": "No plans required.",
                "text": "No plans required."
              },
              {
                "value": "No - I need a draftsman/architect.",
                "text": "No - I need a draftsman/architect."
              },
              {
                "value": "Yes, I already have the plans drawn up.",
                "text": "Yes, I already have the plans drawn up."
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Would you like the price of fixtures, tapware and appliances included in the quote?",
            "jobDescriptionKey": "Fixtures, tapware and appliances",
            "validation": {
              "groupName": "fixtures_tapware_and_appliances",
              "message": "Please select whether you need fixtures, tapware and appliances or not."
            },
            "options": [
              {
                "value": "Yes, include supply of fixtures, tapware and appliances.",
                "text": "Yes, include supply of fixtures, tapware and appliances."
              },
              {
                "value": "No, I'll supply these.",
                "text": "No, I'll supply these."
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
