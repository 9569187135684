const locationAutocomplete = function(selectorId, handleAutocompleteSelection, locationsToRemove) {
  var $selector = $(selectorId);

  $selector.autocomplete({
    source: function (request, cb) {
      $.ajax({
        url:     '/api/suburbs/lookup.json',
        method:  'GET',
        data:    { suburb: request.term },
        beforeSend: cb([{ label: "Loading Matches...", loading: true }])
      })
      .done(function(response) {
        var results = response.map(function(item) { return { id: item.id, value: item.name, lat: item.lat, lng: item.lng } });
        
        if (locationsToRemove) {
          results = results.filter(result => !locationsToRemove.includes(result.id));
        }

        cb(results)
      })
      .fail(function() {
        document.location = "/500";
      })
    },
    select: handleAutocompleteSelection,
    create: function() {
      $(this).data('ui-autocomplete')._renderItem = function (ul, item) {
        var term = this.term;
        if (item.loading) {
          return $('<li class="location-loading"></li>')
              .append("<i>" + item.label + "</i>")
              .appendTo(ul);
        } else {
          var locationName = highlightTerm(term, item.value);
          return $('<li></li>')
            .data("item.autocomplete", item)
            .append("<span>" + locationName + "</span>")
            .appendTo(ul);
        }
      }
    },
    open: function() {
      $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
    }
  })
}

function highlightTerm(term, content) {
  return content.replace(new RegExp("(?![^&;]+;)(?!<[^<>]*)(" +
                                    $.ui.autocomplete.escapeRegex(term) +
                                    ")(?![^<>]*>)(?![^&;]+;)","gi"),
                         "<strong>$1</strong>");
}

export default locationAutocomplete
