import React from 'react'

const Select = function(props) {
  function renderOptions() {
    let opts = props.options.slice(0)
    if (props.withEmptySelection) {
      opts.unshift({id:'', value:''})
    }
    let options = opts.map(function(option) {
      let text = option.value
      let id = option.id
      return <option key={id} data-id={id} value={id}>{text}</option>
    })
    return options
  }

  return (
    <select value={props.defaultValue} name={props.name} className="form-control" onChange={props.onChangeHandler}>
      {renderOptions()}
    </select>
  )
}

export default Select
