import React from 'react'

class RadioButtonRow extends React.Component {
  constructor (props) {
    super(props)
    this.radioButtonVisible = "col-xs-2 col-sm-2 circle"
    this.radioButtonHidden = `${this.radioButtonVisible} hidden`
  }

  render () {
    return (
      <div className={`radio ${(this.props.isChecked ? "active" : "")}`}>
        <div className="row" onClick={this.props.onClickHandler}>
          <div className={`col-xs-10 col-sm-10 ${this.props.className}`}>
            <div className="icon-parag icon-parag-16">
              {this.props.icon}
              <div className="text">
                {this.props.text}
              </div>
            </div>
          </div>
          <div className={this.props.isChecked ? this.radioButtonVisible :  this.radioButtonHidden}>
            <i className="ficon-circle-x pull-right"></i>
          </div>
          <input type="radio" name={this.props.name} ref={this.props.name} id={this.props.id}
            value={this.props.value} defaultChecked={this.props.isChecked ? 'checked' : ''}
            className="form-control invisible"/>
        </div>
      </div>
    )
  }
}

export default RadioButtonRow