import React, { Component } from 'react'
import { Tooltip } from 'flowbite'

class Tooltips extends Component {
  constructor(props) {
    super(props)
    this.tooltipInstance = null
  }

  initializeComponent() {
    const { buttonId, contentId, placement, triggerType } = this.props

    const $targetEl = document.getElementById(contentId)

    const $triggerEl = document.getElementById(buttonId)

    const options = {
        placement: placement || 'top',
        triggerType: triggerType || 'hover'
    }

    const instanceOptions = {
      id: contentId,
      override: true
    }

    this.tooltipInstance = new Tooltip($targetEl, $triggerEl, options, instanceOptions)
  }

  componentDidMount() {
    this.initializeComponent()
  }

  render() {
    const {
      bgColor,
      textColor,
      buttonId,
      contentId,
      content,
      iconName
    } = this.props

    return (
      <div>
        <button
          id={buttonId}
          type='button'
          className='bg-transparent'>
          <hero-icon-solid name={`${iconName || 'information-circle'}`}></hero-icon-solid>
        </button>
        <div
          id={contentId}
          role='tooltip'
          className={`tooltip invisible absolute z-10 inline-block rounded-lg ${bgColor || 'bg-gray-900'} px-3 py-2 text-sm font-medium ${textColor || 'text-white'} opacity-0 shadow-sm transition-opacity duration-300 whitespace-pre`}
        >
          {content}
          <div className='tooltip-arrow' data-popper-arrow></div>
        </div>
      </div>
    )
  }
}

export default Tooltips
