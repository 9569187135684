import React from 'react'

const ErrorMessage = React.createClass({
  render: function() {
    return (
      <div></div>
    )
  }
})

export default ErrorMessage