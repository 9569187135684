const steps = {
  "168": { //General Plumbing
    "steps": [
      {
        "title": {
          "text": "Residential or Commercial",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Is this a residential or commercial job?",
            "jobDescriptionKey": "Job type",
            "validation": {
              "groupName": "residential_commercial",
              "message": "Please choose type of plumbing required.",
            },
            "options": [
              {
                "value": "Residential job",
                "text": "Residential",
              },
              {
                "value": "Commercial job",
                "text": "Commercial",
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Plumbing Job Type",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "What Plumbing work do you need done?",
            "jobDescriptionKey": "Plumbing type",
            "validation": {
              "groupName": "what_plumbing_work",
              "message": "Please select what plumbing work you need done."
            },
            "options": [
              {
                "value": "Fix leaking taps",
                "text": "Fix leaking taps"
              },
              {
                "value": "Install new bathroom/kitchen fixtures",
                "text": "Install new bathroom/kitchen fixtures"
              },
              {
                "value": "Un-block drains or sewers",
                "text": "Un-block drains or sewers"
              },
              {
                "value": "Repair or install a hot-water syste",
                "text": "Repair or install a hot-water system"
              },
              {
                "value": "Install or repair gas piping",
                "text": "Install or repair gas piping"
              },
              {
                "value": "Appliance installation (dishwashers, ovens etc)",
                "text": "Appliance installation (dishwashers, ovens etc)"
              },
              {
                "value": "Plumbing for bathroom/kitchen renovation",
                "text": "Plumbing for bathroom/kitchen renovation"
              },
              {
                "value": "Toilet Repairs",
                "text": "Toilet Repairs"
              },
              {
                "value": "Other (please specify in the description)",
                "text": "Other (please specify in the description)"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
