import React from 'react'

import coreUtils from 'common/src/utils/coreUtils'
import data, { jobData } from 'common/components/job_wizard/data/data'
import Header from 'common/components/job_wizard/Header'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import NavigationButtons from 'common/components/NavigationButtons'
import postingProgressStore from 'common/components/job_wizard/data/posting_progress/store'
import StepSequence from 'common/components/job_wizard/StepSequence'
import EmailAddress from 'common/components/job_wizard/steps/EmailAddress'
import OmniAuthLogin from 'common/components/job_wizard/steps/OmniAuthLogin'
import SelectCategory from 'job_wizard_mobile/components/steps/SelectCategory'
import EnterEmailAddress from 'job_wizard_mobile/components/steps/EnterEmailAddress'
import LoginOmniauth from 'job_wizard_mobile/components/steps/LoginOmniauth'
import GetFirstName from 'job_wizard_mobile/components/steps/GetFirstName'
import ChooseSchedule from 'job_wizard_mobile/components/steps/ChooseSchedule'
import ChooseSiteVisit from 'job_wizard_mobile/components/steps/ChooseSiteVisit'
import EnterDescription from 'job_wizard_mobile/components/steps/EnterDescription'
import CheckJobDuplicate from 'job_wizard_mobile/components/steps/CheckJobDuplicate'
import JobSummary from 'job_wizard_mobile/components/steps/JobSummary'
import actions from 'common/components/job_wizard/data/actions'

const Step = React.createClass({
  onStepProgression: function() {
    if (this.props.currentStep.onSubmit) {
      if (StepSequence.stepsWithProgress().includes(this.props.currentStep.displayName)) {
        actions.updateProgressWidth(this.props.currentStep.displayName)
      }
      this.props.currentStep.onSubmit(this.props.onStepProgression, this.props.onRequest, this.props.onRequestCompletion, this.props.onStepRetreat)
    }
  },

  onStepRetreat: function() {
    this.props.currentStep.onLeftNavButtonClick ? this.props.currentStep.onLeftNavButtonClick(this.props.onStepProgression) : this.props.onStepRetreat()
  },

  onModalExit: function() {
    var self = this
    var $jobModal = $('#job-modal')

    if (data.id !== null) {
      $jobModal.find('.close-modal-warning').velocity('slideUp').end()
        .find('.close').rmHidden()
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', UpdateJob, 'exit')
      return;
    }

    postingProgressStore.updateTracking({ exitType: 'modal_posting_blicked' })
    postingProgressStore.createPostingProgress()
    this.setState({ job: jobData.resetData() })
    this.setState({ currentStep: StepSequence.getSteps()[0] })
    $jobModal.modal('hide')
    $jobModal.find('.modal-stay').trigger('click') // hide this warning
    JobWizardEventEmitter.emit('job:wizard:advanceToStep', StepSequence.getSteps()[0])
    coreUtils.googleAnalytics({ hitType: 'event', eventCategory: 'jobpost', eventAction: 'postingblocked' })
  },

  onSecondaryNavButtonClick: function() {
    this.props.currentStep.onSecondaryNavButtonClick ? this.props.currentStep.onSecondaryNavButtonClick() : null
  },

  componentWillEnter(callback) {
    const el = findDOMNode(this);
    TweenMax.fromTo(el, 0.3, {y: 100, opacity: 0}, {y: 0, opacity: 1, onComplete: callback});
  },

  navButtonLayout: function() {
    var buttonDefault = coreUtils.mobilecheck() === true ? 'upAndDownButton' : 'default'
    
    return this.props.currentStep.buttonLayout ? this.props.currentStep.buttonLayout() : buttonDefault
  },

  handleClick: function(e) {
    let text = $(e.target).parents('.form-group').last().find('h3').text()
    postingProgressStore.updateTracking({lastQuestion: text})
  },

  renderOmniAuthLogin: function() {
    return(
      <div>
        { this.props.currentStep === EmailAddress &&
          <OmniAuthLogin handleSubmit={this.props.currentStep.onSubmit} />
        }
      </div>
    )
  },

  render: function() {
    const ComponentClass = this.props.currentStep.component;
    return (
      <div className="job-modal-step"> {/* extra div below needed by react */}
        <Header/>
        <div className="modal-body job-form-body pb0 pt0 radius-16-bottom" onClick={this.handleClick} onInput={this.handleClick}>
          <ComponentClass
            onStepProgression={this.onStepProgression}
            onRequest={this.props.onRequest}
            onRequestCompletion={this.props.onRequestCompletion}
            onStepRetreat={this.props.onStepRetreat}
          />
        </div>
        <div className="modal-footer pt0">
          <NavigationButtons
            navButtonLayout={this.navButtonLayout()}
            buttons={this.props.currentStep.navigation} //overrides for button text etc
            onStepProgression={this.onStepProgression}
            onStepRetreat={this.onStepRetreat}
            onSecondaryNavButtonClick={this.onSecondaryNavButtonClick}
            onModalExit={this.onModalExit}
          />
        </div>
        { this.renderOmniAuthLogin() }
      </div>
    )
  }
})


export default Step