// html
//     #mobile-nav
//     #page-nav
//         #mobile-nav-open
var html$      = $('html');
var nav$       = $('#mobile-nav');
var navOpen$   = $('#mobile-nav-open');
var pageWrap$  = $('#page-wrap');
var navClass   = 'show-mobile-nav';
var click      = 'click';
var hideNav = function() {
    html$
        .removeClass(navClass)
        .css('height', 'auto');

    pageWrap$ // .css('height', 'auto')
        .off(click);
};

nav$
.on(click, 'button.close-button', hideNav);

navOpen$
.on(click, function() {
    // nav opened
    if (html$.hasClass(navClass)) {
        hideNav();
    } // open nav
    else {
        html$
            .addClass(navClass)
            .css('height', nav$.find('.nav-wrapper').outerHeight() + 'px');

        // var wh = $(win).height(),
        //     nh = nav$.height(),
        //     h  = (nh > wh) ? nav$.height() : wh;
        pageWrap$ // .css('height', h)
            .on(click, function() {
                hideNav();
                return false;
            }); // nav$.css('min-height', h);
    }

    return false;
});
