const steps = {
  "2408": { //Lawn Mowing Services
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "What type of property do you have?",
            "jobDescriptionKey": "Property type",
            "validation": {
              "groupName": "property_type",
              "message": "Please select a property type"
            },
            "options": [
              {
                "value": "Home",
                "text": "Home"
              },
              {
                "value": "Multi-Unit Building",
                "text": "Multi-Unit Building"
              },
							{
								"value": "Commercial",
								"text": "Commercial"
							},
							{
								"value": "Other (please specify in the description)",
								"text": "Other (please specify in the description)"
							}
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "Which area(s) need lawn mowing?",
            "jobDescriptionKey": "Lawn mowing area",
            "validation": {
              "groupName": "lawn_mowing_area",
              "message": "Tick all that apply."
            },
            "options": [
              {
                "value": "Front Yard",
                "text": "Front Yard"
              },
              {
                "value": "Back Yard",
                "text": "Back Yard"
              },
							{
                "value": "Side Yard(s)",
								"text": "Side Yard(s)"
							},
							{
								"value": "Entire Property",
								"text": "Entire Property"
							},
							{
								"value": "Other (please specify in the description)",
								"text": "Other (please specify in the description)"
							}
            ]
          }
        ]
      },
			{
				"title": {
					"text": "Job Details",
					"icon": "ficon-quotes"
				},
				"questions": [
					{
						"type": "Radio",
						"label": "How big is your grassed area?",
						"jobDescriptionKey": "Grassed area size",
						"validation": {
							"groupName": "grassed-area-size",
							"message": "Please select a size"
						},
						"options": [
							{
								"value": "Small",
								"text": "Small"
							},
							{
								"value": "Medium",
								"text": "Medium"
							},
							{
								"value": "Large",
								"text": "Large"
							},
							{
								"value": "Very Large",
								"text": "Very Large"
							},
							{
								"value": "I'm not sure",
								"text": "I'm not sure"
							}
						]
					}
				]
			},
			{
				"title": {
					"text": "Job Details",
					"icon": "ficon-quotes"
				},
				"questions": [
					{
						"type": "Radio",
						"label": "When was the last time your grassed area was cut?",
						"jobDescriptionKey": "Grassed area cut date",
						"validation": {
							"groupName": "grassed-area-cut-date",
							"message": "Please select a date"
						},
						"options": [
							{
								"value": "Less than a week ago",
								"text": "Less than a week ago"
							},
							{
								"value": "1-3 weeks ago",
								"text": "1-3 weeks ago"
							},
							{
								"value": "Over a month ago",
								"text": "Over a month ago"
							}
						]
					}
				]
			},
			{
				"title": {
					"text": "Job Details",
					"icon": "ficon-quotes"
				},
				"questions": [
					{
						"type": "Radio",
						"label": "How often would you like your grass mowed?",
						"jobDescriptionKey": "Grass mowing recurrence",
						"validation": {
							"groupName": "grass-mowing-recurrence",
							"message": "Please select a recurrence"
						},
						"options": [
							{
								"value": "One time only",
								"text": "One time only"
							},
							{
								"value": "Weekly",
								"text": "Weekly"
							},
							{
								"value": "Fortnightly",
								"text": "Fortnightly"
							},
							{
								"value": "Monthly",
								"text": "Monthly"
							},
							{
								"value": "Other (please specify in the description)",
								"text": "Other (please specify in the description)"
							}
						]
					}
				]
			}
    ]
  }
}

export default steps
