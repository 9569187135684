const buttons = document.querySelectorAll('.dropdown-button');
const menus = document.querySelectorAll('.dropdown-menu');

buttons.forEach((button, index) => {
    button.addEventListener('click', () => {
        const menu = menus[index];
        if (menu.classList.contains('hidden')) {
            menu.classList.remove('hidden');
            menu.classList.add('transition', 'ease-out', 'duration-200', 'opacity-100', 'translate-y-0');
            menu.classList.remove('opacity-0', 'translate-y-1');
        } else {
            menu.classList.add('transition', 'ease-in', 'duration-150', 'opacity-0', 'translate-y-1');
            menu.classList.remove('opacity-100', 'translate-y-0');

            setTimeout(() => {
                menu.classList.add('hidden');
            }, 150);
        }
    });
});

const mobileMenu = document.getElementById('mobile-menu');
const menuButton = document.getElementById('menu-button');
const closeMenuButton = document.getElementById('close-button');

function openMobileMenu() {
    mobileMenu.classList.add('block');
    mobileMenu.classList.remove('hidden');
}

function closeMobileMenu() {
    mobileMenu.classList.add('hidden');
    mobileMenu.classList.remove('block');
}


if (menuButton) {
    menuButton.addEventListener('click', function () {
        openMobileMenu();
    });
}


if (closeMenuButton) {
    closeMenuButton.addEventListener('click', function () {
        closeMobileMenu();
    });
}
