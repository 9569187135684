import React from 'react'

import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'

import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import Budget from 'common/components/job_wizard/steps/Budget'
import ContactPreference from 'common/components/job_wizard/steps/ContactPreference'
import SiteVisit from 'common/components/job_wizard/steps/SiteVisit'
import UpdateJob from 'common/components/job_wizard/steps/UpdateJob'

import coreUtils from 'common/src/utils/coreUtils'

const MoreDetails = {
  displayName: 'MoreDetails',

  onLeftNavButtonClick(onStepProgression) {
    if (data.showSuppressants) {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', ContactPreference)
    }
  },

  buttonLayout: function() {
    return 'none'
  },

  component: React.createClass({
    getInitialState: function() {
      return {
        loading: false
      }
    },

    componentDidMount: function() {
      this.toggleModalFooter('hide')

      if (data.categoryId && data.category) {
        let subcategory = { id: data.categoryId, value: data.category }
        this.selectSubcategory(subcategory)
      }
    },

    moreDetailsChosen: function() {
      JobWizardEventEmitter.emit('job:wizard:advanceToNextStep')
      this.toggleModalFooter('show')

      if (data.subcategoryQuestions[data.category_id]) {
        JobWizardEventEmitter.emit('job:wizard:advanceToNextStep')
      }
    },

    skipDetailsChosen: function() {
      coreUtils.appMsg({ id: data.id, status: 'rush' }) // used by mobile app for controlling the flow
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', UpdateJob)
    },

    toggleModalFooter: function(action) {
      let footer = $('#job-modal').find('.modal-footer')
      action === 'show' ? footer.show() : footer.hide()
    },

    showModalCloseButton: function() {
      $('#job-modal')
        .find('.modal-header')
        .find('.close')
        .rmHidden()
    },

    selectSubcategory: function(subcategory) {
      actions.updateCategory(subcategory)
      actions.updateSubCategory(subcategory, 'add')
      JobWizardEventEmitter.emit('job:wizard:categorySelected', null, { item: subcategory })
    },

    redirectToJobPage: function(){
      document.location.href = this.redirectUrl()
    },

    redirectUrl: function() {
      switch (data.referrer.cta) {
        case 'profile': {
          return `/jobs/${this.job.id}/profilejob?successful_job=true`
        }
        case 'business_directory_card': {
          return `/jobs/${this.job.id}/bcardjob?successful_job=true`
        }
        default: {
          return `/jobs/${this.job.id}/posted?successful_job=true`
        }
      }
    },

    render: function() {
      return (
        <div>
          <div className="form-group pb30">
            <h3 className="mb15 mt10">What would you like to do?</h3>
            <div className={`${this.state.loading ? '' : 'hidden'}`}>
              <div className="modal-body job-form-body radius-16-bottom">
                <div className="spinner-icon text-center"/>
              </div>
            </div>
            <div className={`${this.state.loading ? 'hidden' : ''} radio-button-box minor-bold`} id="more-details-radio-group">
              <div className="radio more-details" onClick={this.moreDetailsChosen}>
                <div className="row" role="tab" data-toggle="tab">
                    <div className="col-xs-10 col-sm-10">Answer a few more questions now (so businesses have more info when
                        they are invited to quote)</div>
                  <input type="radio" name="more_details" value="true" className="form-control invisible"/>
                </div>
              </div>
              <div className="radio no-details" role="tab" data-toggle="tab" onClick={this.skipDetailsChosen}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Invite businesses to quote now</div>
                  <input type="radio" name="more_details" value="false" className="form-control invisible"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default MoreDetails
