const steps = {
  "161": { //General Gardening
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "What gardening service(s) do you need?",
            "jobDescriptionKey": "Gardening services",
            "validation": {
              "groupName": "gardening_services",
              "message": "Please tick all that apply."
            },
            "options": [
              {
                "value": "General Gardening",
                "text": "General Gardening"
              },
              {
                "value": "Lawn Mowing",
                "text": "Lawn Mowing"
              },
              {
                "value": "Landscaping/Planting",
                "text": "Landscaping/Planting"
              },
              {
                "value": "Pruning/Hedging",
                "text": "Pruning/Hedging"
              },
              {
                "value": "Tree Lopping",
                "text": "Tree Lopping"
              },
              {
                "value": "Turf Laying",
                "text": "Turf Laying"
              },
              {
                "value": "Garden Clean Up",
                "text": "Garden Clean Up"
              },
              {
                "value": "Other (please specify in description)",
                "text": "Other (please specify in description)"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Is this a one off job or an ongoing job?",
            "jobDescriptionKey": "One off or ongoing job",
            "validation": {
              "groupName": "one_off_or_ongoing",
              "message": "Please select either One off or ongoing job"
            },
            "options": [
              {
                "value": "This is a one off job",
                "text": "This is a one off job"
              },
              {
                "value": "This is an ongoing job",
                "text": "This is an ongoing job"
              }
            ]
          },
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "What is the approximate size of the garden?",
            "jobDescriptionKey": "Garden size",
            "validation": {
              "groupName": "garden_size",
              "message": "Please select a garden size"
            },
            "options": [
              {
                "value": "Small",
                "text": "Small"
              },
              {
                "value": "Medium",
                "text": "Medium"
              },
              {
                "value": "Large",
                "text": "Large"
              },
              {
                "value": "Commercial Grounds",
                "text": "Commercial Grounds"
              }
            ]
          },
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you need quotes on new plants/turf?",
            "jobDescriptionKey": "Quotes on new plants/turf",
            "validation": {
              "groupName": "quotes_on_plants_turf",
              "message": "Please select an option"
            },
            "options": [
              {
                "value": "Yes, I may also need new plants/turf",
                "text": "Yes, I may also need new plants/turf"
              },
              {
                "value": "No, I don't need new plants/turf",
                "text": "No, I don't need new plants/turf"
              }
            ]
          },
        ]
      }
    ]
  }
}

export default steps
