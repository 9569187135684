import autoScroll from 'dom-autoscroller';
import dragula from 'dragula';

export let draker = {
    start: ({ containers, margin, maxSpeed, unmovableContainers, wrapper }) => {
        containers = containers || [];
        margin = margin || 20;
        maxSpeed = maxSpeed || 5;
        unmovableContainers = unmovableContainers || [];
        wrapper = wrapper || document.body;

        const drake = dragula(containers, {
            revertOnSpill: true,
            moves: function (el, source, handle, sibling) {
                if (unmovableContainers.includes(source.id)) {
                    return false
                }

                return true
            }
        });

        const scroll = autoScroll([
            wrapper
            ],{
            margin: margin,
            maxSpeed: maxSpeed,
            scrollWhenOutside: true,
            autoScroll: function() {
                return this.down && drake.dragging;
            }
        });

        return drake;
    }
}
