const steps = {
    "2526": { //Local removalist
      "steps": [{
        "title": {
          "text": "Type of removals job",
          "icon": "ficon-quotes"
        },
        "questions": [{
          "type": "Radio",
          "label": "What type of removals job is this?",
          "jobDescriptionKey": "Removal type",
          "validation": {
            "groupName": "removal_type",
            "message": "Please choose type of removalist required.",
          },
          "options": [{
            "value": "Moving house",
            "text": "Moving house/apartment",
          }, {
            "value": "Moving a few items",
            "text": "Moving a few items only",
          }]
        }]
      },
      {
        "title": {
          "text": "List of items?",
          "icon": "ficon-quotes"
        },
        "questions": [{
          "type": "TextArea",
          "label": "Please list the items you wish to move",
          "jobDescriptionKey": "Items to move",
          "validation": {
            "minTextLength": 5,
            "groupName": "items_to_move",
            "message": "Please list the items you wish to move",
          },
          "options": [{
            "value": "Item list:",
            "text": "eg 2 x Queen sized beds, 1 single bed, 1 dining table, 6 chairs, 20 boxes, TV, TV stand etc"
          }]
        }]
      },
      {
        "title": {
          "text": "Pick up suburb?",
          "icon": "ficon-quotes"
        },
        "questions": [{
          "type": "SuburbInput",
          "label": "What's the pick up suburb?",
          "jobDescriptionKey": "Pickup suburb",
          "validation": {
            "groupName": "pickup_suburb",
            "message": "Please provide an Australian suburb or postcode.",
          },
          "options": [{
            "value": "Pickup suburb",
            "text": "Pickup suburb",
          }]
        }]
      },
      {
        "title": {
          "text": "Moving from property type?",
          "icon": "ficon-quotes"
        },
        "questions": [{
          "type": "Radio",
          "label": "What kind of property are you moving from?",
          "jobDescriptionKey": "Moving from",
          "validation": {
            "groupName": "moving_from_type",
            "message": "Please choose a property type.",
          },
          "options": [{
            "value": "unit/apartment",
            "text": "Unit/Apartment",
          },
          {
            "value": "house",
            "text": "House",
          },
          {
            "value": "storage unit",
            "text": "Storage unit ",
          }]
        }]
      },
      {
        "title": {
          "text": "Property access?",
          "icon": "ficon-quotes"
        },
        "questions": [{
          "type": "Radio",
          "label": "How can the removals company access the property you're moving from?",
          "jobDescriptionKey": "Moving from access via",
          "validation": {
            "groupName": "property_access",
            "message": "Please choose type of access.",
          },
          "options": [{
            "value": "Street",
            "text": "Access from street",
          }, {
            "value": "Stairs",
            "text": "Access via stairs",
          },{
            "value": "Lift",
            "text": "Access via lift",
          }]
        }]
      },
      {
        "title": {
          "text": "How many bedrooms?",
          "icon": "ficon-quotes"
        },
        "questions": [{
          "type": "Radio",
          "label": "How many bedrooms?",
          "jobDescriptionKey": "Number of bedrooms",
          "validation": {
            "groupName": "number_of_bedrooms",
            "message": "Please choose number of bedrooms",
          },
          "options": [{
            "value": "one bedroom",
            "text": "1",
          }, {
            "value": "two bedrooms",
            "text": "2",
          },{
            "value": "three bedrooms",
            "text": "3",
          },{
            "value": "four plus",
            "text": "4+",
          },{
            "value": "not applicable",
            "text": "Not applicable",
          }]
        }]
      },
      {
        "title": {
          "text": "Drop off suburb?",
          "icon": "ficon-quotes"
        },
        "questions": [{
          "type": "SuburbInput",
          "label": "What's the drop off suburb?",
          "jobDescriptionKey": "Drop off suburb",
          "validation": {
            "groupName": "drop_off_suburb",
            "message": "Please provide an Australian suburb or postcode.",
          },
          "options": [{
            "value": "Drop off suburb",
            "text": "Drop off suburb",
          }]
        }]
      },
      {
        "title": {
          "text": "Moving to property type?",
          "icon": "ficon-quotes"
        },
        "questions": [{
          "type": "Radio",
          "label": "What kind of property are you moving into?",
          "jobDescriptionKey": "Moving to",
          "validation": {
            "groupName": "moving_into_type",
            "message": "Please choose a property type.",
          },
          "options": [{
            "value": "unit/apartment",
            "text": "Unit/Apartment",
          },
          {
            "value": "house",
            "text": "House",
          },
          {
            "value": "storage unit",
            "text": "Storage unit ",
          }]
        }]
      },
      {
        "title": {
          "text": "Property access moving to?",
          "icon": "ficon-quotes"
        },
        "questions": [{
          "type": "Radio",
          "label": "How can the removals company access the property you're moving into?",
          "jobDescriptionKey": "Moving to access via",
          "validation": {
            "groupName": "property_access_moving_to",
            "message": "Please choose type of access.",
          },
          "options": [{
            "value": "Street",
            "text": "Access from street",
          }, {
            "value": "Stairs",
            "text": "Access via stairs",
          },{
            "value": "Lift",
            "text": "Access via lift",
          }]
        }]
      }
    ]
  }
}

export default steps
