import React from 'react'
import moment from 'moment'

const DateInput = function(props) {
  return (
    <div className='form-group'>
      <div className='col-xs-6'>
        <label className='pt10 pb10'>{props.label}</label>
      </div>
      <div className='col-xs-6'>
        <input name={props.name} type='date' min={moment().format('YYYY-MM-DD')} className='form-control' value={props.value} onChange={props.onChangeHandler} onMouseLeave={props.onChangeHandler}/>

        { props.dateValid == null || props.dateValid ?
          ''
          :
          <div>
            <span className='text-red'> 
             Invalid Date!
            </span>
          </div>
        }     
      </div>
    </div>
  )
}

export default DateInput
