import React from 'react'
import coreUtils from 'common/src/utils/coreUtils'
import StepSequence from 'common/components/job_wizard/StepSequence'
import data from 'common/components/job_wizard/data/data'
import MobileChannelHandler from 'job_wizard_mobile/components/MobileChannelHandler'
class ProgressBar extends React.Component {
  constructor(props) {
    super(props)
  }

  calculateWidthMobile() {
    let stepIndex = this.props.steps.indexOf(this.props.currentStep)
    let numberOfSteps = this.props.steps.length - 1

    if (this.props.currentStep.displayName == 'JobSummary' || this.props.currentStep.displayName == 'SubmitJob' ) {
      stepIndex = 1
      numberOfSteps = 1
    }

    return ((stepIndex / numberOfSteps) * 100) + '%'
  }

  calculateWidth() {
    let displayName = data.progressWidth

    if (this.canProgress()) {
      displayName = this.props.currentStep.displayName
    }
    let stepsWithProgress = StepSequence.stepsWithProgress()
    let stepIndex = stepsWithProgress.indexOf(displayName)
    let numberOfSteps = stepsWithProgress.length + 1 //plus 1 for EnterAboutMe which is not included in webpack

    if (this.props.currentStep.displayName === 'PostJob' || this.props.currentStep.displayName === 'ContactPreference') {
      if (stepsWithProgress.includes('Contact')) {
        stepIndex = stepsWithProgress.indexOf('Contact')
      }
      else if (stepsWithProgress.includes('EmailAddress')) {
        stepIndex = stepsWithProgress.indexOf('EmailAddress')
      }

      numberOfSteps = stepsWithProgress.length
    }

    return ((stepIndex / numberOfSteps) * 100) + '%'
  }

  canProgress() {
    return StepSequence.stepsWithProgress().includes(this.props.currentStep.displayName)
  }

  render() {
    if (MobileChannelHandler.hasMobileChannel()) {
      return (
        <div className="progress mb0 text-align-center" width="100%" style={{ marginRight: '24px' }}>
          <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="0" className="progress-bar" role="progressbar" style={{ width: (coreUtils.mobilecheck() ? this.calculateWidthMobile() : this.calculateWidth()) }}>
            <span className="sr-only">Step 1 of 2</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row">
          <div className="col-xs-10 col-sm-11 pr8 sm-pr16">
            <div className="progress mb0 text-align-center" width="100%">
              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="0" className="progress-bar" role="progressbar" style={{ width: (coreUtils.mobilecheck() ? this.calculateWidthMobile() : this.calculateWidth()) }}>
                <span className="sr-only">Step 1 of 2</span>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default ProgressBar
