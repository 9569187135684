const steps = {
  "128": { // Bathroom Renovations
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "What specific services do you need?",
            "jobDescriptionKey": "Services needed",
            "validation": {
              "groupName": "services_needed",
              "message": "Tick all that apply."
            },
            "options": [
              {
                "value": "Full Bathroom Renovation",
                "text": "Full Bathroom Renovation"
              },
              {
                "value": "Bath/Shower/Toilet Installation",
                "text": "Bath/Shower/Toilet Installation"
              },
              {
                "value": "Tiling",
                "text": "Tiling"
              },
              {
                "value": "Plumbing",
                "text": "Plumbing"
              },
              {
                "value": "Waterproofing",
                "text": "Waterproofing"
              },
              {
                "value": "Electrical and Lighting",
                "text": "Electrical and Lighting"
              },
              {
                "value": "Sink & Vanity",
                "text": "Sink & Vanity"
              },
              {
                "value": "Bathroom Ventilation",
                "text": "Bathroom Ventilation"
              },
              {
                "value": "Other (please specify in description)",
                "text": "Other (please specify in description)"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you need help with council approval?",
            "jobDescriptionKey": "Council approval",
            "validation": {
              "groupName": "council_approval",
              "message": "Please specify whether you need help with council approval."
            },
            "options": [
              {
                "value": "Yes, I need help getting council approval",
                "text": "Yes, I need help getting council approval"
              },
              {
                "value": "No, I don't need council approval for this job",
                "text": "No, I don't need council approval for this job"
              },
              {
                "value": "No, I already have council approval",
                "text": "No, I already have council approval"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you have plans drawn up?",
            "jobDescriptionKey": "Plans",
            "validation": {
              "groupName": "plans_drawn_up",
              "message": "Please specify if you have plans drawn up."
            },
            "options": [
              {
                "value": "No plans required",
                "text": "No plans required"
              },
              {
                "value": "No, I need a draftsman/architect",
                "text": "No, I need a draftsman/architect"
              },
              {
                "value": "Yes, I already have the plans drawn up",
                "text": "Yes, I already have the plans drawn up"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you need materials, fixtures and fittings?",
            "jobDescriptionKey": "Materials, fixtures and fittings",
            "validation": {
              "groupName": "materials_fixtures_fittings",
              "message": "Please specify if you need any materials, fixtures and fittings."
            },
            "options": [
              {
                "value": "Yes, I need materials for the job",
                "text": "Yes, I need materials for the job"
              },
              {
                "value": "No, I already have the materials for this job",
                "text": "No, I already have the materials for this job"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps