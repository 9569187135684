import React from 'react'

const SimpleCheckbox = function(props) {
  return (
    <div className={props.additionalClasses}>
      <input className="mr5" type="checkbox" id={props.id} name={props.name} value={props.value} defaultChecked={props.isChecked} onClick={props.onClickHandler}/>
      <label htmlFor={props.id}>{props.text}</label>
    </div>
  )
}

export default SimpleCheckbox
