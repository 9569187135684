import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import postingProgressStore from 'common/components/job_wizard/data/posting_progress/store'
import StepSequence from 'common/components/job_wizard/StepSequence'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import Contact from 'common/components/job_wizard/steps/Contact'
import MatchedBusinessesInfo from 'common/components/job_wizard/MatchedBusinessesInfo'
import Password from 'common/components/job_wizard/steps/Password'
import CreateAccount from 'common/components/job_wizard/steps/CreateAccount'
import OmniAuthLogin from 'common/components/job_wizard/steps/OmniAuthLogin'
import PostJob from 'common/components/job_wizard/steps/PostJob'
import API from 'common/src/api'
import coreUtils from 'common/src/utils/coreUtils'

const EmailAddress = {
  displayName: 'EmailAddress',
  newExisingUserStore: { existingUser: false, newUser: false },
  errorMessage: '',
  validator: '',

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    let emailCheck = () => {
      $.ajax({
        url: window.App.url + '/api/users/check_email',
        dataType: 'json',
        cache: false,
        data: { email: data.emailAddress }
      }).fail(function () {
        document.location = "/500"
      }).done((result) => {
        if (result.status === 'true') {
          this.newExisingUserStore = { existingUser: true, newUser: false }
          actions.updatePhoneNumber(result.phone)
          JobWizardEventEmitter.emit('job:wizard:existingUser', {...this.newExisingUserStore, ...{isEmailDisabled: true}})
          this.validator.resetForm()
        }
        else if (result.status === 'valid') {
          this.newExisingUserStore = { existingUser: false, newUser: true }
          JobWizardEventEmitter.emit('job:wizard:existingUser', {...this.newExisingUserStore, ...{isEmailDisabled: true}})
          this.validator.resetForm()
        }
        else {
          this.validator.updateMessage('email_address', 'notEmpty', result.error)
          this.validator.updateStatus('email_address', 'INVALID', 'notEmpty')
        }
      })
    }

    let createAccount = () => {
      let $createAccount = $('.create-account')
      let subscribeToMessages = true

      var userObj = API.V2.userCreate(
        {
          email: data.emailAddress,
          name: $createAccount.find('#first-name').val(),
          phone: $createAccount.find('#contact-phone').val(),
          'autogenerate-password': true
        },
        {
          type: 'email-preferences',
          attrs: {
            'ss-update': subscribeToMessages,
            'sms-offers': subscribeToMessages
          }
        });

      userObj.beforeSend = onRequest

      $.ajax(userObj)
        .always(onRequestCompletion)
        .done(function (data) {
          // send to token to mobile app
          coreUtils.googleAnalytics({ hitType: 'event', eventCategory: 'jobpost', eventAction: 'jobconvert' })
          coreUtils.appMsg({ id: data.data.id, token: data.meta.token, version: '1.1' })
          $('#user-session-id').attr('title', data.data.id)
          window.App.currentUserId = data.data.id;
          window.App.userPhoneIsValid = data.meta['valid-phone']

          if (coreUtils.userHasValidPhone() == true) {
            StepSequence.removeStepByType(Contact)  
          }
          onStepProgression()
        })
        .fail((data) => {
          if (data.status === 422) {
            let res = data.responseJSON
            for (let i = 0; i < res.errors.length; i++) {
              this.errorMessage = this.errorMessage += ' ' + res.errors[i].detail
            }

            this.newExisingUserStore = { existingUser: false, newUser: true }
            JobWizardEventEmitter.emit('job:wizard:existingUser', {...this.newExisingUserStore, ...{isEmailDisabled: true}})
            this.validator.resetForm()

          } else {
            this.errorMessage = 'Sorry we are unable to process your request at this time. Please try again later.'
            document.location.href = '/500'
            onRequestCompletion()
          }
        })
    }

    const passwordCheck = (params) => {
      var sessionObj = API.V2.userSession({
        email: data.emailAddress,
        password: $('#password').val(),
        reauth: true
      })

      sessionObj.beforeSend = onRequest // changes wizard loading state to true for the spinner

      $.ajax(sessionObj)
        .always(function () {
          onRequestCompletion() // changes wizard loading state to false or remove spinner
        })
        .done((result) => {
          postingProgressStore.updateTracking({ UserLoggedIn: true })
          // send to token to mobile apps
          if (data.omniAuthDetails['oauth_email_exists']) {
            attachOmniAuthToUser()
          }

          coreUtils.googleAnalytics({ hitType: 'event', eventCategory: 'jobpost', eventAction: 'jobconvert' })
          coreUtils.appMsg({ id: result.data.id, token: result.meta.token, version: '1.1' })
          $('#user-session-id').attr('title', result.data.id)
          window.App.currentUserId = result.data.id
          window.App.userPhoneIsValid = result.meta['valid-phone']

          if (coreUtils.userHasValidPhone() == true) {
            StepSequence.removeStepByType(Contact)  
          }
          onStepProgression()
        })
        .fail((xhr, textStatus, errorThrown) => {
          if (xhr.status === 401 || xhr.status === 422) {
            let errorMessage = xhr.responseJSON.errors[0].detail

            this.newExisingUserStore = { existingUser: true, newUser: false }
            JobWizardEventEmitter.emit('job:wizard:existingUser', {...this.newExisingUserStore, ...{isEmailDisabled: true}})

            this.validator.resetForm()
            $('.incorrect-password').rmHidden()
            $('.reset-password').css('marginRight', '90px')
          } else {
            document.location.href = '/500'
          }
        })

      function attachOmniAuthToUser(){
        $.ajax({
          url: '/users/auth/attach_oauth_uid',
          dataType: 'json',
          method: 'POST',
          data: {
                  user_id: data.omniAuthDetails['user_id'],
                  oauth_uid: data.omniAuthDetails['oauth_uid'],
                  oauth_provider: data.omniAuthDetails['oauth_provider'],
                }
        })
      }
    }

    let newPasswordCheck = () => {
      let newPassword = $("#new-password").val()
      if (newPassword !== '' && newPassword !== null) {
        $.ajax({
          url: '/api/users/update_password',
          dataType: 'json',
          data: { email: data.emailAddress,
                  otp: data.otp,
                  password: newPassword,
                  password_confirmation: newPassword
                }
        }).done((data) => {
          if (data.result) {
            postingProgressStore.updateTracking({ UserLoggedIn: true })
            coreUtils.googleAnalytics({ hitType: 'event', eventCategory: 'jobpost', eventAction: 'jobconvert' })
            $('#user-session-id').attr('title', data.session_id)
            window.App.currentUserId = data.user_id

            if (data.has_phone === true) {
              StepSequence.removeStepByType(Contact)
            }
            onStepProgression()
          }
          else {
            document.location.href = '/500'
          }
        })
      }
      else {
        $('.password-error').rmHidden()
      } 
    }

    let checkPhoneValidation = () => {
      $.ajax({
        url: '/users/validate_contact_phone',
        dataType: 'json',
        data: { user_attributes: {
                  contact_phone: this.validator.getFieldElements('contact_phone').val()
                }
              }
      }).fail(function (error) {
        document.location = "/500"
      }).done((result) => {
        if (result.valid) {
          this.validator.isValidField('first_name') &&
            this.validator.isValidField('contact_phone') &&
            createAccount()
        } else {
          this.validator.updateMessage('contact_phone', 'notEmpty', result.error_message)
          this.validator.updateStatus('contact_phone', 'INVALID', 'notEmpty')
        }
      })
    }

    if (data.omniAuthDetails['oauth_email_exists']) {
      this.newExisingUserStore = { existingUser: true, newUser: false }
      this.validator.validateField('password')
      this.validator.isValidField('password') && passwordCheck()
    } else if (data.omniAuthDetails['account_from_omni_auth'] === true) {
      postingProgressStore.updateTracking({ UserLoggedIn: true })
      onStepProgression()
      coreUtils.googleAnalytics({ hitType: 'event', eventCategory: 'jobpost', eventAction: 'jobconvert' })
      coreUtils.appMsg({ id: data.omniAuthDetails["user_session_id"], version: '1.1' })
      $('#user-session-id').attr('title', data.omniAuthDetails["user_session_id"])
      window.App.currentUserId = data.omniAuthDetails["user_id"]
      window.App.userPhoneIsValid = true
    } else if (data.otp !== null && data.otp !== '') {
      newPasswordCheck()
    } else if (this.newExisingUserStore.existingUser) {
      $('.other-login').addHidden()
      this.validator.validateField('password')
      this.validator.isValidField('password') && passwordCheck()
    } else if (this.newExisingUserStore.newUser) {
      $('.other-login').addHidden()
      this.validator.validateField('first_name')
      this.validator.validateField('contact_phone')
      checkPhoneValidation()
    } else {
      $('.other-login').addHidden()
      this.validator.validateField('email_address')
      this.validator.isValidField('email_address') && emailCheck()
    }
  },

  // ---------------------------------------------------------------------------- component start --------------------------------------------------------------------------------------------
  component: React.createClass({
    getInitialState: function() {
      return {
        emailAddress: data.emailAddress,
        existingUser: false,
        newUser: false,
        isEmailDisabled: false
      }
    },

    componentDidMount: function() {
      JobWizardEventEmitter.addListener('job:wizard:existingUser', this.updateNewOrExistingUserState)

      if (data.omniAuthDetails['account_from_omni_auth'] === false) {
        $('#job-modal').bootstrapValidator({ /* */ })
        EmailAddress.validator = $('#job-modal').data('bootstrapValidator')
        this.setupValidation()
      }
    },

    componentWillUnmount: function() {
      JobWizardEventEmitter.removeListener('job:wizard:existingUser', this.updateNewOrExistingUserState)
    },

    setupValidation: function () {
      EmailAddress.validator.addField('email_address', {
        threshold: 9999999,
        validators: {
          notEmpty: {
            message: 'Please enter an email address'
          }
        }
      })

      EmailAddress.validator.$form.on('error.field.bv', function(e, data) {
        data.element
          .data('bv.messages')
          .find('[data-bv-validator="' + data.validator + '"]').show()
      })
    },

    updateNewOrExistingUserState: function(args) {
      this.setState(args)
    },

    handleEmailAddressChange: function(e) {
      EmailAddress.newExisingUserStore = { existingUser: false, newUser: false } //reset local object store when user changes email
      let emailAddressOriginal = e.target.value
      let emailAddressTrimmed = e.target.value.replace(/[\s]/g, '')

      if (emailAddressOriginal !== emailAddressTrimmed) {
        e.target.value = emailAddressTrimmed
      }
      
      this.setState({ emailAddress: emailAddressTrimmed })
      actions.updateEmailAddress(emailAddressTrimmed)
      actions.updateOtp(null)
      actions.updateOmniAuthDetails({})
      actions.updateOmniAuthDetails({'account_from_omni_auth': false})
    },

    render: function() {
      return (
        <div>
          <MatchedBusinessesInfo
            subcategoryId={data.category_id}
            subcategoryName={data.category}
            suburbId={data.suburb_id}
            suburbName={data.suburb}
          />
          { data.omniAuthDetails['account_from_omni_auth'] === false &&
            <div>
              <h3 className="mb15 mt10">Where would you like your quotes sent?</h3>
              <small className="info-text">
                <i className="mb15 block">
                Posted a job before? We recommend using the same email address.
                </i>
              </small>
              <div className="form-group">
                <input type="text" className="form-control mt15" id="email_address"
                  name="email_address"
                  placeholder="Enter an email address"
                  defaultValue={this.state.emailAddress}
                  onChange={this.handleEmailAddressChange}
                  disabled={this.state.isEmailDisabled}
                />
              </div>
            </div>
          }
          { data.omniAuthDetails['oauth_email_exists'] === true && <Password onRequest={this.props.onRequest} onRequestCompletion={this.props.onRequestCompletion}/> }
          { data.omniAuthDetails['account_from_omni_auth'] === false &&
            <div>
              { this.state.existingUser && <Password onRequest={this.props.onRequest} onRequestCompletion={this.props.onRequestCompletion}/> }
              { this.state.newUser && <CreateAccount /> }
            </div>
          }
        </div>
      )
    }
  })
}

export default EmailAddress
