import React from 'react'
import StepSequence from 'common/components/job_wizard/StepSequence'
import Category from 'common/components/job_wizard/steps/Category'
import Location from 'common/components/job_wizard/steps/Location'
import SubCategory from 'common/components/job_wizard/steps/SubCategory'
import Description from 'common/components/job_wizard/steps/Description'
import EmailAddress from 'common/components/job_wizard/steps/EmailAddress'
import Origin from 'common/components/job_wizard/Origin'
import data from 'common/components/job_wizard/data/data'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import actions from 'common/components/job_wizard/data/actions'
import coreUtils from 'common/src/utils/coreUtils'

const Details = {
  displayName: 'Details',

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion) {
    let details = this

    details.selectExactMatchForSubcategory(() => {
      if (this.hasSubcategory()) {
        $.ajax({
          url: '/api/sub_categories/landing_page_path.json',
          method: 'GET',
          data: {
            id: data.category_id
          },
          beforeSend: onRequest,
        })
          .always(onRequestCompletion)
          .done(function(response) {
            // App.landingPageRedirect default is `undefined`
            if (response.path === null || !App.landingPageRedirect) { // no landing page found advance to next step
              details.proceedWithChosenSubcategory(onStepProgression)
            } else {
              location.href = `${response.path}?job_suburb=${data.suburb}&keyword=${data.category.toLowerCase()}`
            }
          })
          .fail(function() {
            details.proceedWithChosenSubcategory(onStepProgression)
          })
      } else {
        onStepProgression()
      }
    })
  },

  buttonLayout() {
    return 'oneButton'
  },

  searchTerm() {
    return $('input[name="category"]').val()
  },

  hasSubcategory() {
    return data.category_id !== 0
  },

  proceedWithChosenSubcategory(onStepProgression) {
    let steps            = StepSequence.getSteps()
    let detailsStepIndex = this.detailsStepIndex(steps)
    let nextStep         = steps[detailsStepIndex + 1]

    if (nextStep === SubCategory) { // this condition avoids skipping unique question steps
      let nextStepNumber = StepSequence.getStepPositionByType(SubCategory)
      let targetStep = StepSequence.getStepByPosition(nextStepNumber + 1)

      JobWizardEventEmitter.emit('job:wizard:advanceToStep', targetStep)
    } else {
      onStepProgression()
    }
  },

  detailsStepIndex(steps) {
    return steps.indexOf(this)
  },

  selectExactMatchForSubcategory(callback) {
    $.ajax({
      url: '/api/sub_categories/lookup.json',
      dataType: 'json',
      data: { 'q': this.searchTerm(), 'prefer_keywords': '1' }
    }).done((response) => {
      if ('keyword' in response) {
        let category = { id: response.subcategory_id, value: response.subcategory_name }
        actions.updateCategory(category)
        actions.updateSubCategory(category, 'add')
        actions.updateKeyword(response.keyword)
      } else {
        for (let item of response) {
          if (this.searchTerm().toLowerCase() == item.name.toLowerCase()) {
            let category = { id: item.id, value: item.name }
            actions.updateCategory(category)
            actions.updateSubCategory(category, 'add')
          }
        }
      }

      callback()
    })
  },

  component: React.createClass({
    getInitialState: function() {
      return { showCategoryInput: true }
    },

    componentDidMount: function() {
      JobWizardEventEmitter.addListener('job:wizard:hideCategoryInput', this.updateState)
    },

    componentWillUnmount: function() {
      JobWizardEventEmitter.removeListener('job:wizard:hideCategoryInput', this.updateState)
    },

    updateState: function(data) {
      this.setState(data)
    },

    render: function() {

      return (
        <div className="form-horizontal">
          <div className={`${this.state.showCategoryInput ? '' : 'hidden'}`}>
            <div className="form-group mb24">
              <div className="col-xs-12">
                <h3 className="mb15 mt10">Who do you need?</h3>
                <Category />
              </div>
            </div>
          </div>

          <div className="form-group mb30">
            <div className="col-xs-12">
              <h3 className="mb15 mt10">Where are you located?</h3>
              <Location />
            </div>
          </div>

          <Origin />
        </div>
      )
    }
  })
}

export default Details
