import API from 'common/src/api'
import data from 'common/components/job_wizard/data/data'
import Description from 'common/components/job_wizard/steps/Description'
import Details from 'common/components/job_wizard/steps/Details'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import React from 'react'
import StepSequence from 'common/components/job_wizard/StepSequence'

const PrePostJobCheck = {
  displayName: 'PrePostJobCheck',

  onLeftNavButtonClick: function (onStepRetreat) {
    onStepRetreat(Details)
  },

  buttonLayout: function () {
    return 'exitButton'
  },

  component: class DuplicateJobWarning extends React.Component {
    constructor() {
      super()
      this.state = {
        duplicateJobId: 0,
        errorMessage: "",
        loading: true
      }
    }

    componentDidMount() {
      $('.modal-footer').addHidden()
      this.checkJob()
    }

    buildError() {
      // we want to decide which error to display
      if (this.state.dailyJobContExceeded) { return "TOO_MANY_JOB_REQUESTS" }
      if (this.state.duplicateJobExits) { return "DUPLICATE_JOB" }

      return "";
    }

    checkJob() {
      var self = this
      var job = data

      var jobDetails = API.V2.prePostJobCheck({
        suburbId: job.suburb_id,
        subCategoryId: job.category_id
      })

      $.ajax(jobDetails)
        .done(function (data) {
          self.setState({ duplicateJobId: data.meta.duplicate_job_id })
          self.setState({ duplicateJobExits: data.meta.duplicate_job_exists })
          self.setState({ dailyJobContExceeded: data.meta.daily_job_count_exceeded })
          self.setState({ dailyJobCount: data.meta.daily_job_count })
          self.setState({ errorMessage: self.buildError() })

          $('.modal-footer').rmHidden()

          if (self.state.errorMessage === "") {
            JobWizardEventEmitter.emit('job:wizard:advanceToNextStep')
          }
          else {
            self.setState({ loading: false })
          }
        })
        .fail(function () {
          $('.modal-footer').rmHidden()
          // do not block jobs, redirect on any failure
          JobWizardEventEmitter.emit('job:wizard:advanceToNextStep')
        })
    }

    duplicateJob() {
      return (
        <div>
          <h3>It looks like you already posted a job for {data.category} in {data.suburb}</h3>
          <br />
          <div><a href={`/jobs/${this.state.duplicateJobId}`}>See your existing job</a></div>
          <br />
          <p>Got a problem and need help? Check out our <a href="#{I18n.t(:'ss.help_centre.faq_url')}/hc/en-au/articles/360033908914-It-says-I-posted-a-duplicate-job-What-should-I-do-">FAQs</a></p>
        </div>
      )
    }

    overLimit() {
      return (
        <div>
          <h3>You've posted the maximum allowable jobs for today. Please try again tomorrow</h3>
          <br />
          <p>Got a problem and need help? Check out our <a href="#{I18n.t(:'ss.help_centre.faq_url')}">FAQs</a></p>
        </div>
      )
    }

    loading() {
      return (
        <div className="modal-body job-form-body radius-16-bottom">
          <h3>Processing...
                <div className="spinner-icon text-center" style={{ marginLeft: '15px' }} />
          </h3>
        </div>
      )
    }

    render() {
      return (
        <div>
          {this.state.loading && this.loading()}
          {!this.state.loading && this.state.errorMessage === "TOO_MANY_JOB_REQUESTS" && this.overLimit()}
          {!this.state.loading && this.state.errorMessage === "DUPLICATE_JOB" && this.duplicateJob()}
        </div>
      )
    }
  }
}

export default PrePostJobCheck
