const currencyUtils = {
  print: function(cost) {
      return this.asStr(cost).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  asStr: function(cost) {
      cost = parseFloat(cost, 10);
      return cost.toFixed(2).replace(/\.0[0]*$/, '');
  },

  asFloat: function(cost) {
      return parseFloat(this.asStr(cost), 10);
  }
}

export default currencyUtils
