const steps = {
  "165": { //Exterior House Painting
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Is this a residential or commercial job?",
            "jobDescriptionKey": "Residential or Commercial",
            "validation": {
              "groupName": "residential_commercial",
              "message": "Please choose type of plumbing required.",
            },
            "options": [
              {
                "value": "This is a Residential job",
                "text": "Residential",
              },
              {
                "value": "This is a Commercial job",
                "text": "Commercial",
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Should the quote include the cost of paint?",
            "jobDescriptionKey": "Includes cost of paint",
            "validation": {
              "groupName": "cost_of_paint_included",
              "message": "Please select they should include cost of paint."
            },
            "options": [
              {
                "value": "Please quote for paint AND labour",
                "text": "Please quote for paint AND labour"
              },
              {
                "value": "Please quote for labour only",
                "text": "Please quote for labour only"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
