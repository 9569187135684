import React from 'react'
import UnseenConversationsCountNotification from 'common/components/chat/UnseenConversationsCountNotification'

const MessageNavigation = (props) =>
  props.tailwind ?
    <div>
      <a className='lg:hidden -mx-3 block rounded-lg px-3 py-2 text-xs font-light leading-7 text-old-gray-tones-600 hover:bg-gray-50 uppercase' href={props.path}>
        Contacts <UnseenConversationsCountNotification unseenConversationCount={props.unseenConversationCount} />
      </a>

      <div className='hidden lg:block text-sm font-light leading-6 text-gray-tones-200 nav-link uppercase'>
        <a className={`${props.linkClass} border-b-4 border-transparent hover:border-secondary hover:font-semibold hover:text-black text-gray-tones-200`} href={props.path}>
          Contacts <UnseenConversationsCountNotification unseenConversationCount={props.unseenConversationCount} />
        </a>
      </div>
    </div>
  :
    <a className={`btn-block ${props.linkClass}`} href={props.path}>
      Contacts <UnseenConversationsCountNotification unseenConversationCount={props.unseenConversationCount} />
    </a>

export default MessageNavigation
