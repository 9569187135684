const steps = {
  "2381": { //Rubbish Removalist
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "What type of rubbish removal job is this?",
            "jobDescriptionKey": "Job type",
            "validation": {
              "groupName": "job_type",
              "message": "Please choose a type of removal.",
            },
            "options": [
              {
                "value": "Household waste",
                "text": "Household waste"
              },
              {
                "value": "Building site waste",
                "text": "Building site waste"
              },
              {
                "value": "Garden waste",
                "text": "Garden waste"
              },
              {
                "value": "Business/Commercial waste",
                "text": "Business/Commercial waste"
              },
              {
                "value": "Hazardous waste",
                "text": "Hazardous waste"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Roughly how much waste needs removing?",
            "jobDescriptionKey": "Waste quantity",
            "validation": {
              "groupName": "waste_quantity",
              "message": "Please select how much waste needs removing."
            },
            "options": [
              {
                "value": "Less than 1 skip load",
                "text": "Less than 1 skip load"
              },
              {
                "value": "Roughly 1 skip load",
                "text": "Roughly 1 skip load"
              },
              {
                "value": "Roughly 2-3 skip loads",
                "text": "Roughly 2-3 skip loads"
              },
              {
                "value": "4 or more skip loads",
                "text": "4 or more skip loads"
              },
              {
                "value": "Not Sure",
                "text": "Not Sure"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
