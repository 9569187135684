import React from 'react'
export default class MatchedBusinessesInfo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      matchedBusinessesCount: 0
    }
  }

  componentDidMount() {
    this.fetchMatchedBusinesses()
  }

  fetchMatchedBusinesses() {
    $.ajax({
      url: '/job_wizard/matched_businesses_count',
      method: 'GET',
      data: { subcategory_id: this.props.subcategoryId, suburb_id: this.props.suburbId }
    })
    .done((data) => {
      this.setState({matchedBusinessesCount: data.count})
    })
  }

  formattedSuburbName() {
    let suburb = this.props.suburbName.split(',')
    let state  = (typeof(suburb[1]) === 'undefined') ? '' : `, ${suburb[1]}`
    return `${suburb[0]}${state}`
  }

  hasEnoughMatchedBusinesses() {
    return this.state.matchedBusinessesCount > 2
  }

  render() {
    return(
      <div className={'alert alert-info mt20 ' + (this.hasEnoughMatchedBusinesses() ? '' : 'hidden')} role='alert'>
        We currently have <strong>{this.state.matchedBusinessesCount} available businesses</strong> waiting to send you quotes&nbsp;
        for your <strong>'{this.props.subcategoryName}'</strong> job in <strong>'{this.formattedSuburbName()}'</strong>
      </div>
    )
  }
}
