const steps = {
  "1064": { // Furniture Assembly
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "What product do you need assembled?",
            "jobDescriptionKey": "Products",
            "validation": {
              "groupName": "products",
              "message": "Please specify the products to be assembled."
            },
            "options": [
              {
                "value": "Book Shelves",
                "text": "Book Shelves"
              },
              {
                "value": "Wardrobe",
                "text": "Wardrobe"
              },
              {
                "value": "Table",
                "text": "Table"
              },
              {
                "value": "Chairs",
                "text": "Chairs"
              },
              {
                "value": "Bed",
                "text": "Bed"
              },
              {
                "value": "Side Drawers",
                "text": "Side Drawers"
              },
              {
                "value": "Crib",
                "text": "Crib"
              },
              {
                "value": "Other",
                "text": "Other"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
