import 'jquery-ui'

import coreUtils from 'common/src/utils/coreUtils'

const categoryAutocomplete = {
  occupationAutocomplete(selectorId, handleSelectedOccupation, resultCallback) {
    var $selector = $(selectorId);

    $selector.autocomplete({
      source: function (request, cb) {
        $.ajax({
          url: '/api/categories/lookup.json',
          method: 'get',
          data: { q: request.term },
          beforeSend: cb([{ label: "Loading Matches...", loading: true }])
        })
        .done(function(response) {
          var results = response.map(function(item) { return {id: item.id, value: item.name} });
          cb(results);
        }, function(results){
          resultCallback(results)
        })
        .fail(function(response) { document.location = '/500' })
      },
      select: handleSelectedOccupation,
      open: function(event) {
        categoryAutocomplete.resizeMenu(event);
        $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
      }
    })
  },

  taskAutocomplete(selectorId, handleSelectedTask, preferKeywords, resultCallback) {
    var $selector = $(selectorId);

    $selector.autocomplete({
      source: function (request, cb) {
        $.ajax({
          url: '/api/sub_categories/lookup.json',
          method: 'get',
          data: {q: request.term, prefer_keywords: preferKeywords},
          beforeSend: cb([{ label: "Loading Matches...", loading: true }])
        })
        .done(function(response) {
          if (response.keyword) {
            var results = [
              {
                id:          response.subcategory_id,
                value:       response.subcategory_name,
                keyword:     response.keyword
              }
            ];
          } else {
            var results = response.map(function(item) {
                            return {
                              id: item.id,
                              value: item.name,
                              category_id: item.category_id,
                              category_name: item.category_name,
                            }
                          });
          }
          cb(results);
        }, function(results){
          resultCallback && resultCallback(results)
        })
        .fail(function(response) { document.location = '/500' })
      },
      select: handleSelectedTask,
      create: function() {
        $(this).data('ui-autocomplete')._renderItem = function (ul, item) {
          var term = this.term;
          function highlightTerm(term, content) {
            return content.replace(new RegExp("(?![^&;]+;)(?!<[^<>]*)(" +
                                              $.ui.autocomplete.escapeRegex(term) +
                                              ")(?![^<>]*>)(?![^&;]+;)","gi"),
                                   "<strong>$1</strong>");
          }

          if (item.loading) {
            return $("<li class='category-loading'></li>")
                .append("<i>" + item.label + "</i>")
                .appendTo(ul);
          } else {
              if (item.keyword) {
                var name = highlightTerm(term, item.keyword);

                return $('<li class="category-suggestions"></li>')
                  .data("item.autocomplete", item)
                  .append("<span class='subcategory'>"+name+"</span>")
                  .appendTo(ul);
              } else {
                var name = highlightTerm(term, item.value);
                var categoryName = highlightTerm(term, item.category_name);

                return $('<li class="category-suggestions"></li>')
                  .data("item.autocomplete", item)
                  .append("<span class='subcategory'>"+name+"</span>,<span class='category'><strong>"+categoryName+"</strong></span>")
                  .appendTo(ul);
              }
          }
        }
      },
      open: function(event) {
        categoryAutocomplete.resizeMenu(event);
        $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
      }
    })
  },

  resizeMenu(event) {
    var $input = $(event.target);
    var width = $input.outerWidth();
    var $menu = $input.data('ui-autocomplete').menu.element;
    $menu.width(width);
  }
}

export default categoryAutocomplete
