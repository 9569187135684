import coreUtils   from 'common/src/utils/coreUtils'

$('#quickstart')
    .submit(function() {
        if ($suburbID.val().trim().length === 0) {
            $suburbID.prop('disabled', true);
        }
    })
    .keypress(coreUtils.preventEnterSubmit);

const $siteSticky = $('.site-sticky');

if ($siteSticky.length) {
    const stickyScrollCutOffPoint = ($('.section-header').offset() || Function).top - 100
    const siteStickyHeight = 150;

    const showStickyHeader = function() {
        $siteSticky.css('top', '0');
    };

    const hideStickyHeader = function() {
        $siteSticky.css('top', -siteStickyHeight);
    };

    const scrollAtStickyInsertPoint = function() {
        return $(window).scrollTop() > stickyScrollCutOffPoint;
    };

    const showHideStickyHeader = function() {
        $siteSticky.removeClass('slide-hide');

        if (scrollAtStickyInsertPoint()) {
            showStickyHeader();
        } else {
            hideStickyHeader();
        }
    };

    // initialize sticky header
    showHideStickyHeader();

    $(window).scroll(function() {
        showHideStickyHeader();
    });
}