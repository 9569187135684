import 'jquery-ui'

import coreUtils from 'common/src/utils/coreUtils'

const seoKeywordAutocomplete = {
  keywordAutocomplete(selectorId, handleSelectedKeyword, preferKeywords) {
    var $selector = $(selectorId);

    $selector.autocomplete({
      source: function (request, cb) {
        $.ajax({
          url: '/keyword',
          method: 'get',
          data: { keyword: request.term },
          headers: {
            Accept: 'application/vnd.api+json; version=2',
            'Content-Type': 'application/vnd.api+json'
            },
          beforeSend: cb([{ label: "Loading Matches...", loading: true }])
        })
        .done(function(response) {
            var results = response.data.map(function(item) { return {id: item.id, value: item.attributes.value, categoryName: item.attributes['category-name']} });
            cb(results);
        })
        .fail(function(response) { document.location = '/500' })
      },
      select: handleSelectedKeyword,
      create: function() {
        $(this).data('ui-autocomplete')._renderItem = function (ul, item) {
          var term = this.term;
          function highlightTerm(term, content) {
            return content.replace(new RegExp("(?![^&;]+;)(?!<[^<>]*)(" +
                                              $.ui.autocomplete.escapeRegex(term) +
                                              ")(?![^<>]*>)(?![^&;]+;)","gi"),
                                   "<strong>$1</strong>");
          }

          if (item.loading) {
            return $("<li class='category-loading'></li>")
                .append("<i>" + item.label + "</i>")
                .appendTo(ul);
          } else {
                var name = highlightTerm(term, item.value);
                var categoryName = highlightTerm(term, item.categoryName);

                return $('<li class="category-suggestions"></li>')
                  .data("item.autocomplete", item)
                  .append("<span class='subcategory'>"+name+"</span>,<span class='category'><strong>"+categoryName+"</strong></span>")
                  .appendTo(ul);
          }
        }
      },
      open: function(event) {
        seoKeywordAutocomplete.resizeMenu(event);
        $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
      }
    })
  },

  resizeMenu(event) {
    var $input = $(event.target);
    var width = $input.outerWidth();
    var $menu = $input.data('ui-autocomplete').menu.element;
    $menu.width(width);
  }
}

export default seoKeywordAutocomplete
