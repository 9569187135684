import 'common/src/nav/mobile'
import 'common/components/job_wizard/Wizard'
import 'common/src/nav/stickyHeader'
import 'common/components/chat/MessageNavigation'

import jQueryExtensions from 'common/src/jqueryExtensions'
import PricingSurvey from 'common/components/business/PricingSurvey'

import React from 'react'
import ReactDOM from 'react-dom'

document.addEventListener('DOMContentLoaded', () => {
  const pricingSurvey = document.getElementById('pricing-survey-container')

  if (pricingSurvey !== null) {
    const pricingSurveyData = JSON.parse(pricingSurvey.getAttribute('data').replace(/&quot;/g,'"'))
    ReactDOM.render(<PricingSurvey {...pricingSurveyData}/>, pricingSurvey)
  }
})
