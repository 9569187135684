import React from 'react'
import API from 'common/src/api'

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = this.initialState()
  }

  initialState() {
    return { emailAddress: '',
             alertMessage: '',
             messageClass: '',
             phoneNumber: null,
             loadingEmail: false,
             loadingSms: false,
             validMobileNumber: false }
  }

  componentDidMount = () => {
    $('#reset-password-modal').on('hide.bs.modal', () => {
      this.setState(this.initialState())
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ emailAddress: nextProps.emailAddress,
                    phoneNumber: nextProps.phoneNumber,
                    validMobileNumber: nextProps.validMobileNumber })
  }

  handleSendEmail = () => {
    var forgotObj = API.V2.forgotPassword({
      email: this.state.emailAddress
    })
    forgotObj.beforeSend = () => {
      this.setState({ loadingEmail: true })
    }
    $.ajax(forgotObj)
      .done(data => {
        this.setState({ alertMessage: 'Please check your email, we have sent you instructions on how to reset your password.',
                        messageClass: 'alert-success',
                        loadingEmail: false
                      })
      })
  }

  handleSendSms = () => {
    $.ajax({
      beforeSend: () => {
        this.setState({ loadingSms: true })
      },
      url: '/api/users/send_reset_password_sms',
      dataType: 'json',
      data: { email: this.state.emailAddress }
    }).done((data) => {
      this.setState({ alertMessage: data.alertMessage,
                      messageClass: data.messageClass,
                      loadingSms: false
                    })
    })
  }

  renderSendEmailOption() {
    return (
      <button
        className="btn btn-block btn-secondary-alt2 btn-lg btn-clear"
        onClick={this.handleSendEmail}>{this.state.loadingEmail ? <span className="spinner-icon "/> : 'Send email'}
      </button>
    )
  }

  renderSendSmsOption() {
    if (this.state.phoneNumber !== null && this.state.validMobileNumber) {
      return (
        <div>
          <p className="mt10">or</p>
          <p className="mb10 mt10">Send an SMS to {this.state.phoneNumber}</p>
          <button
            className="btn btn-block btn-secondary-alt2 btn-lg btn-clear"
            onClick={this.handleSendSms}>{this.state.loadingSms ? <span className="spinner-icon "/> : 'Send SMS'}
          </button>
        </div>
      )
    }
  }

  renderAlertMessage() {
    if (this.state.alertMessage !== '') {
      return (
        <div className={`alert ${this.state.messageClass}`}>{this.state.alertMessage}</div>
      )
    }
  }

  render() {
    return (
      <div className="modal fade" id="reset-password-modal">
        <div className="modal-dialog">
          <div className="modal-content modal-component no-border">
            <div className="modal-header ss-alert blue card-pad-md sm-pl32 sm-pr32 no-border">
              <div className="font-16 modal-title bold pt8 text-center">
                Reset Your Password
              </div>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p20 text-center">
              <div className="form-group">
                {this.renderAlertMessage()}
                <div>
                  {this.renderSendEmailOption()}
                  {this.renderSendSmsOption()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ResetPassword
