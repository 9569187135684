import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'

// steps
import Budget from 'common/components/job_wizard/steps/Budget'
import CategoryBasedSteps from 'common/components/CategoryBasedSteps'
import Contact from 'common/components/job_wizard/steps/Contact'
import ContactPreference from 'common/components/job_wizard/steps/ContactPreference'
import Description from 'common/components/job_wizard/steps/Description'
import Details from 'common/components/job_wizard/steps/Details'
import PrePostJobCheck from 'common/components/job_wizard/steps/PrePostJobCheck'
import EmailAddress from 'common/components/job_wizard/steps/EmailAddress'
import JobUpgrades from 'common/components/job_wizard/steps/JobUpgrades'
import MoreDetails from 'common/components/job_wizard/MoreDetails'
import PostJob from 'common/components/job_wizard/steps/PostJob'
import Schedule from 'common/components/job_wizard/steps/Schedule'
import SiteVisit from 'common/components/job_wizard/steps/SiteVisit'
import SubCategory from 'common/components/job_wizard/steps/SubCategory'
import UpdateJob from 'common/components/job_wizard/steps/UpdateJob'
import VerifyNumber from 'common/components/job_wizard/steps/VerifyNumber'
import coreUtils from 'common/src/utils/coreUtils'
import data from 'common/components/job_wizard/data/data'

const StepSequence = {
  hasCustomQuestions: false,
  steps: [],

  defaultSequence() {

    this.steps = [
      Details,
      SubCategory,
      Description,
      Schedule,
      ...loginSteps(),
      PrePostJobCheck,
      ...displayContactStep(),
      VerifyNumber,
      PostJob,
      ContactPreference,
      MoreDetails,
      Budget,
      SiteVisit,
      UpdateJob,
      JobUpgrades
    ]

    function loginSteps() {
      return coreUtils.userLoggedIn() ? [] : [ EmailAddress ]
    }

    function displayContactStep() {
      return (coreUtils.userLoggedIn() && coreUtils.userHasValidPhone()) ? [] : [ Contact ]
    }

    return this.steps
  },

  stepsWithProgress() {

    this.progressSteps = [
      Details.displayName,
      Description.displayName,
      Schedule.displayName,
      ...loginSteps(),
      ...displayContactStep(),
      MoreDetails.displayName
    ]


    function loginSteps() {
      return coreUtils.userLoggedIn() ? [] : [ EmailAddress.displayName ]
    }

    function displayContactStep() {
      return (coreUtils.userLoggedIn() && coreUtils.userHasValidPhone()) ? [] : [ Contact.displayName ]
    }

    return this.progressSteps
  },

  insertSteps(steps, position) {
    let currentSteps = this.steps

    this.steps = [...currentSteps.slice(0, position), ...steps,  ...currentSteps.slice(position)]
    this.hasCustomQuestions = true
  },

  getStepPositionByType(stepType) {
    return this.steps.indexOf(stepType)
  },

  getStepByPosition(step) {
    return this.steps[step] 
  },

  removeStep(position) {
    let currentSteps = this.steps
    this.steps = [...currentSteps.slice(0, position), ...currentSteps.slice(position + 1)]
  },

  removeStepByType(stepType) {
    let position = this.steps.indexOf(stepType)

    if (position === -1) { return }

    this.removeStep(position)
  },

  insertStepsForCategoryID(categoryID) {
    if(this.steps.length !== this.defaultSequence().length) {
      //reset step sequence this handles back button and change of category
      this.steps = this.defaultSequence()
      data.extraDetails = []
    }

    if(categoryHasExtraQuestions()) {
      let steps = this.steps
      let moreStep = steps.indexOf(MoreDetails)
      let subcategoryStep = steps.indexOf(SubCategory)
      this.removeStep(subcategoryStep) // remove subcategory slide if custom category questions are inserted
      this.insertSteps(CategoryBasedSteps.generate(categoryID), moreStep)
    }

    function categoryHasExtraQuestions() {
      // return false
      return data.subcategoryQuestions[categoryID] //see react_data/jobs/subcategory_id_questions folder for questions files
    }

  },

  init() {
    JobWizardEventEmitter.removeListener('job:wizard:categorySelected', (event, selected) => {this.insertStepsForCategoryID(selected.item.id)})
    JobWizardEventEmitter.addListener('job:wizard:categorySelected', (event, selected) => {this.insertStepsForCategoryID(selected.item.id)})

    this.defaultSequence();
    //this.steps = this.defaultSequence()
    this.hasCustomQuestions = false
  },

  getSteps() {
    return this.steps;
  }
}

export default StepSequence
