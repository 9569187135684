import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'

import Header from 'common/components/job_wizard/Header'
import JobUpgradeCard from 'common/components/job_wizard/JobUpgradeCard'
import CreditCardContainer from 'common/components/credit_card/CreditCardContainer'

import utils from 'common/components/job_wizard/utils'

const JobUpgrades = {

    component: React.createClass({

        getInitialState: function () {
            return {
              total: preSelectedJobUpgradeTotal(),
              withUpgrades: utils.Products.hasSelectedJobUpgrades()
            }

            function preSelectedJobUpgradeTotal() {
              let total = 0
              total += data.products.urgent ? window.App.products.urgentListing.amount : 0
              return parseFloat(total.toFixed(2))
            }
        },

        componentDidMount: function() {
          document.getElementsByClassName('modal')[0].scrollTop = 0 //force window to scroll to top - needed because previous step is long and scroll is moved to view bottom
        },

        onUpgradeCardClick: function(e) {
            let checkBox = e.currentTarget
            let withUpgrade = checkBox.checked
            let price = parseFloat(checkBox.dataset.price)
            let newPrice = withUpgrade ? price : -price

            updateJobProductsStore()

            this.setState({
                total: buildAmount(this.state.total, newPrice),
                withUpgrades: products.hasSelectedJobUpgrades()
            })

            function buildAmount(runningAmount, jobUpgradeAmount) {
                return parseFloat((runningAmount + jobUpgradeAmount).toFixed(2))
            }

            function updateJobProductsStore() {
              if (checkBox.value === 'urgent_listing') {
                actions.urgentProductAdd(checkBox.checked ? true : null)
              }
            }
        },

        retreatWizardToPrevStep: function() {
          this.props.onStepRetreat()
        },

        handleNoThanks: function() {
          document.location.href = this.redirectUrl()
        },

        redirectUrl: function() {
          let jobID = data.id


          switch (data.referrer.cta) {
            case 'profile': {
              return `/jobs/${jobID}/profilejob?success_job=true`
            }
            case 'business_directory_card': {
              return `/jobs/${jobID}/bcardjob?success_job=true`
            }
            default: {
              return `/jobs/${jobID}/posted?success_job=true`
            }
          }
        },

        handleClick: function(e) {
          let $form = $('#upgrade-job-cc-form')
          let formData = $form.serializeArray()
          appendJobUpgradesToData(formData)

          $form.find(':submit:visible')
            .attr('disabled', 'disabled')
            .html("<div class='spinner-icon white-spinner'></div>")

          $.ajax({
            url: `/products/jobs/${data.id}/upgrade`,
            method: 'POST',
            data: formData
          })
          .done(() => {
            document.location.href = this.redirectUrl()
          })
          .fail(function() {
            document.location.href = '/500'
          })

          e.preventDefault()

          function appendJobUpgradesToData(data) {
            let upgradeElements = getSelectedUpgradeElements()
            upgradeElements.forEach(function(el) {
              data.push(formatInputDataForSubmission(el))
            })
          }

          function getSelectedUpgradeElements() {
            let jobUpgradeCheckBoxIds = ['urgent-listing']
            let selectedElements = []
            for (let id of jobUpgradeCheckBoxIds) {
              let el = document.getElementById(id)
              if (el && el.checked) {
                selectedElements.push(el)
              }
            }
            return selectedElements
          }

          function formatInputDataForSubmission(input) {
            return {name: input.name, value: input.value}
          }
        },

        submitButton: function() {
          return (
            <div className="row">
              <div className="col-sm-6 col-xs-12 mb10">
                <a href="#" className="btn btn-block btn-primary" onClick={this.retreatWizardToPrevStep}>
                  <i className="ficon-arrow-left-shaft pr10 fs14"/>
                  Back
                </a>
              </div>
              <div className="col-sm-6 col-xs-12 mb10">
                <button
                  type="submit"
                  name="commit"
                  className="btn btn-main btn-block"
                  id="js-upgrade-submit-form"
                  data-disable-with="Processing"
                  onClick={this.handleClick}
                >
                  Continue
                  <i className='ficon-arrow-right-shaft pl10 fs14'/>
                </button>
              </div>
            </div>
          )
        },
        jobPageButton: function() {
          return (
            <div className="row p30">
              <div className="col-sm-6 col-xs-12 mb10">
                <a href="#" className="btn btn-block btn-primary" onClick={this.retreatWizardToPrevStep}>
                  <i className="ficon-arrow-left-shaft pr10 fs14"/>
                  Back
                </a>
              </div>
              <div className="col-sm-6 col-xs-12 mb10">
                <a href={this.redirectUrl()} className="btn btn-block btn-main">
                  Continue
                  <i className='ficon-arrow-right-shaft pl10 fs14'/>
                </a>
              </div>
            </div>
          )
        },

        hiddenFields: function() {
          return (
            <div className="hidden-fields">
              <input type="hidden" name="total_cost" id="total-cost" value={`${this.state.total}`}/>
              <input type="hidden" name="purchase_params[purchasing_plan_type]" id="purchase_params[purchasing_plan_type]" value="one_off"/>
              <input type="hidden" name="purchase_params[free_upgrade]" id="purchase_params[purchasing_plan_type]"/>
              <input type="hidden" name="purchase_params[purchase_method][type]" id="purchase_params[purchase_method][type]" value="cc"/>
            </div>
          )
        },

        render: function() {
            return (
              <div>
                <Header title={{ text: 'Job Upgrades - Checkout',  icon: 'ficon-star' }} />

                <div id="job-upgrades">
                  <div className="ju-container bg-white-smoke p30">
                    <div className="row pb20">
                      <JobUpgradeCard
                        upgradeName='URGENT'
                        id='urgent-listing'
                        price={window.App.products.urgentListing.amount}
                        description='We individually SMS your job to the top matched businesses alerting them that you want it quoted on or completed within 24 hours'
                        type='urgent'
                        onUpgradeCardClick={this.onUpgradeCardClick}
                        checked={data.products.urgent}
                        value='urgent_listing'
                      />
                    </div>
                  </div>
                </div>

                <div className={`row p30 ${this.state.withUpgrades ? '' : 'hidden'}`}>
                  <div className="col-xs-12">
                    <div className="row">
                      <div className="col-xs-6">
                        <div className="font-16 bold mt32 mb16">Total</div>
                      </div>
                      <div className="col-xs-6">
                        <div className="font-16 bold mt32 mb16 pull-right">${this.state.total.toFixed(2)}</div>
                      </div>
                    </div>

                  <CreditCardContainer
                      submitButton={this.submitButton()}
                      formId="upgrade-job-cc-form"
                      withExistingCard={data.with_cc}
                      ccNumber={data.cc_number}
                      hiddenFields={this.hiddenFields()}
                      disclaimer="By pressing 'Continue' you agree to our Terms & Conditions and acknowledge that your credit card will be debited for the Upgrades selected above"
                      action={`/products/jobs/${data.id}/upgrade`}
                  />
                </div>
              </div>
              {this.state.withUpgrades ? [] : this.jobPageButton()}
            </div>
          )
        }
    })
}

export default JobUpgrades
