import React from 'react'
import coreUtils from 'common/src/utils/coreUtils'
import data, { jobData } from 'common/components/job_wizard/data/data'
import MobileChannelHandler from 'job_wizard_mobile/components/MobileChannelHandler'

const Header = React.createClass({
  componentDidMount: function() {
    let $jobModal = $('#job-modal');
    $jobModal.find('.modal-header .close').on('click', function() {
      let $warning = $jobModal.find('.close-modal-warning');

      if(data.lastSlide) {
        redirectPage();
        return;
      }

      if ($warning.is(':hidden')) {
        if (coreUtils.mobilecheck() === true){
          $warning.addClass("close-modal-mobile-class");
        }
        $warning.velocity('slideDown');
        $(this).addHidden();
      }
    })

    function redirectPage() {
      let jobID = data.id
      let url = ''
      let message = JSON.stringify({message_type: 'jobs_wizard', message: 'JOB_POSTING_COMPLETED', payload: jobID})

      if (MobileChannelHandler.sendChannelMessage(message) == true) {
        return;
      }

      switch (data.referrer.cta) {
        case 'profile': {
          url = `/jobs/${jobID}/profilejob?successful_job=true`
        }
        case 'business_directory_card': {
          url = `/jobs/${jobID}/bcardjob?successful_job=true`
        }
        default: {
          url = `/jobs/${jobID}/posted?successful_job=true`
        }
      }
      document.location.href = url 
    }
  },
  render: function() {
    return (
      <div className="modal-header question-header no-border-bottom mr32 ml32 p0 pb16">
        <button type="button" className="close hidden-xs" aria-label="Close"><span aria-hidden="true" style={{top: '-20px', right:'0px', position:'absolute'}} aria-hidden="true">&times;</span></button>

        { MobileChannelHandler.hasMobileChannel() ? '' :
          <button type="button" className="close visible-xs" aria-label="Close" style={{ top: '-18px', right: '-5px', position: 'absolute' }}>
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
    )
  }
})

export default Header
