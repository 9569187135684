import React from 'react'

import actions from 'common/components/job_wizard/data/actions'

import FileUpload from 'common/components/FileUpload'

const ACCEPTED_FILE_TYPES = "image/png, image/jpeg, application/pdf"

const JobFileUploadContainer = React.createClass({
  getInitialState: function() {
    return {
      fileNames: [],
      fileErrors: ""
    }
  },

  handleFiles: function(e) {
    var result = processFiles()

    this.setState({fileErrors: ""})

    this.setState({
      fileNames: this.state.fileNames.concat(result['fileNames'])
    })

    if (result['rejectedFiles']){
      this.setState({fileErrors: "One or more files you selected are unsupported. Please select .pdf, .jpg, .jpeg, or .png image files."})
    }

    function processFiles() {
      let result = {}
      let fileNames = []

      // DOESN'T work in ios // for(let file of e.target.files) {
      // DOESN'T work in ios //   actions.uploadFiles(file)
      // DOESN'T work in ios //   fileNames.push(file.name)
      // DOESN'T work in ios // }
      // https://developer.mozilla.org/en/docs/Web/API/FileList

      var files = e.target.files;
      var fl = files.length;
      var file;

      for (var i = 0; i < fl; i++) {
          file = files[i];
          if (FileTypeIsSupported(file)) {
            actions.uploadFiles(file)
            fileNames.push(file.name)
          } else {
            result['rejectedFiles'] = true
          }
      }

      result['fileNames'] = fileNames
      return result
    }
  },

  renderImageFileNames: function() {
    return (
      this.state.fileNames.map(function (name) {
        return (
          <span key={name}>
            <ImageNamePreview fileName={name} />
          </span>
        )
      })
    )
  },

  render: function() {
    return(
      <div>
        <small>
          <i style={{color: 'black'}}>
            Feel free to upload some photos to help further explain your job (Optional)
          </i>
        </small>
        <div className='mt10'>
          <FileUpload handleFiles={this.handleFiles} 
            id={'job-description-fileupload'}
            accept={ACCEPTED_FILE_TYPES}
          />
        </div>
        <div className='text-red'>
          { this.state.fileErrors }
        </div>
        <div className='mt5' style={{'overflowX': 'hidden', 'overflowY': 'scroll'}}>
          { this.renderImageFileNames() }
        </div>
      </div>
    )
  }
})

let ImageNamePreview = function(props) {
  return (
    <p>{props.fileName}</p>
  )
}

let FileTypeIsSupported = function(file) {
  var validExtensions  = ['jpg', 'jpeg', 'png', 'pdf']
  var fileExtension = file.name.split('.').pop().toLowerCase()
  
  return validExtensions.indexOf(fileExtension) > -1
}

export default JobFileUploadContainer