const steps = {
	"2371": { //Electrician
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
						"label": "Is this a residential or commercial job?",
						"jobDescriptionKey": "Residential or Commercial",
            "validation": {
              "groupName": "residential_or_commercial",
              "message": "Please choose whether residential or commercial",
            },
            "options": [
              {
                "value": "This is a residential job",
                "text": "This is a residential job",
              },
              {
                "value": "This is a commercial job",
                "text": "This is a commercial job",
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
						"label": "What kind of electrical service do you require?(Tick all that apply)",
						"jobDescriptionKey": "Electrical service",
            "validation": {
              "groupName": "electrical_service",
              "message": "Tick all that apply."
            },
            "options": [
              {
                "value": "Re-wiring",
                "text": "Re-wiring"
              },
              {
                "value": "Appliance Installation",
                "text": "Appliance Installation"
              },
							{
                "value": "Hot Water System",
                "text": "Hot Water System"
              },
							{
                "value": "Repairs/Installation",
                "text": "Repairs/Installation"
              },
							{
                "value": "Powerpoint Repairs/Installation",
                "text": "Powerpoint Repairs/Installation"
              },
							{
                "value": "Lighting Repairs/Installtion",
                "text": "Lighting Repairs/Installtion"
              },
							{
                "value": "Smoke Alarm Installation",
                "text": "Smoke Alarm Installation"
              },
							{
                "value": "Phone/Internet Cabling",
                "text": "Phone/Internet Cabling"
              },
							{
								"value": "Home Entertainment Setup",
                "text": "Home Entertainment Setup"
              },
							{
                "value": "Fault Inspection",
                "text": "Fault Inspection"
              },
							{
                "value": "Home Theatre Installtion",
                "text": "Home Theatre Installtion"
              },
              {
                "value": "Other (please specify in the description)",
                "text": "Other (please specify in the description)"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
