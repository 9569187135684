const steps = {
  "526": { // Tree Lopping
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Is this a residential or commercial job?",
            "jobDescriptionKey": "Residential or Commercial",
            "validation": {
              "groupName": "residential_commercial",
              "message": "Please specify if the job is residential or commercial.",
            },
            "options": [
              {
                "value": "This is a Residential job",
                "text": "Residential",
              },
              {
                "value": "This is a Commercial job",
                "text": "Commercial",
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "What specific tree service do you need?",
            "jobDescriptionKey": "Services",
            "validation": {
              "groupName": "services",
              "message": "Please specify the specific tree service needed."
            },
            "options": [
              {
                "value": "Tree Trimming",
                "text": "Tree Trimming"
              },
              {
                "value": "Stump Grinding",
                "text": "Stump Grinding"
              },
              {
                "value": "Complete Removal",
                "text": "Complete Removal"
              },
              {
                "value": "Land Clearing",
                "text": "Land Clearing"
              },
              {
                "value": "Reshaping",
                "text": "Reshaping"
              },
              {
                "value": "Other",
                "text": "Other"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "How many trees need maintenance?",
            "jobDescriptionKey": "How many trees",
            "validation": {
              "groupName": "number_of_trees",
              "message": "Please specify how many trees need maintenance.",
            },
            "options": [
              {
                "value": "1",
                "text": "1",
              },
              {
                "value": "2",
                "text": "2",
              },
              {
                "value": "3",
                "text": "3",
              },
              {
                "value": "4",
                "text": "4",
              },
              {
                "value": "5+",
                "text": "5+",
              },
              {
                "value": "Other",
                "text": "Other",
              }
            ]
          }
        ]
      },
    ]
  }
}

export default steps
