import React from 'react'

const FileUpload =  function(props) {
  let acceptedTypes = "image/png, image/jpeg, text/plain, application/pdf"

  if (props.accept) {
    acceptedTypes = props.accept
  }

  return (
    <div>
      <label className="ficon-camera icon-button m0" type="button" htmlFor={props.id}/>
      <input type="file" className="hidden" id={props.id} multiple onChange={props.handleFiles} />
    </div>
  )
}

export default FileUpload