import chatDispatcher from 'common/components/chat/data/dispatcher'
import conversationStore from 'common/components/chat/data/conversation_store/consversationStore'
import assign from 'object-assign'
import { EventEmitter } from 'events'
import coreUtils from 'common/src/utils/coreUtils'

const CHANGE_EVENT = 'change'
let messages = []
let currentOpenConversationID = null
let loading = true

const messageStore = assign({}, EventEmitter.prototype, {
  getAll: function () {
    return messages
  },

  getLoading: function () {
    return loading
  },

  getAllForConversation: function (conversationsID, messageUrl) {
    $.ajax({
      url: messageUrl,
      data: {
        'page[size]': 50,
        include: 'job,job.suburb'
      },
      method: 'GET',
      headers: { 'Accept': 'application/vnd.api+json; version=2' },
    }).done((response) => {
      console.log(response)
      chatDispatcher.dispatch({ type: 'MESSAGES_FETCH_SUCCESS', data: response })
    })
  },

  getAllForCurrentConversation: function () {
    let currentConversation = conversationStore.getCurrent()
    let messageUrl = currentConversation.relationships.thread.links.related
    return this.getAllForConversation(currentConversation.id, messageUrl)
  },

  emitChange: function () {
    console.log('emit change from messages')
    this.emit(CHANGE_EVENT)
  },

  addChangeListener: function (callback) {
    this.on(CHANGE_EVENT, callback)
  },

  removeChangeListener: function (callback) {
    this.removeListener(CHANGE_EVENT, callback)
  }
})

function createMessage({ data, contentType = 'application/vnd.api+json', optimisticMessage }) {
  appendMessage(optimisticMessage)

  $.ajax({
    url: window.App.url + '/messages',
    data: data,
    method: 'POST',
    headers: { 'Accept': 'application/vnd.api+json; version=2' },
    processData: false,
    contentType: contentType
  }).done((response) => {
    console.log(response.data)
    chatDispatcher.dispatch({
      type: 'MESSAGE_POST_SUCCESS',
      data: response.data,
      optimisticMessageID: optimisticMessage.id
    })
  }).fail((response) => {
    let errorMessageText

    if (response.status === 500) {
      errorMessageText = 'There was an error posting your message.'
    } else {
      errorMessageText = `${response.responseJSON.errors[0].title}. ${response.responseJSON.errors[0].detail}`
    }

    chatDispatcher.dispatch({
      type: 'MESSAGE_POST_FAIL',
      response: response,
      optimisticMessageID: optimisticMessage.id,
      errorMessageText: errorMessageText
    })
  })
}

function dataBuilder(messageBody) {
  let currentConversation = conversationStore.getCurrent()
  let jobData = currentConversation.relationships.job.data
  let pageContextType = conversationStore.getPageContextType()
  let senderRelationshipsData = pageContextType === 'user' ? currentConversation.relationships.user.data : currentConversation.relationships.business.data
  let recipientRelationshipsData = pageContextType === 'user' ? currentConversation.relationships.business.data : currentConversation.relationships.user.data

  return (
    JSON.stringify(
      {
        "data": {
          "type": "messages",
          "attributes": {
            "body": messageBody
          },
          "relationships": {
            "job": {
              "data": jobData
            },
            "sender": {
              "data": senderRelationshipsData
            },
            "recipient": {
              "data": recipientRelationshipsData
            }
          }
        },
        "included": [
          {
            "type": "message-source",
            "attributes": {
              "source": mobilecheck() ? 'mobile' : 'desktop',
              "user-agent": coreUtils.userAgent()
            }
          }
        ]
      }
    )
  )
}

function markAllAsRead() {
  let unreadRecipientMessages = messages.filter((message) => message.attributes.author === false && message.attributes.read === false)[0]
  console.log('unread recipient message:', unreadRecipientMessages)
  if (unreadRecipientMessages) {
    let url = unreadRecipientMessages.links.self + '/read'
    $.ajax({
      url: url,
      method: 'PATCH',
      headers: { 'Accept': 'application/vnd.api+json; version=2', 'Content-Type': 'application/vnd.api+json' }
    }).done((response) => {
      console.log(response)
      chatDispatcher.dispatch({ type: 'MARK_ALL_AS_READ_SUCCESS', data: response })
    })
  }
}

function markAsRead(message) {
  let url = message.links.self + '/read'
  $.ajax({
    url: url,
    method: 'PATCH',
    headers: { 'Accept': 'application/vnd.api+json; version=2', 'Content-Type': 'application/vnd.api+json' }
  }).done((response) => {
    console.log('done markAsRead: ', response)
    chatDispatcher.dispatch({ type: 'MARK_AS_READ_SUCCESS', data: response })
  })
}


function appendMessage(message) {
  messages.push(message)
}

function uniqueID() {
  return `FAKE_${Math.round(Math.random() * 10000)}_ID`
}

function buildOptimisticMessage({ messageBody = '', error = false, spinner = false }) {
  return { id: uniqueID(), attributes: { body: messageBody, author: true, error: error, loading: spinner, file: [] } }
}

function replaceMessage({ id, replacementMessage }) {
  messages.map(function (message, index) {
    if (message.id === id) {
      messages[index] = replacementMessage
    }
  })
}

function removeFirstMessageShownInBusinessCard(payload) {
  if (beginningOfMessages()) { // if there are no next links there are no more messages
    messages.shift()
  }

  function beginningOfMessages() {
    return !payload.data.links.next
  }
}

function addIncludedToMessages(included) {
  messages.included = {
    job: included.filter(include => include.type === 'jobs')[0],
    suburb: included.filter(include => include.type === 'suburbs')[0]
  }
}

// Register dispatcher callback (single place where all events that "modify" your Stores are handled) ---------------------------------------------------------------------------
messageStore.dispatchToken = chatDispatcher.register(function (payload) {
  chatDispatcher.waitFor([conversationStore.dispatchToken])
  switch (payload.type) {
    case 'CONVERSATIONS_FETCH_SUCCESS': // triggered from ConversationStore
      if (conversationStore.getOpenConversationID() !== currentOpenConversationID) {
        messageStore.getAllForCurrentConversation()
      }
      break

    case 'FETCH_MESSAGES':
      loading = true
      messageStore.getAllForCurrentConversation()
      messageStore.emitChange()
      break

    case 'UPDATE_OPEN_CONVERSATION_ID': // triggered from ConversationStore
      loading = true
      messageStore.getAllForCurrentConversation() //new conversation has been clicked and current conv updated so get messages
      messageStore.emitChange()
      break

    case 'MESSAGES_FETCH_SUCCESS':
      loading = false
      messages = payload.data.data.reverse() // newest to last
      markAllAsRead()
      removeFirstMessageShownInBusinessCard(payload)
      addIncludedToMessages(payload.data.included)
      currentOpenConversationID = conversationStore.getOpenConversationID()
      messageStore.emitChange()
      break

    case 'CREATE_MESSAGE':
      let messageBody = payload.data
      let optimisticMessageWithText = buildOptimisticMessage({ messageBody: messageBody })
      createMessage({
        data: dataBuilder(messageBody),
        optimisticMessage: optimisticMessageWithText
      })
      messageStore.emitChange()
      break

    case 'CREATE_FILE_MESSAGE':
      let files = payload.data
      let formData = new FormData()
      let optimisticMessageWithSpinner = buildOptimisticMessage({ spinner: true })

      for (var i = 0; i < files.length; i++) {
        formData.append('file', files[i])
        let fileName = payload.data[i].name
        let apiData = dataBuilder(`Attachment: ${fileName}`)
        formData.append('json_data', apiData)
        createMessage({
          data: formData,
          contentType: false,
          optimisticMessage: optimisticMessageWithSpinner
        })
      }
      messageStore.emitChange()
      break

    case 'MESSAGE_POST_SUCCESS':
      let newMessage = payload.data
      replaceMessage({ id: payload.optimisticMessageID, replacementMessage: newMessage }) // replace optimistic text message
      messageStore.emitChange()
      break

    case 'MESSAGE_POST_FAIL':
      let errorMessage = buildOptimisticMessage({ messageBody: payload.errorMessageText, error: true })
      replaceMessage({ id: payload.optimisticMessageID, replacementMessage: errorMessage }) // replace optimistic spinner message
      messageStore.emitChange()
      break

    case 'NEW_MESSAGE_RECEIVED':
      let message = payload.data.data
      appendMessage(message)
      markAsRead(message)
      messageStore.emitChange()
      break

    default:
      return true;
  }

  console.log('MessageStore: ', payload.type)
  // If action was acted upon, emit change event
  // messageStore.emitChange()
  return true
})

export default messageStore
