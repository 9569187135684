const steps = {
  "2717": { //Home Renovations
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "What type of tradesman do you need?",
            "jobDescriptionKey": "Type of tradesman",
            "validation": {
              "groupName": "type_of_tradesman",
              "message": "Please choose the type of tradesman.",
            },
            "options": [
              {
                "value": "Builder",
                "text": "Builder",
              },
              {
                "value": "Carpenter",
                "text": "Carpenter",
              },
              {
                "value": "Electrician",
                "text": "Electrician",
              },
              {
                "value": "Bricklayer",
                "text": "Bricklayer",
              },
              {
                "value": "Plumber",
                "text": "Plumber",
              },
              {
                "value": "Tiler",
                "text": "Tiler",
              },
              {
                "value": "Plasterer",
                "text": "Plasterer",
              },
              {
                "value": "Handyman",
                "text": "Handyman",
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
