!function ($) {
    'use strict';
    function radioButtonGroup($buttonGroupContainer, options){
        var $radioButtonRows = $buttonGroupContainer.find('.radio');

        function bindEvents(){
            $buttonGroupContainer.on('click', ".radio", $buttonGroupContainer, setRadioButtonToCheck);
            $buttonGroupContainer.on('blur', "input[type='text']", $buttonGroupContainer, enableAllRadioButtons);
        }

        function setRadioButtonToCheck(e) {
            var $radioButtonRow = $(e.currentTarget);
            var $groupContainer = $(e.data);
            var $parentForm = $groupContainer.parents('form');
            var $radioButton = $radioButtonRow.find("input[type='radio']");
            var activeClass = activeRowClass();
            $radioButton.prop('checked', true);
            $radioButtonRows.removeClass(activeClass).find('.circle').addClass('hidden');
            $radioButtonRow.addClass(activeClass).find('.circle').removeClass('hidden');

            if (isParentFormWithValidation() && isTickedRadioButtonValidateable()){
                forceValidateField();
                if (!isRadioButtonOthers()){
                    document.dispatchEvent(new Event('crb:radio:button:click', {}));
                } else {
                    disableNonOtherRadioButtons();
                }
            }

            function disableNonOtherRadioButtons() {
                $groupContainer.find('.radio').prop('disabled', true);
            }

            function isParentFormWithValidation(){
                if ($parentForm){
                    return !!$parentForm.data('bootstrapValidator');
                } else {
                    return false;
                }
            }

            function isTickedRadioButtonValidateable(){
                return !!$radioButton.data('bv.messages');
            }

            function forceValidateField(){
                var fieldName = $radioButton.prop('name');
                $parentForm.data('bootstrapValidator').revalidateField(fieldName);
            }

            function isRadioButtonOthers(){
                return $radioButton.val().toLowerCase() === "other";
            }

        }

        function enableAllRadioButtons(e) {
            var $otherInput = $(e.currentTarget);
            var $value = $otherInput.val();
            if ($value == "") {
                $buttonGroupContainer.find('.radio').prop('disabled', false);
            }
        }

        function activeRowClass(){
            var activeClass = options.activeRowClass;
            return activeClass ? activeClass : '';
        }

        function getCheckedRadioInput(){
            return $buttonGroupContainer.find("input[type='radio']:checked");
        }

        function getCheckedRadioInputValue(){
            var $checkedRadioInput = getCheckedRadioInput();
            var checkedRadioInputValue =  $checkedRadioInput ? $checkedRadioInput.val() : '';

            if (checkedRadioInputValue.toLowerCase() === 'other'){
                var $checkedRadioInputRow = $buttonGroupContainer.find("input[type='radio']:checked").next();
                checkedRadioInputValue = $checkedRadioInputRow.find("input[type='text']").val();
            }

            return checkedRadioInputValue;
        }

        return {
            init: function(){
                bindEvents();
            },
            currentCheckedValue: function(){
                return getCheckedRadioInputValue();
            }
        }
    }

    function plugin(){
        var $this = $(this);
        var data = $this.data('radioButtonGroup');
        var options = arguments[0] || {};

        if (!data){
            data = radioButtonGroup(this, options);
            data.init();
            $this.data('radioButtonGroup', data);
        }
        return data;
    }

    $.fn.radioButtonGroup = plugin;

}(window.jQuery);
