import React from 'react'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
// import Select from 'react-select'
import CategoryAutocomplete from 'common/src/autocomplete/categoryAutocomplete'

const CategoryInput = React.createClass({
  componentDidMount: function() {
    this.onAutocomplete()
  },

  handleSelectedCategory: function(event, selected) {
    JobWizardEventEmitter.emit('job:wizard:categorySelected', event, selected)
    this.props.handleSelectedCategory && this.props.handleSelectedCategory(event, selected)
  },

  onAutocomplete: function() {
    CategoryAutocomplete.taskAutocomplete(`.category-${this.props.guId}`, this.handleSelectedCategory, this.props.preferKeywords, this.props.resultCallback)
  },

  render: function() {
    return(
      <input type="text"
        autoFocus
        className={`form-control category-${this.props.guId}`}
        name="category"
        data-category-id={this.props.categoryId}
        id={`category-${this.props.guId}`}
        placeholder={this.props.placeholder || 'e.g. Tiler, Cleaner, Electrician'}
        value={this.props.category}
        onChange={this.props.handleCategoryChange}
      />
    )
  }
})

export default CategoryInput

