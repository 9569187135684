import chatDispatcher from 'common/components/chat/data/dispatcher'
import assign from 'object-assign'
import { EventEmitter } from 'events'

let conversations = []
let links = null
let openConversationID = null
let pageContextType = null
const CHANGE_EVENT = 'change'
let loading = true


const conversationStore = assign({}, EventEmitter.prototype, {
  fetchAll: function (url) {
    let params = { include: 'eois,jobs', 'page[size]': 10 }
    let dispatchSuccessType = 'CONVERSATIONS_FETCH_SUCCESS'
    API.get(url, params, dispatchSuccessType)
  },

  fetch: function(id) {
    let url = `${window.App.url}/jobs_conversations/${id}`
    let params = { include: 'eoi,job' }
    let dispatchSuccessType = 'CONVERSATION_FETCH_SUCCESS'
    API.get(url, params, dispatchSuccessType)
  },

  fetchNext: function() {
    let params = { include: 'eois,jobs' }
    let dispatchSuccessType = 'CONVERSATIONS_FETCH_NEXT_SUCCESS'
    API.get(links.next, params, dispatchSuccessType)
  },

  get: function (id) {
    return conversations.filter((conversation) => conversation.id === id)[0]
  },

  getCurrent: function() {
    return this.get(this.getOpenConversationID());
  },

  getAll: function () {
    return conversations
  },

  getLoading: function () {
    return loading
  },

  getOpenConversationID: function() {
    return openConversationID
  },

  getPageContextType: function () {
    return pageContextType
  },

  // boilerplate events
  emitChange: function () {
    this.emit(CHANGE_EVENT)
  },

  addChangeListener: function (callback) {
    this.on(CHANGE_EVENT, callback)
  },

  removeChangeListener: function (callback) {
    this.removeListener(CHANGE_EVENT, callback)
  }
})


// helper functions (private)
let API = {
  get: (url, data, successDispatch) => {
    $.ajax({
      url: url,
      data: data,
      method: 'GET',
      headers: { 'Accept': 'application/vnd.api+json; version=2' },
    }).done((response) => {
      console.log(response)
      chatDispatcher.dispatch({
        type: successDispatch,
        data: response
      })
    })
  }
}

function addIncludedDataToConversations(included, newConversations) {
  var eois = included.slice(0, newConversations.length)
  var jobs = included.slice(newConversations.length) // only unique job ids are returned in the included ie no duplicates

  newConversations.map((conversation, index) => {
    conversation.included = {}
    conversation.included.eoi = eois[index]
    conversation.included.job = jobs.filter((job) => job.id === conversation.relationships.job.data.id)[0] // need to add jobs from included to corresponding conversation due to no duplicates being returned
  })

  return newConversations
}

function conversationExists(id) {
  return chatDispatcher.conversations.indexOf(conversationStore.get(id)) !== -1
}

function updateConversations(payload) {
  let conversationID = payload.data.data.id
  let conversation = payload.data.data
  let included = payload.data.included

  addIncludedDataToConversations(included, [conversation])

  function replaceConversation() {
    let index = chatDispatcher.conversations.indexOf(conversationStore.get(conversationID))
    chatDispatcher.conversations[index] = conversation
  }

  function addToBeginningOfConversations() {
    chatDispatcher.conversations = [...[conversation], ...chatDispatcher.conversations]
  }

  if (conversationExists(conversationID)) {
    replaceConversation()
  } else {
    addToBeginningOfConversations()
  }
}


// Register dispatcher callback (single place where all events that "modify" your Stores are handled) ---------------------------------------------------------------------------  
conversationStore.dispatchToken = chatDispatcher.register(function (payload) { //will this work?
  switch (payload.type) {
    case 'LOAD_CONVERSATIONS':
      pageContextType = payload.data

      function primaryConversationParamsBuilder() {
        let primaryConversationParam = window.location.pathname.match(/\/[0-9]+$/)
        return primaryConversationParam ? '?primary_conversation_id=' + primaryConversationParam[0].replace(/^\//, '') : ''
      }

      let url = `${window.App.url}/jobs_conversations/${pageContextType}${primaryConversationParamsBuilder()}`
      conversationStore.fetchAll(url)
      break

    case 'CONVERSATIONS_FETCH_SUCCESS':
      loading = false
      let primaryConversationID = window.location.pathname.match(/\/[0-9]+$/)

      function hasConversations() {
        return !!payload.data.data.length
      }

      function setOpenConversation() {
        if (!openConversationID && (primaryConversationID || !window.mobilecheck())) { //first chat is open by default on desktop not mobile
          openConversationID = conversations[0].id // default to first conversation if not already set
        }
      }

      if (hasConversations()) {
        var newConversations = payload.data.data
        var included = payload.data.included
        conversations = addIncludedDataToConversations(included, newConversations)
        links = payload.data.links
        setOpenConversation()
      }
      break

    case 'CONVERSATION_FETCH_SUCCESS':
      updateConversations(payload)
      break

    case 'CONVERSATIONS_FETCH_NEXT':
      if (!loading && links.next) {
        loading = true
        conversationStore.fetchNext(payload.data)
      }
      break

    case 'CONVERSATIONS_FETCH_NEXT_SUCCESS':
      loading = false
      var newConversations = payload.data.data
      var included = payload.data.included
      newConversations = addIncludedDataToConversations(included, newConversations)
      conversations = [...conversations, ...newConversations]
      links = payload.data.links
      break

    case 'UPDATE_OPEN_CONVERSATION_ID':
      openConversationID = payload.data
      break

    case 'MARK_ALL_AS_READ_SUCCESS': // triggered from MessageStore
      conversationStore.fetch(openConversationID)
      break

    case 'NEW_UNREAD_MESSAGE_RECEIVED':
      let conversationID = payload.data.data.relationships['jobs-conversation'].data.id
      conversationStore.fetch(conversationID)
      break

    case 'NEW_CONVERSATION_RECEIVED':
      updateConversations(payload)
      break

    case 'MOBILE_MESSAGE_BACK_BUTTON_PRESSED':
      openConversationID = null
      break

    default:
      return true;
  }

  // If action was acted upon, emit change event
  console.log('conversationsStore: ', payload.type)
  conversationStore.emitChange()
  return true
})


export default conversationStore
