import React from 'react'
import data from 'common/components/job_wizard/data/data'

const CloseModalWarning = function(props) {
  return (
    <div className="close-modal-warning" style={{display: 'none'}}>
      <div>
        {props.hideNotPostedWarningMessage || data.id ? '' : <p className="bold">Your job has NOT been posted!</p>}
        {data.id ? <p className="bold">Are you sure you want to close?</p> : <p>Are you sure you want to exit?</p>}
        <div className="row">
          <div className="col-sm-6">
            <a href="#" className="btn btn-block btn-clear btn-white font-12 modal-exit">
              { data.id ? 'Invite Businesses Now' : 'Exit' }
            </a>
          </div>
          <div className="col-sm-6">
            <a href="#" className="btn btn-block btn-white font-12 modal-stay" style={{color: 'red'}}>
              { data.id ? 'Let me add more info first' : 'Stay & Continue' }
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CloseModalWarning
