import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'

import RadioButtonRow from 'common/components/RadioButtonRow'

import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import ContactPreference from 'common/components/job_wizard/steps/ContactPreference'
import MoreDetails from 'common/components/job_wizard/MoreDetails'
import UpdateJob from 'common/components/job_wizard/steps/UpdateJob'

const Budget = {
  displayName: 'Budget',

  onSubmit: function(onStepProgression) {
    if (data.preferredOutcome === 'price') {
      if (data.showSuppressants) {
        advanceToStep(ContactPreference)
      } else {
        advanceToStep(MoreDetails)
      }
    } else if (data.preferredOutcome === 'quality') {
      advanceToStep(UpdateJob)
    } else {
      onStepProgression()
    }

    function advanceToStep(step) {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', step)
    }
  },

  buttonLayout: function() {
    if (data.preferredOutcome === 'price') {
      return 'continueButton'
    } else {
      return 'default'
    }
  },

  component: React.createClass({
    set_price_range_matrix: [
      { id: 0, text: 'Under $100',      value: '$1_-_$100',     className: 'under-100'},
      { id: 1, text: '$100 - $500',     value: '$100_-_$500',   className: 'hundred-500'},
      { id: 2, text: '$500 - $1000',    value: '$500_-_$1000',  className: 'five-hundred-1000'},
      { id: 3, text: '$1000 - $2000',   value: '$1000_-_$2000', className: 'thousand-200'},
      { id: 4, text: '$2000 - $5000',   value: '$2000_-_$5000', className: 'two-thousand-5000'},
      { id: 5, text: 'More than $5000', value: '$5001_-_$5001', className: 'more-than-5000'}
    ],

    getInitialState: function() {
      return this.getStateValues()
    },

    validator: undefined,

    getStateValues: function() {
      return {
        budgetType:          data.budgetType,
        budgetPriceRange:    data.budgetPriceRange,
        budgetSetPriceRange: data.budgetSetPriceRange
      }
    },

    componentDidMount: function() {
      if (data.preferredOutcome === 'price') {
        this.hideModalCloseButton()
      }

      $('#job-modal').bootstrapValidator({ /* */ })
      this.validator = $('#job-modal').data('bootstrapValidator')

      $('#budget-selection').radioButtonGroup({activeRowClass: 'active'})
      $('#budget-price-range').radioButtonGroup({activeRowClass: 'active'})

      $('#budget-price-range > * [class="radio"]').click(function(e) {
        actions.updateBudgetPriceRange(e.currentTarget.lastChild.lastChild.value)
      })

      this.componentDoesValidate()
    },

    hideModalCloseButton: function() {
      $('#job-modal')
        .find('.modal-header')
        .find('.close')
        .addHidden()
    },

    budgetInMindChanged: function(e) {
      let val = $(e.currentTarget).data('value')
      actions.updateBudgetInMind(val)
      this.state = this.getStateValues()
      this.validator.updateStatus($("[name='budget_select']"), "VALID")
      this.wizard.scrollTo('#price-range-or-set')
    },

    budgetTypeChanged: function(e) {
      let val = $(e.currentTarget).data('value')
      actions.updateBudgetType(val)
      this.state = this.getStateValues()
      this.wizard.scrollTo('#budget-amount')
      this.validator.updateStatus($("[name='budget']"), "VALID")
    },

    budgetPriceRangeChanged: function(e) {
      let $selectedItem = $(e.currentTarget).find('[type="radio"]')[0]
      let [min, max] = $selectedItem.value.replace(/\$|_/g, '').split('-');

      actions.updateBudgetPriceRange(min, max)
      this.state = this.getStateValues()
      this.validator.updateStatus($("[name='price_range']"), "VALID")
      this.autoProgress()
    },

    setPriceChanged: function(e) {
      let val = $(e.currentTarget).val()
      actions.updateBudgetSetPriceRange(val)
      this.state = this.getStateValues()
    },

    handleNoBudgetClick: function(e) {
      this.budgetInMindChanged(e)
      this.state = this.getStateValues()

      $('#budget-amount').addClass('hidden')

      this.autoProgress()
    },

    autoProgress: function() {
      this.props.onStepProgression()
    },

    isBudgePriceRangeChecked: function(priceRange) {
      let selectedPriceRange = data.budgetPriceRange;

      return selectedPriceRange === priceRange
    },

    componentDoesValidate: function() {
      $(function() { // doc ready
        this.validator.addField('budget_select',
          {
            validators: {
              callback: {
                message: 'Please choose if you have a budget in mind',
                callback: function(value, validator) {
                  console.log("calling back");
                  return ((data.budgetInMind === "" || data.budgetInMind === null) ? false : true);
                }
              }
            }
          }
        )

        this.validator.addField('budget',
          {
            validators: {
              notEmpty: {
                message: 'Please select a "Budget" type'
              }
            }
          }
        )

        this.validator.addField('set_price',
          {
            validators: {
              notEmpty: {
                message: 'Please enter a "Set Price"'
              },
              regexp: {
                regexp: /^\$?\-?([1-9]{1}[0-9]{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$/,
                message: 'Please enter a dollar amount for the price'
              }
            }
          }
        )

        this.validator.addField('price_range',
          {
            validators: {
              notEmpty: {
                message: 'Please select a "Price Range"'
              }
            }
          }
        )
      }) // end doc ready
    },

    render: function() {
      let radioButtonVisible = "col-xs-2 col-sm-2 circle"
      let radioButtonHidden = `${radioButtonVisible} hidden`

      return (
        <div className="form-group">
          <h3 className="mb15 mt10">Do you have a budget in mind?</h3>

          <ul className="nav nav-tabs nav-justified ss-tabs" role="tablist" id="budget">
            <li role="presentation" className={(this.state.budgetInMind === "yes") ? 'active' : ''} >
              <a href="#budget-yes" name="budget_select" role="tab" data-toggle="tab" data-value="yes" className="yes" onClick={this.budgetInMindChanged}>Yes</a>
            </li>
            <li className={(this.state.budgetInMind === "no") ? 'active' : ''} >
              <a href="#budget-no" name="budget_select" role="tab" data-toggle="tab" data-value="no" className="no" id="budget-no-button" onClick={this.handleNoBudgetClick}>No</a>
            </li>
          </ul>

          <div className="tab-content" id="price-range-or-set">
            <div role="tabpanel" className={"tab-pane form-group " + ((this.state.budgetInMind === "yes" || this.stateBudgetInMind === "no") ? ' active' : '')}  id="budget-yes">
              <div className="radio-button-box minor-bold" id="budget-selection">
                <div className="form-group mt15">
                  <div  className={"radio " +((this.state.budgetType === "price_range") ? "active" : "")} role="tab" data-value="price_range"  data-toggle="tab" href="#budget-price-range" onClick={this.budgetTypeChanged}>
                    <div className="row">
                      <div className="col-xs-10 col-sm-10 price_range">Price Range</div>
                      <div className={(this.state.budgetType === "price_range") ? radioButtonVisible : radioButtonHidden}>
                        <i className="ficon-circle-x pull-right"></i>
                      </div>
                      <input type="radio"  defaultChecked={(this.state.budgetType === "price_range") ? 'checked' : ''} id="budget" name="budget" value="price_range" className="form-control invisible" onClick={this.budgetTypeChanged}/>
                    </div>
                  </div>
                  <div className={"radio " + ((this.state.budgetType === "set_price") ? "active" : '')} role="tab" data-value="set_price" data-toggle="tab" href="#budget-set-price" onClick={this.budgetTypeChanged}>
                    <div className="row">
                      <div className="col-xs-10 col-sm-10">Set Price</div>
                      <div className={(this.state.budgetType === "set_price") ? radioButtonVisible : radioButtonHidden}>
                        <i className="ficon-circle-x pull-right"></i>
                      </div>
                      <input type="radio"  defaultChecked={(this.state.budgetType === "set_price") ? 'checked' : ''} id="budget" name="budget" value="set_price" className="form-control invisible"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" className="tab-pane" id="budget-no"></div>
          </div>

          <div className="tab-content" id="budget-amount">
            <div role="tabpanel" className={"tab-pane form-group "  + ((this.state.budgetType === "set_price") ? "active" : "")} id="budget-set-price">
              <input type="text" id="set_price" name="set_price" defaultValue={this.state.budgetSetPriceRange} className="form-control" placeholder="e.g. $250.00" onChange={this.setPriceChanged} />
            </div>

            <div role="tabpanel" className={"tab-pane form-group " + ((this.state.budgetType === "price_range") ? "active" : "")} id="budget-price-range">
              <div className="radio-button-box minor-bold">
                <div className="form-group mt15">
                  {this.set_price_range_matrix.map((priceRange) => {
                    return (
                      <RadioButtonRow
                        name="price_range"
                        key={priceRange.id}
                        text={priceRange.text}
                        id={priceRange.id}
                        className={priceRange.className}
                        value={priceRange.value}
                        isChecked={this.isBudgePriceRangeChecked(priceRange.value)}
                        onClickHandler={this.budgetPriceRangeChanged}
                      />
                    )
                  }, this)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default Budget
