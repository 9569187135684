const steps = {
  "209": { //House Cleaning
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "What kind of cleaning service do you need?",
            "jobDescriptionKey": "Cleaning services",
            "validation": {
              "groupName": "cleaning_services",
              "message": "Please select a cleaning service."
            },
            "options": [
              {
                "value": "One off Spring clean",
                "text": "One off Spring clean"
              },
              {
                "value": "End of Lease Clean",
                "text": "End of Lease Clean"
              },
              {
                "value": "Weekly service",
                "text": "Weekly service"
              },
              {
                "value": "Fornightly service",
                "text": "Fornightly service"
              },
              {
                "value": "Monthly service",
                "text": "Monthly service"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "What type of property needs to be cleaned?",
            "jobDescriptionKey": "Property type",
            "validation": {
              "groupName": "property_type",
              "message": "Please select a property type"
            },
            "options": [
              {
                "value": "House",
                "text": "House"
              },
              {
                "value": "Unit/Apartment",
                "text": "Unit/Apartment"
              }
            ]
          },
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "How many bedrooms?",
            "jobDescriptionKey": "Bedrooms count",
            "validation": {
              "groupName": "bedrooms_count",
              "message": "Please select a bedroom count"
            },
            "options": [
              {
                "value": "1",
                "text": "1"
              },
              {
                "value": "2",
                "text": "2"
              },
              {
                "value": "3",
                "text": "3"
              },
              {
                "value": "4+",
                "text": "4+"
              }
            ]
          },
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "How many bathrooms?",
            "jobDescriptionKey": "Bathrooms count",
            "validation": {
              "groupName": "bathrooms_count",
              "message": "Please select a bathcoom count"
            },
            "options": [
              {
                "value": "1",
                "text": "1"
              },
              {
                "value": "2",
                "text": "2"
              },
              {
                "value": "3+",
                "text": "3+"
              }
            ]
          },
        ]
      }
    ]
  }
}

export default steps
