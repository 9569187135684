import React from 'react'

class CheckBoxRow extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount() {
    if (!this.props.optional) {
      this.setupValidation()
    }
  }

  setupValidation() {
    const validator = $('#job-modal').data('bootstrapValidator')
    validator.addField(this.props.name, {
      validators: {
        choice: {
          min: 1,
          message: 'Please select at least one.'
        }
      }
    })
  }

  render () {
    return (
      <div className={`check-box ${(this.props.isChecked ? 'active' : '')}`} onClick={this.props.onClickHandler}>
        <div className="row">
          <div className="col-xs-10 col-sm-10">{this.props.text}</div>
          <div className={`col-xs-2 col-sm-2 tick ${!this.props.isChecked && 'hidden'}`}>
            <i className="ficon-tick pull-right" />
          </div>
          <input
            type="checkbox"
            name={this.props.name}
            ref={this.props.name}
            id={this.props.id}
            defaultChecked={this.props.isChecked ? 'checked' : ''} value={this.props.value}
            data-subcategory-id={this.props.id}
            className="form-control invisible"
          />
          </div>
        </div>
        )
     }
 }

export default CheckBoxRow
