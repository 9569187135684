import coreUtils from 'common/src/utils/coreUtils'

import subcategoryQuestionsIndex from 'common/components/job_wizard/data/subcategory_id_questions/index'

export const jobData = {
    data: {
        id:                  null,
        attachments:         [],
        backToSubmit:        false,
        budgetInMind:        'no',
        budgetPriceRange:    null,
        budgetSetPriceRange: null,
        budgetType:          null,
        canCall:             true,
        categories:          [],
        category:            "",
        category_id:         0,
        keyword:             null,
        cc_number:           null,
        contactPreference:   null,
        quoteEstimate:       null,
        description:         null,
        emailAddress:        "",
        extraDetails:        {},
        fileNames:           [],
        firstName:           null,
        maxBudget:           null,
        minBudget:           null,
        mustCreateAccount:   false,
        omniAuthProvider:    null,
        originName:          "ss",
        phoneNumber:         null,
        preferedVisitTime:   null,
        price:               null,
        products:            { urgent: null },
        quoteType:           [],
        referrer:            {
                               url: location.href,
                               source: coreUtils.mobilecheck() ? 'mobile' : 'desktop',
                               user_agent: coreUtils.userAgent(),
                               app_version: coreUtils.urlInspector.getURLParameter('app_version') || 'modal',
                               channel: coreUtils.urlInspector.getURLParameter('channel') || undefined,
                               cta: coreUtils.urlInspector.getURLParameter('cta') || undefined
                             },
        exclusiveFor:        null,
        exclusiveBusiness:   null,
        requireVisit:        null,
        schedule:            "",
        scheduleDate:        null,
        scheduleTime:        null,
        siteVisit:           "",
        siteVisitTime:       null,
        suburb:              "",
        suburb_id:           0,
        with_cc:             null,
        subcategoryQuestions:  subcategoryQuestionsIndex,
        omniAuthDetails:     { 'account_from_omni_auth': false },
        consumerLeads:       [],
        lastSlide:           false,
        otp:                 null,
        closureSurvey:     {
          job: {},
          possibleBusinesses: [],
          outcome: null,
          providerAccountId: null,
          providerAccountName: null,
          rating: null,
          comments: null,
          selectedReasons: []
        },
        progressWidth:       null,
        preferredOutcome:    null,
        hostedFieldsToken:   null,
        hostedFieldsUserId:  null,
        environment:         '',
        premiumListingAmount: '',
        popularJobs:         null
    },

    seedData: {
        id:                  null,
        attachments:         [],
        backToSubmit:        false,
        budgetInMind:        'no',
        budgetPriceRange:    null,
        budgetSetPriceRange: null,
        budgetType:          null,
        canCall:             true,
        categories:          [], // contains (one or many) subcategories
        category:            "", // main category
        category_id:         0,
        keyword:             null,
        cc_number:           null,
        contactPreference:   null,
        quoteEstimate:       null,
        description:         null,
        emailAddress:        "",
        extraDetails:        {},
        fileNames:           [],
        firstName:           null,
        maxBudget:           null,
        minBudget:           null,
        mustCreateAccount:   false,
        omniAuthProvider:    null,
        originName:          "ss",
        phoneNumber:         null,
        preferedVisitTime:   null,
        price:               null,
        products:            { urgent: null },
        quoteType:           [],
        referrer:            {
                               url: location.href,
                               source: coreUtils.mobilecheck() ? 'mobile' : 'desktop',
                               user_agent: coreUtils.userAgent(),
                               app_version: coreUtils.urlInspector.getURLParameter('app_version') || 'modal',
                               channel: coreUtils.urlInspector.getURLParameter('channel') || undefined,
                               cta: coreUtils.urlInspector.getURLParameter('cta') || undefined
                             },
        exclusiveFor:        null,
        exclusiveBusiness:   null,
        requireVisit:        null,
        schedule:            "",
        scheduleDate:        null,
        scheduleTime:        null,
        siteVisit:           "",
        siteVisitTime:       null,
        suburb:              "",
        suburb_id:           0,
        with_cc:             null,
        subcategoryQuestions:  subcategoryQuestionsIndex,
        omniAuthDetails:     { 'account_from_omni_auth': false },
        consumerLeads:       [],
        lastSlide:           false,
        additionalQuestion:  false,
        otp:                 null,
        closureSurvey:     {
          job: {},
          possibleBusinesses: [],
          outcome: null,
          providerAccountId: null,
          providerAccountName: null,
          rating: null,
          comments: null,
          selectedReasons: []
        },
        progressWidth:       '0%',
        preferredOutcome:    null,
        hostedFieldsToken:   null,
        hostedFieldsUserId:  null,
        environment:         '',
        premiumListingAmount: '',
        popularJobs:         null
    },

    resetData() {
        return this.seedData
    },

    resetDataMobile() {
        var data     = this.data
        var seedData = this.seedData

        data.id                  = seedData.id
        data.attachments         = seedData.attachments
        data.backToSubmit        = seedData.backToSubmit
        data.budgetInMind        = seedData.budgetInMind
        data.budgetPriceRange    = seedData.budgetPriceRange
        data.budgetSetPriceRange = seedData.budgetSetPriceRange
        data.budgetType          = seedData.budgetType
        data.canCall             = seedData.canCall
        data.categories          = seedData.categories
        data.category            = seedData.category
        data.category_id         = seedData.category_id
        data.keyword             = seedData.keyword
        data.cc_number           = seedData.cc_number
        data.contactPreference   = seedData.contactPreference
        data.quoteEstimate       = seedData.quoteEstimate
        data.description         = seedData.description
        data.emailAddress        = seedData.emailAddress
        data.extraDetails        = seedData.extraDetails
        data.fileNames           = seedData.fileNames
        data.firstName           = seedData.firstName
        data.maxBudget           = seedData.maxBudget
        data.minBudget           = seedData.minBudget
        data.mustCreateAccount   = seedData.mustCreateAccount
        data.omniAuthProvider    = seedData.omniAuthProvider
        data.originName          = seedData.originName
        data.phoneNumber         = seedData.phoneNumber
        data.preferedVisitTime   = seedData.preferedVisitTime
        data.price               = seedData.price
        data.products            = seedData.products
        data.quoteType           = seedData.quoteType
        data.referrer            = seedData.referrer
        data.exclusiveFor        = seedData.exclusiveFor
        data.exclusiveBusiness   = seedData.exclusiveBusiness
        data.requireVisit        = seedData.requireVisit
        data.schedule            = seedData.schedule
        data.scheduleDate        = seedData.scheduleDate
        data.scheduleTime        = seedData.scheduleTime
        data.siteVisit           = seedData.siteVisit
        data.siteVisitTime       = seedData.siteVisitTime
        data.suburb              = seedData.suburb
        data.suburb_id           = seedData.suburb_id
        data.with_cc             = seedData.with_cc
        data.subcategoryQuestions= seedData.subcategoryQuestions
        data.omniAuthDetails     = seedData.omniAuthDetails
        data.consumerLeads       = seedData.consumerLeads
        data.lastSlide           = seedData.lastSlide
        data.additionalQuestion  = seedData.additionalQuestion
        data.otp                 = seedData.otp
        data.closureSurvey       = seedData.closureSurvey
        data.progressWidth       = seedData.progressWidth
        data.preferredOutcome    = seedData.preferredOutcome
        data.hostedFieldsToken   = seedData.hostedFieldsToken
        data.hostedFieldsUserId  = seedData.hostedFieldsUserId
        data.environment         = seedData.environment
        data.premiumListingAmount= seedData.premiumListingAmount
        data.popularJobs         = seedData.popularJobs

        return data
    }
}

export default jobData.data
