const steps = {
	"1250": { //T-Shirt Printing
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
						"label": "What type of T-Shirt do you need?",
						"jobDescriptionKey": "T-Shirt type",
            "validation": {
              "groupName": "tshirt_type",
              "message": "Please choose type of tshirt.",
            },
            "options": [
              {
                "value": "I am supplyng my own shirts",
                "text": "I am supplyng my own shirts",
              },
              {
                "value": "Men's T-shirts",
                "text": "Men's T-shirts",
              },
							{
                "value": "Women's T-shirts",
                "text": "Women's T-shirts",
							},
							{
                "value": "Kids T-shirts",
                "text": "Kids T-shirts",
							},
							{
                "value": "Jumpers/Hoodies",
                "text": "Jumpers/Hoodies",
							},
							{
                "value": "Polo Shirts",
                "text": "Polo Shirts",
							},
							{
                "value": "Mixed/Other(please specify in the description)",
                "text": "Mixed/Other(please specify in the description)",
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
						"label": "What size T-Shirt do you need?",
						"jobDescriptionKey": "T-Shirt type",
            "validation": {
              "groupName": "tshirt_size",
              "message": "Please select a size of T-Shirt."
            },
            "options": [
              {
                "value": "N/A",
                "text": "N/A"
              },
              {
                "value": "I am supplying my own T-shirts",
                "text": "I am supplying my own T-shirts"
              },
              {
                "value": "Small",
                "text": "Small"
              },
              {
                "value": "Medium",
                "text": "Medium"
              },
              {
                "value": "Large",
                "text": "Large"
              },
              {
                "value": "X Large",
                "text": "X Large"
              },
							{
								"value": "XX Large",
								"text": "XX Large"
							},
              {
                "value": "Mixed (please specify in the description)",
                "text": "Mixed (please specify in the description)"
              }
            ]
          }
        ]
      },
			{
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
						"label": "Will you be supplying your own design?",
						"jobDescriptionKey": "Design",
            "validation": {
              "groupName": "design",
              "message": "Please select option that apply."
            },
            "options": [
              {
                "value": "I already have a design",
                "text": "I already have a design"
              },
              {
                "value": "I need you to design it for me",
                "text": "I need you to design it for me"
              }
            ]
          }
        ]
      },
			{
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
						"label": "How many T-Shirts do you need?",
						"jobDescriptionKey": "T-Shirt count",
            "validation": {
              "groupName": "tshirt_count",
              "message": "Please select a t-shirt count."
            },
            "options": [
              {
                "value": "0-10",
                "text": "0-10"
              },
              {
                "value": "10-20",
                "text": "10-20"
              },
							{
								"value": "50 or more (please specify in the description)",
								"text": "50 or more (please specify in the description)"
							}
            ]
          }
        ]
      }
    ]
  }
}

export default steps
