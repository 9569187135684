import React from 'react'

import UpdateCreditCardButton from 'common/components/credit_card/UpdateCreditCardButton'

const ExistingCreditCard = function(props) {
  return(
    <div>
      <div className="js-existing-credit-card existing-credit-card">
        <div className="row">
          <div className="col-xs-12">
            <h5 className="text-gray">Use this card</h5>
            <div className="text-gray fs28 mb16">
              {props.ccNumber}
              <UpdateCreditCardButton 
              isUpdatingCreditCard={props.isUpdatingCreditCard}
              toggleCreditCardInput={props.toggleCreditCardInput}
              disabled={props.disableUpdateButton}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExistingCreditCard
