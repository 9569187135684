import React from 'react'

import data from 'common/components/job_wizard/data/data'

import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'

import JobUpgrades from 'common/components/job_wizard/steps/JobUpgrades'

import wizardUtils from 'common/components/job_wizard/utils'
import coreUtils from 'common/src/utils/coreUtils'
import stringUtils from 'common/src/utils/stringUtils'

const UpdateJob = {
  displayName: 'UpdateJob',

  component: React.createClass({
    getInitialState: function() {
      return {
        loading: true
      }
    },

    componentDidMount: function() {
      const $modalFooter = $('#job-modal').find('.modal-footer')
      $modalFooter.hide()

      let job     = data

      let jobData = {
        id:                 data.id,
        budget:             job.minBudget,
        min_budget:         job.minBudget,
        max_budget:         job.maxBudget,
        schedule:           buildJobSchedule(),
        description:        this.buildDescription(),
        contact_preference: data.contactPreference
      }

      $.ajax({
        url: '/job_wizard/update_job',
        method: "POST",
        data: jobData
      })
      .done(function(data) {
        data.id = data.job_id
        data.with_cc = data.with_cc
        data.cc_number = data.cc_number
        finalizeJobPosting()
        coreUtils.appMsg({ id: data.job_id, status: 'completed' }) // used by mobile app for controlling the flow
        coreUtils.googleAnalytics({hitType: 'event', eventCategory: 'jobpost', eventAction: 'jobpostfinal'})
      })

      function finalizeJobPosting() {
        if(wizardUtils.products.hasSelectedJobUpgrades()) {
          advanceToJobUpgradesStep()
        } else {
          redirectToJobPage()
        }

        function advanceToJobUpgradesStep() {
          JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobUpgrades)
        }

        function redirectToJobPage(){
          document.location.href = redirectUrl()
        }

        function redirectUrl() {
          let jobID = data.id

          switch (data.referrer.cta) {
            case 'profile': {
              return `/jobs/${jobID}/profilejob?successful_job=true`
            }
            case 'business_directory_card': {
              return `/jobs/${jobID}/bcardjob?successful_job=true`
            }
            default: {
              return `/jobs/${jobID}/posted?successful_job=true`
            }
          }
        }
      }

      function buildJobSchedule() {
        if (job.schedule !== 'on_a_particular_date') { return "" }

        var [day, month, year] = job.scheduleDate.split("/")

        return new Date([year, month, day])
      }
    },

    render: function() {
      return (
        <div className="modal-body job-form-body">
          <h3>Updating your job details...
            <div className="spinner-icon text-center" style={{marginLeft: '15px'}}/>
          </h3>
        </div>
      )
    }
  })
}

/* build the description object for the job */
function buildDescription() {
  const job = data;

  let description = `${job.description}`

  if (job.extraDetails) {
    buildExtraDetails()
  }

  switch(job.schedule) {
  case 'this_week':
    description += '\n\nSchedule: Need job done this week\n\n';
    break;
  case 'next_couple_of_weeks':
    description += '\n\nSchedule: Need job done in the next couple of weeks\n\n';
    break;
  case 'on_a_particular_date':
    description += `\n\nSchedule: Need job done on ${job.scheduleDate}\n\n`
    break;
  case 'asap_urgent':
    description += '\n\nSchedule: Need job done urgently\n\n'
    break;
  default:
    description += '\n\nSchedule: Not Specified\n\n';
  }

  if (job.budget === 'yes') {
    description += `\nBudget: ${stringUtils.capitalise(job.budgetType)}`
  }
  else if (job.budgetType !== 'price_range' && job.budgetType !== 'set_price') {
    description += `\nBudget: Not Specified`
  }

  if (job.budgetType === 'price_range' && job.budgetPriceRange !== null) {
    if (job.minBudget == '5001' && job.maxBudget == '5001') {
      description += `\nBudget: More than $5000`
    } else {
      description += `\nBudget: $${job.minBudget} - $${job.maxBudget}`
    }
  }

  if (job.budgetType === 'set_price' && job.budgetSetPriceRange !== null) {
    let price = job.budgetSetPriceRange
    description += `\nBudget: $${price}`
  }

  if (job.siteVisit !== null)
  {
    let siteVisit = stringUtils.capitalise(job.siteVisit.replace(/_/g, ' '))
    description += `\n\nSite Visit: ${siteVisit}`

    if (job.siteVisitTime !== null) {
      description += `\nSite Visit: ${stringUtils.capitalise(job.siteVisitTime)}`
    }
  }

  function buildExtraDetails() {
    description += '\n\n\nSpecific Job Details: \n'
    formatExtraDetails()

    function formatExtraDetails() {
      Object.keys(job.extraDetails).forEach(function(descriptionKey) {
        if(Array.isArray(job.extraDetails[descriptionKey])) {
          description += `\n ${descriptionKey}: \n${addLeadingHyphens(descriptionKey)} \n`
        } else {
          description += `\n ${descriptionKey}: ${job.extraDetails[descriptionKey]} \n`
        }
      })
    }

    function addLeadingHyphens(key) {
      return job.extraDetails[key].map((el) => '  - ' + el).join('\n')
    }
  }

  return description
}

export default UpdateJob
