import React, { Component } from 'react'

export default class LocationEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      radius:      this.props.radius,
      suburbId:    this.props.suburb.id,
      suburbName:  this.props.suburb.name,
      lat:         this.props.suburb.lat,
      lng:         this.props.suburb.lng
    }
  }

  componentDidMount() {
    this.initializeMap()

    if (!this.props.mapOnly) {
      this.onAutocomplete()
    }

    // $('.collapse')
    //   .on('shown.bs.collapse', function() {
    //     $(this).parent().find('.show-map').addHidden()
    //     $(this).parent().find('.close-map').rmHidden()
    //   })
    //   .on('hide.bs.collapse', function() {
    //     $(this).parent().find('.close-map').addHidden()
    //     $(this).parent().find('.show-map').rmHidden()
    //   })
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps) {
      this.setState({
        radius: nextProps.radius,
        suburbId: nextProps.suburb.id,
        suburbName: nextProps.suburb.value,
        lat: nextProps.suburb.lat,
        lng: nextProps.suburb.lng
      }, function() {
        this.setMapChanges()
      })
    }
  }

  handleRadiusChange = (event) => {
    const radiusValue = (event.target.validity.valid) ? event.target.value : this.state.radius

    this.setState({
      radius: radiusValue
    }, function() {
      this.setCircleRadius()
      this.setMapZoom()
      this.props.handleComponentUpdate()
      $('#coverage-filter').trigger('job-filter.save');
    })
  }

  handleLocationChange = (event) => {
    this.setState({
      suburbId: ''
    })
  }

  handleAutocompleteSelection = (event, response) => {
    let suburb = response.item

    this.setState({
      suburbId: suburb.id,
      suburbName: suburb.name,
      lat: suburb.lat,
      lng: suburb.lng
    }, function() {
      this.setMapCenter()
      this.setMarkerPosition()
      this.props.handleComponentUpdate()
      $('#coverage-filter').trigger('job-filter.save');
    })
  }

  initializeMap = () => {
    let mapBox = document.querySelector("#map-box")
    let mapStyles = [ { featureType: 'road',     stylers: [{ visibility: 'off' }]},
                      { featureType: 'poi.park', stylers: [{ visibility: 'simplified' }]},
                      { featureType: 'transit',  stylers: [{ visibility: 'off' }]} ]
    let ausCenter = this.createLatLng(-23.6288140, 134.29759820)

    this.map = new window.google.maps.Map(mapBox, {
      visualRefresh: true,
      center: ausCenter,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      gestureHandling: 'cooperative',
      panControl: false,
      scrollwheel: false,
      zoomControl: true,
      zoomControlOptions: { style:    window.google.maps.ZoomControlStyle.LARGE,
                            position: window.google.maps.ControlPosition.LEFT_CENTER },
      disableDoubleClickZoom: true,
      scaleControl: true,
      scaleControlOptions: { position: window.google.maps.ControlPosition.RIGHT_BOTTOM },
      streetViewControl: false,
      draggable: false
    })

    this.map.setOptions({ styles: mapStyles })

    this.marker = new window.google.maps.Marker({
      map: this.map,
      position: ausCenter
    })

    this.circle = new window.google.maps.Circle({
      map: this.map
    })
    this.circle.bindTo('center', this.marker, 'position')

    this.setMapCenter()
    this.setMapZoom()
    this.setMarkerPosition()
    this.setCircleRadius()
  }

  setMapChanges = () => {
    this.setCircleRadius()
    this.setMapZoom()
    this.setMapCenter()
    this.setMarkerPosition()
  }

  setMapCenter = () => {
    let mapCenter
    if (this.state.lat && this.state.lng) {
      mapCenter = this.createLatLng(this.state.lat, this.state.lng)
    }
    this.map.setCenter(mapCenter)
  }

  setMarkerPosition = () => {
    this.marker.setPosition(this.createLatLng(this.state.lat, this.state.lng))
  }

  setCircleRadius = () => {
    this.circle.setRadius(this.state.radius * 1000)
  }

  onAutocomplete = () => {
    $('#location').autocomplete({
      source: function (request, cb) {
        $.ajax({
          url:     '/api/suburbs/lookup.json',
          method:  'GET',
          data:    { suburb: request.term }
        })
        .done(function(response) {
          let results = response.map( item => ({ value: item.name,
                                                 id : item.id,
                                                 lat: item.lat,
                                                 lng: item.lng })
                                    )
          cb(results)
        })
        .fail(function() {
          document.location = "/500";
        })
      },
      select: this.handleAutocompleteSelection,
      autoFocus: true
    })
  }

  createLatLng(lat, lng) {
    return new window.google.maps.LatLng(lat, lng)
  }

  setMapZoom = () => {
    let d = this.state.radius
    let zoom = d > 2800 ? 2 : d > 1250 ? 3 : d > 650 ? 4 : d > 330 ? 5 : d > 170 ? 6 : d > 85 ? 7 : d > 45 ? 8 : d > 21 ? 9 : d > 11 ? 10 : d > 5 ? 11 : d > 2 ? 12 : d > 1.5 ? 13 : d > 0.75 ? 14 : d > 0.38 ? 15 : d > 0.19 ? 16 : d > 0.09 ? 17 : d > 0.03 ? 18 : d > 0.02 ? 19 : d > 0.01 ? 20 : 10;
    this.map.setZoom(zoom)
  }

  render() {
    if (this.props.mapOnly) {
      return (
        <div id='map-area' className='relative'>
          <div id='map-container'>
            <div id='map-box'></div>
          </div>
        </div>
      )
    } else {
      return (
        <div id='map-area' className='relative'>
          <div id='map-accordion' role='tablist' aria-multiselectable='true'>
            <div className='flex flex-wrap md:flex-nowrap items-center gap-4'>
              <div className='whitespace-nowrap'>Send me leads up to</div>
              <input type='number'
                autoFocus
                className='w-14 input-text rounded-md input-text-small'
                name='radius'
                value={this.state.radius}
                onChange={this.handleRadiusChange}
                id='radius'
                size='40'
                min='1'
              />
              <div className='whitespace-nowrap'>km away from</div>
              <div className='w-full'>
                <input type='text'
                  id='location'
                  className='input-text rounded-md input-text-small'
                  name='location'
                  defaultValue={this.state.suburbName}
                  onChange={this.handleLocationChange}
                  size='40'
                />
                <input type='hidden'
                  name='suburb_id'
                  value={this.state.suburbId}
                />
              </div>
            </div>
            <div id='collapseMap' role='tabpanel' aria-labelledby='map-heading'>
              <div className='panel-body p-0 mt-6'>
                <div id='map-container'>
                  <div id='map-box'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}
