import React from 'react'
import store from 'common/components/job_wizard/data/store'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'

import coreUtils    from 'common/src/utils/coreUtils'

import CategoryInput from 'common/components/job_wizard/CategoryInput'

import 'bootstrapvalidator'

const Category = React.createClass({
  getInitialState() {
    return store.getCategory()
  },

  componentDidMount() {
    this.onValidation()
    store.addChangeListener(this.updateState)
  },

  componentWillUnmount() {
    store.removeChangeListener(this.updateState)
  },

  updateState(data) {
    this.setState(store.getCategory())
  },

  handleSelectedCategory( event, selected ) {
    event.preventDefault()
    actions.updateCategory(selected.item)
    actions.updateSubCategory(selected.item, 'add')
    actions.updateKeyword(selected.item.keyword)
    this.validateCategory()
  },

  handleCategoryOnChange(event) {
    actions.updateSubCategory({id: data.category_id, values: data.category}, 'remove')
    actions.updateCategory({ id: 0, value: event.target.value })
    actions.updateKeyword(null)
  },

  searchTerm() {
    if (this.props.emptySearch && this.hasNoSubcategory()) {
      return null
    }

    return this.state.keyword || this.state.category
  },

  hasNoSubcategory() {
    return data.category_id === 0
  },

  onValidation() {
    const $jobModal = $('#job-modal')
    $jobModal.bootstrapValidator({ /* */ })
    const validator = $jobModal.data('bootstrapValidator')

    validator.addField('category', {
      verbose: false,
      threshold: 99999,
      validators: {
        callback: {
          callback: (fieldValue, validator) => {
            if (this.props.isNoMatchCategory === false) {
              return true;
            }

            if (this.hasNoSubcategory()) {
              if (fieldValue !== '' && (this.state.resultCount || 0) == 0) {
                let linkStr = '<a href="https://www.serviceseeking.com.au/categories" target="_blank">here</a>'
                let errorMessage = `No matches found for "${fieldValue}". Try using a different term or view all categories ` + linkStr
                  return { valid: false,
                           message: errorMessage }
              } else {
                return { valid: false,
                message: 'Please enter your Industry and select the matching one from the drop-down menu' }
              }
            } else {
              return true
            }
          }
        }
      }
    })
  },

  validateCategory() {
    let validator = $('#job-modal').data('bootstrapValidator');
    validator.revalidateField('category')
  },

  setSearchResultCount(result) {
    this.setState({ resultCount: result.length }, this.validateCategory)
  },

  render() {
    return (
      <CategoryInput
        category={this.searchTerm()}
        handleSelectedCategory={this.handleSelectedCategory}
        handleCategoryChange={this.handleCategoryOnChange}
        placeholder={this.props.placeholder}
        preferKeywords="1"
        guId={coreUtils.generateGuid()}
        resultCallback={this.setSearchResultCount}
      />
    )
  }
})

export default Category