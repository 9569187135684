import React from 'react'

const UpdateCreditCardButton = function(props) {
  return(
    <div className="mb16"><a href="#" className={`js-change-credit-card btn btn-xs btn-clear ${props.disabled ? 'disabled' : ''}`} 
      onClick={props.toggleCreditCardInput}>{props.isUpdatingCreditCard ? 'Cancel Update' : 'Update Card'}</a></div>
  )
}

export default UpdateCreditCardButton
