import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import StepSequence from 'common/components/job_wizard/StepSequence'
import PostJob from 'common/components/job_wizard/steps/PostJob'
import Contact from 'common/components/job_wizard/steps/Contact'
import Eula from 'common/components/job_wizard/Eula'
import coreUtils from 'common/src/utils/coreUtils'
import API from 'common/src/api'

class Password extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      passwordless: null,
      showSendResetPassword: false,
      showOtpField: false,
      showCreatePassword: false,
      showOtpMessageError: false,
      showPassword: false,
      loadingSms: false,
      loadingEmail: false,
      loadingSubmit: false,
      alertMessage: '',
      messageClass: '',
      otp: null,
      validMobileNumber: false
    }
  }

  componentDidMount() {
    this.checkIfPasswordless()
  }

  setupValidation() {
    $('#job-modal').bootstrapValidator({ /* */ })

    const validator = $('#job-modal').data('bootstrapValidator')

    validator.addField("password", {
      validators: {
        notEmpty: {
          message: "Please enter your password"
        },
        blank: {}
      }
    })
  }

  checkIfPasswordless() {
    $.ajax({
      url: '/api/users/check_passwordless',
      dataType: 'json',
      data: { email: data.emailAddress }
    }).fail(function () {
      document.location = "/500"
    }).done((data) => {
      if (data.passwordless === true) {
        this.setState({ passwordless: true,
                        validMobileNumber: data.valid_mobile_number })
      }
      else {
        this.setState({ passwordless: false,
                        validMobileNumber: data.valid_mobile_number})
      }
      this.setupValidation()
    })
  }

  handleOnclickResetPassword = () => {
    this.setState({ passwordless: null, showSendResetPassword: true })
  }

  handleSendEmail = () => {
    $.ajax({
      beforeSend: () => {
        this.setState({ loadingEmail: true })
      },
      url: '/api/users/send_otp_email',
      dataType: 'json',
      data: { email: data.emailAddress }
    }).done((data) => {
      this.setState({ alertMessage: 'Please check your email, we have sent you the code to create new password.',
                      messageClass: 'alert-success',
                      loadingEmail: false,
                      showSendResetPassword: false,
                      showOtpField: true,
                      passwordless: null
                    })
    })
  }

  handleSendSms = () => {
    $.ajax({
      beforeSend: () => {
        this.setState({ loadingSms: true })
      },
      url: '/api/users/send_otp_sms',
      dataType: 'json',
      data: { email: data.emailAddress }
    }).done((data) => {
      this.setState({ alertMessage: 'Please check your SMS, we have sent you the code to create new password.',
                      messageClass: 'alert-success',
                      loadingSms: false,
                      showSendResetPassword: false,
                      showOtpField: true,
                      passwordless: null
                    })
    })
  }

  handleOnclickSubmitCode = () => {
    let otp = $('#otp').val()
    $.ajax({
      beforeSend: () => {
        this.setState({ loadingSubmit: true })
      },
      url: '/api/users/check_otp',
      dataType: 'json',
      data: { email: data.emailAddress, otp: otp }
    }).done((data) => {
      if (data.result === true) {
        this.setState({ showOtpMessageError: false,
                        showCreatePassword: true,
                        showOtpField: false,
                        alertMessage: ''
                      })
        actions.updateOtp(otp)
      }
      else {
        this.setState({ showOtpMessageError: true })
      }
    })
  }

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handlePasswordChange = () => {
    $('.reset-password').css('marginRight', '0px')
    $('.incorrect-password').addHidden()
  }

  renderSendEmailOption = () => {
    return (
      <button
        className="btn btn-block btn-clear"
        onClick={this.handleSendEmail}>{this.state.loadingEmail ? <span className="spinner-icon "/> : 'Send code via email'}
      </button>
    )
  }

  renderSendSmsOption = () => {
    if (data.phoneNumber !== null && this.state.validMobileNumber) {
      return (
        <span>
          <p className="mt10">or</p>
          <p className="mb10 mt10">Send an SMS to {data.phoneNumber}</p>
          <button
            className="btn btn-block btn-clear"
            onClick={this.handleSendSms}>{this.state.loadingSms ? <span className="spinner-icon "/> : 'Send code via SMS'}
          </button>
        </span>
      )
    }
  }

  renderAlertMessage = () => {
    if (this.state.alertMessage !== '') {
      return (
        <div className={`alert ${this.state.messageClass}`}>{this.state.alertMessage}</div>
      )
    }
  }

  renderOtpField = () => {
    if (this.state.showOtpField) {
      return (
        <div>
          <h3 className="mb15 mt20">Enter code:</h3>
          <div className="input-group">
            <span>
              <input
                type="text"
                name="otp"
                id="otp"
                className="form-control"
              />
            </span>
            <span className="input-group-btn">
              <a href="#" className="btn btn-block btn-main" onClick={this.handleOnclickSubmitCode}>Submit</a>
            </span>
          </div>
          { this.state.showOtpMessageError &&
            <div className="help-block text-red">Wrong code.</div>
          }
        </div>
      )
    }
  }

  renderCreatePasswordField = () => {
    if (this.state.showCreatePassword) {
      return (
        <div>
          <h3 className="mb15 mt20">Create new password</h3>
          <div className="input-group">
            <span>
              <input
                type={this.state.showPassword ? `text` : `password`}
                name="new-password"
                id="new-password"
                className="form-control"
              />
            </span>
            <span className="input-group-btn">
              <a href="#" className="btn btn-block btn-clear" onClick={this.handleShowPassword}>{this.state.showPassword ? 'HIDE' : 'SHOW'}</a>
            </span>
          </div>
          <div className="password-error help-block text-red hidden">Please enter your new password!</div>
        </div>
      )
    }
  }

  renderPasswordlessMessage = () => {
    if (this.state.passwordless === true) {
      return (
        <span>
          You haven't set up a password for this account yet.
          <br/>
          <a href="#" onClick={this.handleOnclickResetPassword}>Get a code to create new password</a>
        </span>
      )
    }
  }

  renderExistingPasswordMessage = () => {
    if (this.state.passwordless === false) {
      return (
        <span>
          We've found your existing account! Please enter your password to login and proceed.
        </span>
      )
    }
  }

  renderSendCodeOptions = () => {
    if (this.state.showSendResetPassword) {
      return (
        <p className="grey-box p10 mt10 text-center">
          {this.renderSendEmailOption()}
          {this.renderSendSmsOption()}
        </p>
      )
    }
  }

  renderPasswordField = () => {
    if (this.state.passwordless === false) {
      return (
        <div>
          <h3 className="mb15 mt20">Login</h3>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            className="form-control"
            onChange={this.handlePasswordChange}
          />
        </div>
      )
    }
  }

  renderResetPasswordButton = () => {
    if (this.state.passwordless === false) {
      return (
        <a href="#"
          className="reset-password pull-right mt5"
          onClick={this.handleOnclickResetPassword}>Reset your password
        </a>
      )
    }
  }

  render() {
    return (
      <div>
        <div className="password-form form-group">
          { this.renderAlertMessage() }
          { this.renderOtpField() }
          { this.renderCreatePasswordField() }
          <div className={`grey-box p10 mt10 ${this.state.passwordless !== null ? '' : 'hidden'}`}>
            { this.renderPasswordlessMessage() }
            { this.renderExistingPasswordMessage() }
          </div>
          { this.renderSendCodeOptions() }
          { data.omniAuthDetails['oauth_email_exists'] ? (
            <p className="grey-box p10 mt10">
              We will connect your {data.omniAuthDetails['oauth_provider']} account once you're logged in.
            </p>
          ) : ''
          }
          { this.renderPasswordField() }
          <span className="inline-block incorrect-password help-block text-red hidden">Incorrect password. Please try again or</span>
          { this.renderResetPasswordButton() }
        </div>

        { Eula.renderApp({ marginTop: "57px", marginBottom: "40px" }) }
      </div>
    )
  }
}
 export default Password