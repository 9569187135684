import React from 'react'

const JobPostingForm = function({stepComponent}) {
  return(
    <form id="job_form">
        { stepComponent }
    </form>
  )
}

export default JobPostingForm