import React from 'react'

const TextInput = function(props) {
  return (
    <div className='form-group'>
      <div className='col-xs-6'>
        <label className='pt10 pb10'>{props.label}</label>
      </div>
      <div className='col-xs-6'>
        <input
          name={props.name}
          type={props.inputType || 'text'}
          className='form-control'
          value={props.value}
          onChange={props.onChangeHandler}
          onKeyPress={props.onKeyPressHandler}
          readOnly={props.readOnly}
        />
      </div>
    </div>
  )
}

export default TextInput
