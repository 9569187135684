
import chatDispatcher from 'common/components/chat/data/dispatcher'

// actions abstract the dispatch action for use in views -------------------------------------------------------------------------------------------------------------------------
const unseenMessagesActions = {
  setCount: function (data) {
    chatDispatcher.dispatch({
      type: 'SET_UNSEEN_COUNT',
      data: data
    })
  }
}

export default unseenMessagesActions
