const steps = {
  "2243": { //Air conditioning installation
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Is this a residential or commercial job?",
            "jobDescriptionKey": "Residential or Commercial",
            "validation": {
              "groupName": "residential_commercial",
              "message": "Please choose type of plumbing required.",
            },
            "options": [
              {
                "value": "This is a Residential job",
                "text": "Residential",
              },
              {
                "value": "This is a Commercial job",
                "text": "Commercial",
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "What type of work is required?",
            "jobDescriptionKey": "Work Type",
            "validation": {
              "groupName": "work_type",
              "message": "Please select what type of work you need done."
            },
            "options": [
              {
                "value": "Installation",
                "text": "Installation"
              },
              {
                "value": "Service and Maintenance",
                "text": "Service and Maintenance"
              },
              {
                "value": "Repair",
                "text": "Repair"
              },
              {
                "value": "Diagnose fault",
                "text": "Diagnose fault"
              },
              {
                "value": "Other (please specify in the description)",
                "text": "Other (please specify in the description)"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "What type of air conditioning or heating unit??",
            "jobDescriptionKey": "Unit type",
            "validation": {
              "groupName": "unit_type",
              "message": "Please select what unit type."
            },
            "options": [
              {
                "value": "Ducted system",
                "text": "Ducted system"
              },
              {
                "value": "Inverted system",
                "text": "Inverted system"
              },
              {
                "value": "Multi-split system",
                "text": "Multi-split system"
              },
              {
                "value": "VRV system",
                "text": "VRV system"
              },
              {
                "value": "Gas heating",
                "text": "Gas heating"
              },
              {
                "value": "Other/not sure",
                "text": "Other/not sure"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
