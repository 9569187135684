const steps = {
  "2357": { //Computer Repairs
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "Which specific services do you need?",
            "jobDescriptionKey": "Services",
            "validation": {
              "groupName": "services",
              "message": "Tick all that apply."
            },
            "options": [
              {
                "value": "Computer/Laptop Hardware Problems",
                "text": "Computer/Laptop Hardware Problems"
              },
              {
                "value": "Computer/Laptop Software Problems",
                "text": "Computer/Laptop Software Problems"
              },
							{
                "value": "Networking Multiple Computers",
								"text": "Networking Multiple Computers"
							},
							{
								"value": "Data Backup or Recovery",
								"text": "Data Backup or Recovery"
							},
							{
								"value": "Internet Connection Problems",
								"text": "Internet Connection Problems"
							},
              {
								"value": "Virus Detection/Removal",
								"text": "Virus Detection/Removal"
							},
              {
								"value": "Other (please specify in the description)",
								"text": "Other (please specify in the description)"
							}
            ]
          }
        ]
      }
    ]
  }
}

export default steps
