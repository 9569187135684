import React from 'react'
import unseenMessagesActions from 'common/components/chat/data/unseen_conversation_store/actions'
import unseenConversationStore from 'common/components/chat/data/unseen_conversation_store/unseenConversationStore'


export default class UnseenConversationsCountNotification extends React.Component {
  constructor(props) {
    super(props)
    this.state = { unseenConversationCount: props.unseenConversationCount }
  }

  componentDidMount() {
    unseenMessagesActions.setCount(this.props.unseenConversationCount)
    unseenConversationStore.addChangeListener(this.onChange.bind(this))
  }

  componentWillUnmount() {
   unseenConversationStore.removeChangeListener(this.onChange.bind(this))
  }

  onChange() {
    this.setState({unseenConversationCount: unseenConversationStore.getCount()})
  }

  hasUnseenMessages() {
    return this.state.unseenConversationCount > 0
  }

  render() {
    return( this.hasUnseenMessages() && <span className='count-notification label'>{this.state.unseenConversationCount}</span> )
  }
}