import React from 'react'

import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'

import Budget from 'common/components/job_wizard/steps/Budget'
import MoreDetails from 'common/components/job_wizard/MoreDetails'
import SiteVisit from 'common/components/job_wizard/steps/SiteVisit'

const ContactPreference = {
  displayName: 'ContactPreference',

  onSubmit: function(onStepProgression) {
    onStepProgression()
  },

  buttonLayout: function() {
    return 'continueButton'
  },

  component: React.createClass({
    getInitialState: function() {
      return {
        contactPreference: data.contactPreference,
        quoteEstimate: data.quoteEstimate
      }
    },

    updateContactPreference: function(e) {
      let value = $(e.currentTarget).find('[type="radio"]')[0].value
      let contactPreference = (value === 'all') ? null : value

      actions.updateContactPreference(contactPreference)
      this.setState({ contactPreference: contactPreference })
    },

    componentDidMount: function() {
      this.setState({quoteEstimate: data.quoteEstimate})
    },

    showModalCloseButton: function() {
      $('#job-modal')
        .find('.modal-header')
        .find('.close')
        .rmHidden()
    },

    render: function() {
      let radioButtonVisible = "col-xs-2 col-sm-2 circle"
      let radioButtonHidden  = `${radioButtonVisible} hidden`

      return (
        <div className="contact-preference">
          <h3 className="mb8 mt24">Who should receive your job?</h3>
          <h4 className="mb20 text-copy-1 font-16"><i>We expect up to {this.state.quoteEstimate} businesses to call you about this job.</i></h4>
          <div className="form-group">
            <div className="radio-button-box minor-bold">
              <div className={`radio ${(this.state.contactPreference === null) ? "active" : ""}`} onClick={this.updateContactPreference}>
                <div>
                  <div className="text">
                    <strong>All businesses</strong>
                    <p className="mb0">Businesses that are interested in the job can call.</p>
                  </div>
                  <input type="radio" defaultChecked={(this.state.contactPreference === "all") ? 'checked' : ''}
                         id="contact-preference-all" name="contact-preference" value="all" className="form-control invisible" />
                </div>
              </div>
              <div className={`radio ${(this.state.contactPreference === "pro_and_top_rated") ? "active" : ""}`} onClick={this.updateContactPreference}>
                <div>
                  <div className="text">
                    <strong>Insured & Top Rated Businesses</strong>
                    <p className="mb0">We have a copy of their insurance or they have at least 4.5/5 Stars from 5 or more reviews</p>
                  </div>
                  <input type="radio" defaultChecked={(this.state.contactPreference === "pro_and_top_rated") ? 'checked' : ''}
                         id="contact-preference-pro-and-top-rated" name="contact-preference" value="pro_and_top_rated" className="form-control invisible" />
                </div>
              </div>
              <div className="mt16 mb30">
                <div className="text-copy-1">
                  <p><b>Did you know?</b> Customers who choose Insured or Top Rated here are up to 50% more likely to end up finding a business they love.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default ContactPreference
