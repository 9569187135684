import data from 'common/components/job_wizard/data/data'
import Flux from 'flux'
import API  from 'common/src/api'

// dispatcher
const Dispatcher = new Flux.Dispatcher()
const postingProgressData = {}

const store = {
  // actions
  track: false,
  updateTracking(val) {
    Dispatcher.dispatch({
      type: 'job:posting_progress:tracking_changed',
      value: val
    })
  },
  createPostingProgress() {
    Dispatcher.dispatch({
      type: 'job:posting_progress:create'
    })
  },
  storePostingProgress() {
    Dispatcher.dispatch({
      type: 'job:posting_progress:store'
    })
  },
  syncStoredPostingProgress() {
    Dispatcher.dispatch({
      type: 'job:posting_progress:sync'
    })
  }
}

// store
Dispatcher.register(
  function(payload) {
    switch (payload.type) {
      case 'job:posting_progress:tracking_changed':
        $.extend(postingProgressData, payload.value)
        console.log(JSON.stringify(postingProgressData))
        break;
      case 'job:posting_progress:create':
        if(!location.host.includes('admin.') && store.track === true) {
          API.V2.postAjax('jobs_posting_progress', jobPostingProgressDataMapper());
        }
        break;
      case 'job:posting_progress:store':
        if(!location.host.includes('admin.') && store.track === true && localStorage) {
          localStorage.setItem('jobs_posting_progress', jobPostingProgressDataMapper())
        }
        break;
      case 'job:posting_progress:sync':
        if (localStorage && localStorage.getItem('jobs_posting_progress')) {
          API.V2.postAjax('jobs_posting_progress', localStorage.getItem('jobs_posting_progress'), function() {
            localStorage.removeItem('jobs_posting_progress')
          })
        }
        break;
    }
  }
)

// ajax helper
function jobPostingProgressDataMapper() {
  return JSON.stringify(
    { data:
      { type: 'jobs-posting-progress',
        attributes: {
          'url':            postingProgressData.url,
          'source':         postingProgressData.source,
          'exit-type':      postingProgressData.exitType,
          'last-question':  postingProgressData.lastQuestion
        },
        relationships: {
          'job':      { data: { type: 'jobs',   id: postingProgressData.jobId }},
          'user':     { data: { type: 'users',  id: postingProgressData.userId }},
          'job-type': { data: { type: postingProgressData.categoryType, id: data.category_id }} //TODO this needs to change to data.category_type when category code gets updated @JM
         }
      }
    }
  )
}

export default store