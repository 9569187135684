import React from 'react'

const CheckboxInput = function(props) {
  return (
    <div className='form-group'>
      <div className='col-xs-6'>
        <label className='pt10 pb10'>{props.text}</label>
      </div>
      <div className='col-xs-6 pt10 pb10'>
        <input name={props.name} type='checkbox' className={props.customClass || 'form-control'} checked={props.value} onChange={props.onChangeHandler}/>
      </div>
    </div>
  )
}

export default CheckboxInput
