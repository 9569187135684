const steps = {
  "2702": { //Bridal Hair And Makeup
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you require a hair stylist & make-up artist?",
            "jobDescriptionKey": "Hair stylist and/or make-up artist",
            "validation": {
              "groupName": "hair_makeup",
              "message": "Please select a type of service needed.",
            },
            "options": [
              {
                "value": "Hair only",
                "text": "Hair only",
              },
              {
                "value": "Make-up only",
                "text": "Make-up only",
              },
              {
                "value": "Both Hair and Make-up",
                "text": "Both Hair and Make-up",
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "How many people need hair and/or make-up?",
            "jobDescriptionKey": "Number of people to be serviced",
            "validation": {
              "groupName": "number_of_people",
              "message": "Please select the number of people to be serviced."
            },
            "options": [
              {
                "value": "1 person",
                "text": "1 person"
              },
              {
                "value": "2 people",
                "text": "2 people"
              },
              {
                "value": "3 people",
                "text": "3 people"
              },
              {
                "value": "4+ people",
                "text": "4+ people"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you require a hair/make-up trial run?",
            "jobDescriptionKey": "Trial requirement",
            "validation": {
              "groupName": "trial_or_no_trial",
              "message": "Please select whether to require a trial or not."
            },
            "options": [
              {
                "value": "Trial run required",
                "text": "Trial run required"
              },
              {
                "value": "Trial run not required",
                "text": "Trial run not required"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
