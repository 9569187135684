import React from 'react'
import Spinner from 'common/components/Spinner'

const SubmitInput = function(props) {

  let buttonClass = props.buttonClass ? props.buttonClass : 'btn-secondary'

  function renderSubmitOrSpinner(){
    function submitButton() {
      return (
        <div className={`col-xs-12 ${props.additionalClasses}`}>
          <button type='button' className={`btn btn-default ${buttonClass}`} onClick={props.onClickHandler} disabled={props.isDisabled ? 'disabled' : false}>
            <i className={`${props.icon} mr10`}/>
            <span>{props.text}</span>
          </button>
        </div>
      )
    }

    function spinner() {
      return (
        <div className={`col-xs-12 ${props.additionalClasses}`}>
          <button type='button' className={`btn btn-default ${buttonClass}`} disabled>
            <span><Spinner /></span>
            <span className='ml5'>{props.spinnerText}</span>
          </button>
        </div>
      )
    }

    return props.submitting ? spinner() : submitButton()
  }

  return (
    <div className='mt10 form-group'>
      {renderSubmitOrSpinner()}
    </div>
  )
}

export default SubmitInput
