var o = $({});

$.subscribe = function() {
    o.on.apply(o, arguments);
}

$.publish = function() {
    o.trigger.apply(o, arguments);
};

$.fn.extend({
  addHidden() {
    return this.addClass('hidden');
  },

  rmHidden: function() {
    return this.removeClass('hidden');
  }
})
