import React from 'react'

import CreditCardDownForMaintenance from 'common/components/credit_card/CreditCardDownForMaintenance'
import CreditCardForm from 'common/components/credit_card/CreditCardForm'

const CreditCardContainer = React.createClass({
 disclaimer: function() {
    return(
      <div className="bg-white-smoke mb32">
        <div className="row">
          <div className="col-xs-12 p32">
            <div className="font-14 text-gray">
              {this.props.disclaimer}
            </div>
          </div>
        </div>
      </div>
    )
  },

  render: function() {
    return(
      window.App.payment_gateway_down_for_maintenance ?
      <CreditCardDownForMaintenance />
      :
      <div>
        <div className="bg-white p16">
          <div className="row">
            <div className="col-xs-5">
              <div className="font-16 bold card-detail-header">Card Details</div>
            </div>
            <div className="col-xs-7">
              <div className="cc-card-icon pull-right cc-type">
                <img src="/assets/cc/amex.svg" />
                <img src="/assets/cc/mastercard.svg" />
                <img src="/assets/cc/visa.svg" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white-smoke p16">
          <div className="row">
            <div className="col-xs-12">
              <div className="font-14 text-gray cc-text-icon">
                <span className="cc-lock-icon ficon-SSL" /> This is a secure 128-bit SSL encrypted payment. You're safe.
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p16">
          <div className={'row pl16 pr16 ' + (this.props.disable ? `disabled`:``)}>
            <CreditCardForm
              action={this.props.action}
              formId={this.props.formId}
              hiddenFields={this.props.hiddenFields}
              submitButton={this.props.submitButton}
              disclaimer={this.props.disclaimer && this.disclaimer()}
              withExistingCard={this.props.withExistingCard}
              ccNumber={this.props.ccNumber}
              submitting={this.props.submitting}
            />
          </div>
        </div>
      </div> //wrapping div
    )
  }
})

export default CreditCardContainer
