const steps = {
  "142": { // Fence Installation
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "How long will the fence be?",
            "jobDescriptionKey": "Fence length",
            "validation": {
              "groupName": "fence_length",
              "message": "Please specify the length of the fence."
            },
            "options": [
              {
                "value": "Small (less than 20m)",
                "text": "Small (less than 20m)"
              },
              {
                "value": "Medium (20m-50m)",
                "text": "Medium (20m-50m)"
              },
              {
                "value": "Large (50m-100m)",
                "text": "Large (50m-100m)"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you need any gates?",
            "jobDescriptionKey": "Gates",
            "validation": {
              "groupName": "gates",
              "message": "Please specify if you need any gates."
            },
            "options": [
              {
                "value": "No gates needed",
                "text": "No gates needed"
              },
              {
                "value": "1 gate needed",
                "text": "1 gate needed"
              },
              {
                "value": "2 gates needed",
                "text": "2 gates needed"
              },
              {
                "value": "3+ gates needed",
                "text": "3+ gates needed"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "What type of fencing is needed?",
            "jobDescriptionKey": "Type of fencing",
            "validation": {
              "groupName": "type_of_fencing",
              "message": "Please specify the type of fencing needed."
            },
            "options": [
              {
                "value": "Property fencing",
                "text": "Property fencing"
              },
              {
                "value": "Pool fencing",
                "text": "Pool fencing"
              },
              {
                "value": "Balcony fencing",
                "text": "Balcony fencing"
              },
              {
                "value": "Rural fencing",
                "text": "Rural fencing"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "What materials should be used?",
            "jobDescriptionKey": "Materials",
            "validation": {
              "groupName": "materials",
              "message": "Please specify the materials to be used."
            },
            "options": [
              {
                "value": "Timber/Picket",
                "text": "Timber/Picket"
              },
              {
                "value": "Colorbond",
                "text": "Colorbond"
              },
              {
                "value": "Steel",
                "text": "Steel"
              },
              {
                "value": "Glass",
                "text": "Glass"
              },
              {
                "value": "Mesh",
                "text": "Mesh"
              },
              {
                "value": "Wire",
                "text": "Wire"
              },
              {
                "value": "Electric",
                "text": "Electric"
              },
              {
                "value": "Other",
                "text": "Other"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
