import React from 'react'

const Switch = function(props) {
  let uniqueKey = `label-switch-${Math.round(Math.random() * 10000)}`

  return (
    <div className="switch">
      <input id={uniqueKey} type="checkbox" checked={props.enabled} onChange={props.changeHandler} />
      <label htmlFor={uniqueKey} className="label-switch"></label>
    </div>
  )
}

export default Switch
