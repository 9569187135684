import React from 'react'

import Eula from 'common/components/job_wizard/Eula'
import ErrorMessage from 'common/components/ErrorMessage'

class CreateAccount extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.setupValidation()
  }

  setupValidation() {
    $('#job-modal').bootstrapValidator({ /* */ })
    const validator = $('#job-modal').data('bootstrapValidator')

    validator.addField('first_name',
        {
            validators: {
            notEmpty: {
                message: 'Please enter your first name'
            }
            }
        }
    )

    validator.addField('contact_phone',
        {
            validators: {
            notEmpty: {
                message: 'Please enter your contact number'
            }
            }
        }
    )

}

  render() {
    return (
      <div className="create-account">
        <h3 className="mb15 mt20">First Name & Contact Number</h3>
        <ErrorMessage />
        <div className="form-group">
          <input type="text" className="form-control" name="first_name" id="first-name" placeholder="First Name" />
        </div>
        <div className="form-group">
          <input type="text" className="form-control" name="contact_phone" id="contact-phone" placeholder="Contact Number" />
        </div>
        { Eula.renderApp({ marginTop: '40px', marginBottom: '40px' }) }
      </div>
    )
  }
}

export default CreateAccount
