const steps = {
  "578": { //Concreting
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Checkbox",
            "label": "What type of concreting job is this?",
            "jobDescriptionKey": "Job Type",
            "validation": {
              "groupName": "job_type",
              "message": "Please select a type of concreting job."
            },
            "options": [
              {
                "value": "Concrete Driveway",
                "text": "Concrete Driveway"
              },
              {
                "value": "Concrete Slab/s",
                "text": "Concrete Slab/s"
              },
              {
                "value": "Concrete Foundations",
                "text": "Concrete Foundations"
              },
              {
                "value": "Concrete Stairs",
                "text": "Concrete Stairs"
              },
              {
                "value": "Concrete Cleaning/Sealing",
                "text": "Concrete Cleaning/Sealing"
              },
              {
                "value": "Polished Concrete Floors",
                "text": "Polished Concrete Floors"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Can a truck access the site directly?",
            "jobDescriptionKey": "Site accessibility",
            "validation": {
              "groupName": "site_accessibility",
              "message": "Please select an option that apply"
            },
            "options": [
              {
                "value": "Yes, a truck can access the site",
                "text": "Yes, a truck can access the site"
              },
              {
                "value": "No, a truck can't access the site directly",
                "text": "No, a truck can't access the site directly"
              }
            ]
          },
        ]
      }
    ]
  }
}

export default steps
