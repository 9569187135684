import React from 'react'
import Select from 'common/components/Select'

const SelectInput = function(props) {
  return (
    <div className='form-group'>
      <div className='col-xs-6'>
        <label className='pt10 pb10'>{props.label}</label>
      </div>
      <div className='col-xs-6'>
        <Select
          options={props.options}
          name={props.name}
          onChangeHandler={props.onChangeHandler}
          defaultValue={props.defaultValue}
          readOnly
        />
      </div>
    </div>
  )
}

export default SelectInput
