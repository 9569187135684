import React from 'react'

import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import store from 'common/components/job_wizard/data/store'
import postingProgressStore from 'common/components/job_wizard/data/posting_progress/store'

import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'

import Header from 'common/components/job_wizard/Header'
import NavigationButtons from 'common/components/NavigationButtons'
import LocationInput from 'common/components/LocationInput'

import CheckBoxRow from 'common/components/CheckBoxRow'
import RadioButtonRow from 'common/components/RadioButtonRow'

import coreUtils from 'common/src/utils/coreUtils'

import Budget from 'common/components/job_wizard/steps/Budget'
import SiteVisit from 'common/components/job_wizard/steps/SiteVisit'

const CategoryBasedSteps = {

  generate(categoryId) {

    function questionFactory(questions) {
      const componentTypesMapper = {
        'Checkbox': Checkbox,
        'Radio': Radio,
        'SuburbInput': SuburbInput,
        'TextArea': TextArea
      }

      const commonUtilFunctions = {
        retestValidationsToRemoveErrorsOn(clickedElement) {
          let fieldName = clickedElement.parentNode.getElementsByTagName('input')[0].getAttribute('name');
          validator().$form.data('bootstrapValidator').revalidateField(fieldName)
        }
      }

      const commonProps = {
        bindValidations(groupName, message) {
          validator().addField( groupName, { validators: { notEmpty: { message: message } } } )
        },

        handleOnClick(e) {
          commonUtilFunctions.retestValidationsToRemoveErrorsOn(e.target)
        },

        isChecked(text) {
          return data.extraDetails.indexOf(text) > -1
        },

        getStoredExtraDetail: function(key) {
          return store.getExtraDetailsValue(key)
        }
      }

      function createQuestions() {
        function questionProps(question) {
          return $.extend(question, commonProps, { key: question.label })
        }

        function inputComponentType(question) {
          return componentTypesMapper[question.type]
        }

        return questions.map(
          function (question) { return React.createElement( inputComponentType(question), questionProps(question) ) }
        )
      }

      return createQuestions()
    }

    let customStepFactory = function(subcategoryId){
      let steps = data.subcategoryQuestions[subcategoryId].steps

      function createSteps() {
        return steps.map(
          function(step) {
            // TODO update this to use BasicStep instead of CategoryStep (make sure jobs_posting_progress still functions)
            return <CategoryStep title={step.title} questions={questionFactory(step.questions)} isBasicStep={true} />
          }
        )
      }

      return createSteps()
    }

    function CategoryStep(props) {
      function advanceToNextStep(){
        JobWizardEventEmitter.emit('job:wizard:advanceToNextStep')
        JobWizardEventEmitter.emit('job:wizard:category:step:continueButtonClicked')
      }

      function retreatToPreviousStep() {
        JobWizardEventEmitter.emit('job:wizard:retreatToPreviousStep')
      }

      function handleClick(e) {
        let text = $(e.target).parents('.form-group').last().find('h3').text()
        postingProgressStore.updateTracking({lastQuestion: text})
      }

      return(
        <div className="job-modal-step">
          <Header title={props.title} />
          <div className="modal-body job-form-body" onClick={handleClick}>
            { props.questions.map( (question) => question) }
          </div>
          <div className="modal-footer">
            <NavigationButtons
              navButtonLayout={'default'}
              onStepProgression={advanceToNextStep}
              onStepRetreat={retreatToPreviousStep}
            />
          </div>
        </div>
      )
    }

    return customStepFactory( categoryId ).map( function(step) { return step })
  },

  validator() {
    const $jobModal = $('#job-modal')
    $jobModal.bootstrapValidator({ /* */ })
    return $jobModal.data('bootstrapValidator')
  }
}

const SuburbInput = React.createClass({
  getInitialState: function() {
    return {
      suburbName: this.props.getStoredExtraDetail(this.props.jobDescriptionKey)
    }
  },

  componentDidMount: function() {
    CategoryBasedSteps.validator().addField(
      'location',
      {
        threshold: 99999,
        validators: {
          callback: {
            callback: function(fieldValue, validator) {
              return fieldValue !== ''
            },
            message: 'Please enter your Postcode or Suburb and select the matching one from the drop-down menu'
          }
        }
      }
    )
  },

  handleAutocompleteSelection: function( event, response ) {
    actions.addExtraDetails({[this.props.jobDescriptionKey]: response.item.value})
  },

  render() {
    return(
      <div className="form-group">
        <h3 className="mb15 mt10">{this.props.label}</h3><br/>
        <div className="minor-bold">
          <LocationInput
            suburb={this.state.suburbName}
            handleAutocompleteSelection={this.handleAutocompleteSelection}
            handleLocationChange={this.handleLocationChange}
            guId={coreUtils.generateGuid()}
          />
        </div>
      </div>
    )
  }
})


const TextArea = React.createClass({

  getInitialState: function() {
    return {
      description: this.props.getStoredExtraDetail(this.props.jobDescriptionKey)
    }
  },

  componentWillMount: function() {
    JobWizardEventEmitter.addListener('job:wizard:category:step:continueButtonClicked', this.advanceStep)
  },

  componentDidMount: function() {
    this.setupValidation()
  },

  componentWillUnmount: function() {
    JobWizardEventEmitter.removeListener('job:wizard:category:step:continueButtonClicked', this.advanceStep)
  },

  MIN_TEXT_LENGTH: function() { return this.props.validation.minTextLength },

  advanceStep: function() {
    actions.addExtraDetails({[this.props.jobDescriptionKey]: this.textArea.value})
  },

  setupValidation: function(minTextLength = this.MIN_TEXT_LENGTH()) {
      CategoryBasedSteps.validator().addField(
         this.props.validation.groupName, {
          trigger: 'blur',
          validators: {
            notEmpty: {
              message: this.props.validation.message
            },
            stringLength: {
                min: minTextLength,
                message: `Required. Please type at least ${this.MIN_TEXT_LENGTH()} characters.`
            }
          }
        }
      )
  },

  revalidateIfMinTextLengthReached: function(e) {
    let revalidateDescriptionField = () => { //revalidating removes error message once char limit is reached
      CategoryBasedSteps.validator().$form.data('bootstrapValidator').revalidateField(this.props.validation.groupName)
    }

    if(e.target.value.length >= this.MIN_TEXT_LENGTH()) {
      revalidateDescriptionField()
    }
  },

  render: function() {
    return(
      <div className="form-group">
        <h3 className="mt10">{this.props.label}</h3>
        <small className="mb15">{this.props.options[0].text}</small>
        <textarea
          ref={(input) => { this.textArea = input }}
          autoFocus
          className="component-block p16 mt15 h120 form-control"
          id={this.props.validation.groupName}
          name={this.props.validation.groupName}
          rows="7"
          placeholder={this.props.validation.message}
          defaultValue={this.state.description}
          onChange={this.descriptionChanged}
          onKeyUp={this.revalidateIfMinTextLengthReached}
        />
      </div>
    )
  }
})


const Checkbox = React.createClass({
    componentDidMount: function() {
      $('.check-box-container').checkBoxGroup({activeRowClass: 'active'}) //attach click listeners
      this.props.bindValidations(this.props.validation.groupName, this.props.validation.message)
    },

    handleOnClick: function(e) {
      var groupName = `input[name="${this.props.validation.groupName}"]`

      var checkedValues = $(groupName).filter(':checked').map(function() { return this.value }).get()
      var $checkBox = $(e.target).closest('.check-box')
      var $checkedSubcategory = $checkBox.find(groupName)
      var subcategory = {id: $checkedSubcategory.data('subcategory-id'), values: $checkedSubcategory.val()}
      var action = $checkBox.hasClass('active') ? 'add' : 'remove'

      actions.addExtraDetails({[this.props.jobDescriptionKey]: checkedValues})

      if (subcategory.id) {
        actions.updateSubCategory(subcategory, action, 'additional')
      }

      this.props.handleOnClick(e)
    },

    isChecked(text) {
      return this.props.getStoredExtraDetail(this.props.jobDescriptionKey) && data.extraDetails[this.props.jobDescriptionKey].indexOf(text) > -1
    },

    render() {
      return(
        <div className="form-group">
          <h3 className="mt10">{this.props.label}</h3><br/>
          <div className="check-box-container minor-bold" onClick={this.handleOnClick}>
            {
              this.props.options.map((input) =>
                <CheckBoxRow
                  key={input.value}
                  name={this.props.validation.groupName}
                  text={input.text}
                  id={input.value}
                  value={input.value}
                  data-subcategory-id={input.subcategoryId}
                  isChecked={this.isChecked(input.value)}
                />
              )
            }
          </div>
        </div>
      )
    }
})

const Radio = React.createClass({
  componentDidMount: function() {
    $('.radio-button-box-container').radioButtonGroup({activeRowClass: 'active'})
    this.props.bindValidations(this.props.validation.groupName, this.props.validation.message)
  },

  handleOnClick: function(e) {
    var groupName = `input[name="${this.props.validation.groupName}"]`

    var selectedValues = $(groupName).filter(':checked').map(function() { return this.value }).get()
    var $radioBox = $(e.target).closest('.radio')
    var $selectedSubcategory = $radioBox.find(groupName)
    var subcategory = {id: $selectedSubcategory.data('subcategory-id'), values: $selectedSubcategory.val()}
    var action = $radioBox.hasClass('active') ? 'add' : 'remove'

    actions.addExtraDetails({[this.props.jobDescriptionKey]: selectedValues})

    if (subcategory.id) {
      actions.updateSubCategory(subcategory, action, 'additional')
    }

    this.props.handleOnClick(e)

    JobWizardEventEmitter.emit('job:wizard:advanceToNextStep')
  },

  isChecked(text) {
    return this.props.getStoredExtraDetail(this.props.jobDescriptionKey) === text
  },

  render() {
    return(
      <div className="form-group">
        <h3 className="mt10">{this.props.label}</h3><br/>
        <div className="radio-button-box-container minor-bold" onClick={this.handleOnClick}>
          <div className="form-group">
            {
              this.props.options.map((input) =>
                <RadioButtonRow
                  key={input.value}
                  name={this.props.validation.groupName}
                  text={input.text}
                  value={input.value}
                  isChecked={this.isChecked(input.value)}
                />
              )
            }
          </div>
        </div>
      </div>
    )
  }
})
// used for testing purposes see app/views/jobs/full_page_wizard.slim to uncomment last line
// SS.React.JobWizard.CategorySteps = function(){
// return (<div>{customStepFactory( '168' ).map( function(step) { return step })}</div>)

export default CategoryBasedSteps