const steps = {
	"2661": { //Tinting and films
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
						"label": "Is this a residential or commercial job?",
						"jobDescriptionKey": "Residential or Commercial",
            "validation": {
              "groupName": "residential_or_commercial",
              "message": "Please choose whether residential or commercial",
            },
            "options": [
              {
                "value": "Residential",
                "text": "Residential",
              },
              {
                "value": "Commercial",
                "text": "Commercial",
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
						"label": "Where do you need tinting?",
						"jobDescriptionKey": "Tinting location",
            "validation": {
              "groupName": "tinting_location",
              "message": "Please select a tinting location."
            },
            "options": [
              {
                "value": "Apartment",
                "text": "Apartment"
              },
              {
                "value": "Home",
                "text": "Home"
              },
							{
                "value": "Office",
                "text": "Office"
              },
							{
                "value": "Shop",
                "text": "Shop"
              },
							{
                "value": "Car",
                "text": "Car"
              },
							{
                "value": "Boat",
                "text": "Boat"
              },
              {
                "value": "Other (please specify in the description)",
                "text": "Other (please specify in the description)"
              }
            ]
          }
        ]
      },
			{
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
						"label": "What type of tinting do you need?",
						"jobDescriptionKey": "Tinting type",
            "validation": {
              "groupName": "tinting_type",
              "message": "Please select a tinting type."
            },
            "options": [
              {
                "value": "Frosting/Privacy films",
                "text": "Frosting/Privacy films"
              },
							{
                "value": "Solar film/shade tinting",
                "text": "Solar film/shade tinting"
              },
							{
                "value": "Safety/Security tinting",
                "text": "Safety/Security tinting"
              },
							{
								"value": "Anti-graffiti film",
                "text": "Anti-graffiti film"
              },
              {
                "value": "Other (please specify in the description)",
                "text": "Other (please specify in the description)"
              }
            ]
          }
        ]
      },
			{
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
						"label": "How many windows need tinting?",
						"jobDescriptionKey": "Window tinting count",
            "validation": {
              "groupName": "window_tinting_count",
              "message": "Please select a window count."
            },
            "options": [
              {
                "value": "1",
                "text": "1"
              },
              {
                "value": "2",
                "text": "2"
              },
							{
                "value": "3",
                "text": "3"
              },
							{
                "value": "4",
                "text": "4"
              },
							{
								"value": "Other (please specify in the description)",
								"text": "Other (please specify in the description)"
							}
            ]
          }
        ]
      }
    ]
  }
}

export default steps
