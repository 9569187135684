import React, { Component } from 'react'
import { Modal } from 'flowbite'

class SharedModal extends Component {
  constructor(props) {
    super(props)
    this.modalInstance = null
  }

  initializeModal() {
    const { id } = this.props
    const modalElement = document.getElementById(id)
    const { bgColor, parentContainer } = this.props
    const parentContainerEl = document.querySelector(`#${parentContainer} .bg-container`)

    const modalOptions = {
      placement: 'center-center',
      backdrop: 'static',
      closable: false,
      onShow: () => {
        if (parentContainer) {
          parentContainerEl.classList.add('bg-transparent')
          parentContainerEl.classList.remove(bgColor || 'bg-white')
        }
      },
      onHide: () => {
        if (parentContainer) {
          parentContainerEl.classList.remove('bg-transparent')
          parentContainerEl.classList.add(bgColor || 'bg-white')
        }
      },
    }

    this.modalInstance = new Modal(modalElement, modalOptions)
  }

  componentDidMount() {
    this.initializeModal()
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props

    if (prevProps.isOpen !== isOpen) {
      this.handleModalVisibility()
    }
  }

  componentWillUnmount() {
    if (this.modalInstance) {
      this.modalInstance.hide()
    }
  }

  handleModalVisibility() {
    const { isOpen } = this.props

    if (isOpen) {
      this.modalInstance.show()
    } else {
      this.modalInstance.hide()
    }
  }

  render() {
    const { id, bgColor, children, maxWidth } = this.props
    return (
      <div id={id} data-modal-backdrop='static' tabIndex='-1' aria-hidden='true' className='shared-modal fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-full p-4'>
        <div className={`relative w-full ${maxWidth || 'max-w-2xl'} max-h-full`}>
          <div className={`relative ${bgColor || 'bg-white'} bg-container rounded-lg md:shadow p-3 md:p-7`}>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

export default SharedModal
