import React from 'react'

const StarRating = function(props) {
  function stars() {
    let stars = []
    let keyIndex = 0
    let wholeStarCount = parseInt(props.rating, 10)
    let halfStarCount = (props.rating - wholeStarCount) === 0 ? 0 : 1
    let emptyStarCount = (5 - wholeStarCount - halfStarCount )

    for(var i = 0; i < wholeStarCount; i++) {
      keyIndex ++
      stars.push(<i className="ficon-star-full text-components-stars-yellow" key={keyIndex}/>)
    }
    for(var i = 0; i < halfStarCount; i++) {
      keyIndex ++
      stars.push(<i className="ficon-star-half text-components-stars-yellow" key={keyIndex}/>)
    }
    for(var i = 0; i < emptyStarCount; i++) {
      keyIndex ++
      stars.push(<i className="ficon-star-empty text-components-stars-yellow" key={keyIndex}/>)
    }
    return stars
  }

  return (
    <span className='inline-flex items-center space-x-1'>
      {
        !props.starsOnly &&
        <p className={props.fontOptions || 'text-sm'}>{props.rating}</p>
      }
      {stars()}
    </span>
  )
}

export default StarRating
