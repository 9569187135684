import React from 'react'

import API from 'common/src/api'

import Fragment from 'react-dot-fragment'
import { Modal } from 'flowbite'

class PricingSurvey extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStep: 0,
      formData: {
        gst_included: true,
        price: '',
        unit: '',
        pricing_survey_answered: ''
      },
      submitting: false
    }
  }

  componentDidMount() {
    const $targetEl = document.getElementById('pricing-survey-modal')

    const options = {
      placement: 'center-center',
      backdrop: 'static',
      closable: false
    }

    const instanceOptions = {
      id: 'pricing-survey-modal',
      override: true
    }

    const modal = new Modal($targetEl, options, instanceOptions)

    const leadsRegex = /^(\/v2)?\/leads\/?(\?.*)?$/

    if (leadsRegex.test(window.location.pathname)) {
      modal.show()
    }
  }

  nextStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }))
  }

  prevStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }))
  }

  handleChange = (event) => {
    const { name, value, type, checked } = event.target

    let formattedValue = ['true', 'false'].includes(value) ? JSON.parse(value) : value

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: type === 'checkbox' ? checked : formattedValue,
      }
    }))
  }

  submit = () => {
    const { formData } = this.state

    let businessPricingCreate = API.V3.businessPricingCreate(formData)

    businessPricingCreate.beforeSend = () => {
      this.setState({ submitting: true })
    }

    $.ajax(businessPricingCreate)
      .done((response) => {
        this.setState({ currentStep: this.steps().length - 1, submitting: false })
      })
      .fail((error) => {
        document.location.href = '/500'
      })
  }

  getUnitLabel = () => {
    return this.props.unitLabels[this.state.formData.unit]
  }

  steps = () => {
    const { formData } = this.state
    const steps = [
      {
        component: () => (
          <div>
            <h1 className='font-bold text-xl text-center mb-7'>Do you have a standard rate?</h1>
            <div className='flex items-center gap-x-3 mb-6'>
              <input type='radio' id='pricing_survey_answered_yes' name='pricing_survey_answered' value={true} checked={formData.pricing_survey_answered ? 'checked' : ''} onChange={(e) => this.handleChange(e)} className='radio-btn' />
              <label htmlFor='pricing_survey_answered_yes' className='block text-xl leading-6'>Yes</label>
            </div>
            <div className='flex items-center gap-x-3 mb-6'>
              <input type='radio' id='pricing_survey_answered_no' name='pricing_survey_answered' value={false} checked={!formData.pricing_survey_answered && formData.pricing_survey_answered !== ''  ? 'checked' : ''} onChange={(e) => this.handleChange(e)} className='radio-btn' />
              <label htmlFor='pricing_survey_answered_no' className='block text-xl leading-6'>No</label>
            </div>
          </div>
        )
      },
      {
        component: () => (
          <div>
            <h1 className='font-bold text-xl text-center mb-7'>How do you set your rates?</h1>
            <div className='flex items-center gap-x-3 mb-6'>
              <input type='radio' id='unit_hourly' name='unit' value='hourly' checked={formData.unit === 'hourly' ? 'checked' : ''} onChange={(e) => this.handleChange(e)} className='radio-btn' />
              <label htmlFor='unit_hourly' className='block text-xl leading-6'>Hourly</label>
            </div>
            <div className='flex items-center gap-x-3 mb-6'>
              <input type='radio' id='unit_daily' name='unit' value='daily' checked={formData.unit === 'daily' ? 'checked' : ''} onChange={(e) => this.handleChange(e)} className='radio-btn' />
              <label htmlFor='unit_daily' className='block text-xl leading-6'>Daily</label>
            </div>
            <div className='flex items-center gap-x-3 mb-6'>
              <input type='radio' id='unit_lineal_rate' name='unit' value='lineal_rate' checked={formData.unit === 'lineal_rate' ? 'checked' : ''} onChange={(e) => this.handleChange(e)} className='radio-btn' />
              <label htmlFor='unit_lineal_rate' className='block text-xl leading-6'>Per Lineal Rate</label>
            </div>
            <div className='flex items-center gap-x-3 mb-6'>
              <input type='radio' id='unit_square_metre' name='unit' value='square_metre' checked={formData.unit === 'square_metre' ? 'checked' : ''} onChange={(e) => this.handleChange(e)} className='radio-btn' />
              <label htmlFor='unit_square_metre' className='block text-xl leading-6'>Per Square Metre</label>
            </div>
            <div className='flex items-center gap-x-3 mb-6'>
              <input type='radio' id='unit_cubic_metre' name='unit' value='cubic_metre' checked={formData.unit === 'cubic_metre' ? 'checked' : ''} onChange={(e) => this.handleChange(e)} className='radio-btn' />
              <label htmlFor='unit_cubic_metre' className='block text-xl leading-6'>Per Cubic Metre</label>
            </div>
          </div>
        )
      },
      {
        component: () => (
          <div>
            <h1 className='font-bold text-xl text-center mb-7'>Specify your minimum price</h1>
            <div className='flex mb-7 space-x-2 items-center'>
              <div className='text-xl text-light-gray'>From</div>
              <div className='relative'>
                <div className='absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none'>
                  <svg className='w-4 h-4' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 11 20'>
                    <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M1.75 15.363a4.954 4.954 0 0 0 2.638 1.574c2.345.572 4.653-.434 5.155-2.247.502-1.813-1.313-3.79-3.657-4.364-2.344-.574-4.16-2.551-3.658-4.364.502-1.813 2.81-2.818 5.155-2.246A4.97 4.97 0 0 1 10 5.264M6 17.097v1.82m0-17.5v2.138'/>
                  </svg>
                </div>
                <input type='number' name='price' id='price' className='input-text rounded-xl text-lg pl-10 p-2.5 w-auto price-survey-input' onChange={(e) => this.handleChange(e)} value={formData.price} />
              </div>
              <div className='text-lg'>
                / {this.getUnitLabel()}
              </div>
            </div>
            <div className='flex space-x-4 items-center'>
              <div className='flex items-center gap-x-3 mb-6'>
                <input type='radio' id='gst_included_yes' name='gst_included' value={true} checked={formData.gst_included ? 'checked' : ''} onChange={(e) => this.handleChange(e)} className='radio-btn' />
                <label htmlFor='gst_included_yes' className='block text-xl leading-6'>Inc. GST</label>
              </div>
              <div className='flex items-center gap-x-3 mb-6'>
                <input type='radio' id='gst_included_no' name='gst_included' value={false} checked={!formData.gst_included ? 'checked' : ''} onChange={(e) => this.handleChange(e)} className='radio-btn' />
                <label htmlFor='gst_included_no' className='block text-xl leading-6'>Ex. GST</label>
              </div>
            </div>
          </div>
        )
      },
      {
        component: () => (
          <div className='flex flex-col justify-center items-center bg-white text-center'>
            <h1 className='font-bold text-xl mb-7'>Thank You!</h1>
            <div className='mb-7'>
              <hero-icon-solid name='check-circle' width='64' height='64' class='text-secondary'></hero-icon-solid>
            </div>
            {
              !formData.pricing_survey_answered ?
              <div className='mt-7'>
                <div className='mb-5'>
                  Thank you for answering the price survey.
                </div>
                To edit your price, please go to your business profile.
              </div>
              :
              <div className='mt-7'>
                <div className='mb-5'>
                  Your standard price of ${formData.price} per {this.getUnitLabel()} has been set. We show pricing on your Business Profile and use it to analyse pricing in your industry.
                </div>
                To edit your price, please go to your business profile.
              </div>
            }
          </div>
        )
      }
    ]

    return steps
  }

  render() {
    const { currentStep, formData, submitting } = this.state

    const isNextButtonDisabled = (() => {
      switch (currentStep) {
        case 0:
          return formData.pricing_survey_answered === ''
        case 1:
          return !formData.unit
        default:
          return false
      }
    })()

    const isSubmitButtonDisabled = (() => {
      if (currentStep === 2) {
        return !this.state.formData.price
      }
    })()

    const steps = this.steps()
    const currentStepComponent = steps[currentStep]
    const submitButtonText = submitting ? 'Submitting...' : 'Submit'

    return (
      <div id='pricing-survey-modal' data-modal-backdrop='static' tabIndex='-1' aria-hidden='true' className='shared-modal fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-full p-4'>
        <div className='relative p-4 w-full max-w-md max-h-full'>
          <div className='relative bg-white rounded-lg md:shadow p-1 md:p-7'>
            <div id='modal-title' className='mb-4'>
              <h1 className='text-center font-bold text-xl text-secondary'>Pricing Survey</h1>
            </div>
            <div id='modal-body'>
              {currentStepComponent.component()}
            </div>
            <div className='nav-buttons flex flex-row space-x-6 justify-end mt-7'>
              {
                (currentStep === steps.length - 1) ?
                <a href='/my-profile' className='btn-inline-block btn-secondary text-xl'>Close</a>
                :
                <Fragment>
                  {
                    (currentStep > 0) &&
                    <button
                      className='btn-inline-block btn-secondary text-xl'
                      onClick={this.prevStep}
                    >
                      Previous
                    </button>
                  }
                  {
                    (currentStep < steps.length && currentStep !== 2) &&
                    <button className='btn-inline-block btn-secondary text-xl' onClick={!this.state.formData.pricing_survey_answered ? this.submit : this.nextStep} disabled={isNextButtonDisabled}>Next</button>
                  }
                  {
                    (currentStep === 2) &&
                    <button className='btn-inline-block btn-secondary text-xl' onClick={this.submit} disabled={isSubmitButtonDisabled || submitting}>{submitButtonText}</button>
                  }
                </Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PricingSurvey
