import React, { Component } from "react";
import Spinner from "common/components/Spinner";

const styles = {
  input: {
    color: "#124a9e",
    "font-family": "system-ui, -apple-system, BlinkMacSystemFont, sans-serif",
    "letter-spacing": "0.02em",
  },
  ".invalid": {
    color: "#cc0001",
  },
  "::placeholder": {
    color: "#757575",
  },
  ".invalid .card-icon": {
    color: "#cc0001",
  },
};

export default class HostedFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      useExistingCreditCard: !!this.props.ccNumber,
      isUpdatingCreditCard: false,
    };
  }

  toggleCreditCardInput = (e) => {
    e.preventDefault();

    this.setState({
      useExistingCreditCard: !this.state.useExistingCreditCard,
      isUpdatingCreditCard: !this.state.isUpdatingCreditCard,
    });
  };

  componentDidMount() {
    let hostedFields = assembly.hostedFields({
      environment: this.props.environment || "pre-live",
    });
    let cardName = hostedFields.create("cardName", {
      placeholder: "Full Name",
      styles: styles,
    });
    let cardNumber = hostedFields.create("cardNumber", {
      placeholder: "•••• •••• •••• ••••",
      styles: styles,
    });
    let cardExpiry = hostedFields.create("cardExpiry", {
      placeholder: "MM/YY",
      styles: styles,
    });
    let cardCvv = hostedFields.create("cardCvv", {
      placeholder: "•••",
      styles: styles,
    });

    cardName.mount("#card-name-field");
    cardNumber.mount("#card-number-field");
    cardExpiry.mount("#card-expiry-field");
    cardCvv.mount("#card-cvv-field");

    let inputs = [cardName, cardNumber, cardExpiry, cardCvv];
    let form = document.querySelector(".cc-hosted-fields .form");
    let submitButton = form.querySelector(".button");
    let responseContainer = document.querySelector(
      ".cc-hosted-fields .response"
    );

    inputs.forEach((field) => {
      field.on("change", (event) => {
        let errorElement = document.querySelector(
          "." + event.fieldType + "-container .error"
        );
        this.toggleError(errorElement, event);
      });
    });

    form.addEventListener("submit", (event) => {
      event.preventDefault();
      submitButton.disabled = true;
      submitButton.classList.add("button-loading");

      hostedFields
        .createCardAccount({
          token: this.props.hostedFieldsToken,
          user_id: this.props.hostedFieldsUserId,
        })
        .then((response) => {
          this.resetSubmitButton(submitButton);
          // handle create card account succeeded
          // responseContainer.classList.add("response-success");
          // responseContainer.innerText = "Card account successfully created.";
          this.resetForm(inputs);
          this.props.callback(response.card_accounts.id);
        })
        .catch((response) => {
          this.resetSubmitButton(submitButton);
          // handle errors
          responseContainer.classList.add("response-error");
          if (response.errors && response.errors.token) {
            responseContainer.innerText =
              "Your token is not authorized.\rPlease try again.";
          } else {
            console.log(response);

            responseContainer.innerText =
              "There was an error creating your card account.";
          }
        });
    });
  }

  toggleError(element, event) {
    if (event.error) {
      element.innerText = event.error.message;
      element.classList.add("visible");
    } else {
      element.innerText = "";
      element.classList.remove("visible");
    }
  }

  resetSubmitButton(submitButton) {
    submitButton.disabled = false;
    submitButton.classList.remove("button-loading");
  }

  resetForm(inputs) {
    inputs.forEach(function (input) {
      input.clear();
    });
  }

  render() {
    return (
      <div>
        {this.state.useExistingCreditCard ? (
          <div className="existing-card-container">
            <form
              id="credit-card-form"
              action={this.props.action}
              acceptCharset="UTF-8"
              method="post"
              noValidate="novalidate"
              className="bv-form"
            >
              {this.props.hiddenFields}
              <h5 className="text-gray">Use this card</h5>
              <div className="fs28 mb16 text-copy-1">{this.props.ccNumber}</div>
              <div>
                <a
                  href="#"
                  className={`btn btn-xs btn-clear ${
                    this.props.submitting ? "disabled" : ""
                  }`}
                  onClick={this.toggleCreditCardInput}
                >
                  {this.state.isUpdatingCreditCard
                    ? "Cancel Update"
                    : "Update Card"}
                </a>
              </div>
              {this.props.disclaimer}
              <div className="row submit-button-container">
                <div className="form-group">
                  <div className="col-xs-12">{this.props.submitButton}</div>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <form
            id="credit-card-form"
            action={this.props.action}
            acceptCharset="UTF-8"
            method="post"
            noValidate="novalidate"
            className="bv-form"
          >
            {this.props.hiddenFields}
          </form>
        )}
        <div
          className={`cc-hosted-fields ${
            this.state.useExistingCreditCard ? "hidden" : ""
          }`}
        >
          <div className="wrapper">
            <form className="form">
              {this.state.isUpdatingCreditCard && (
                <div className="mb16">
                  <a
                    href="#"
                    className={`btn btn-xs btn-clear ${
                      this.props.submitting ? "disabled" : ""
                    }`}
                    onClick={this.toggleCreditCardInput}
                  >
                    {this.state.isUpdatingCreditCard
                      ? "Cancel Update"
                      : "Update Card"}
                  </a>
                </div>
              )}
              <div className="cardName-container field-container">
                <label htmlFor="card-name-field">Cardholder Name</label>
                <div id="card-name-field" className="hosted-field"></div>
                <div className="error"></div>
              </div>
              <div className="cardNumber-container field-container">
                <label htmlFor="card-number-field">Card Number</label>
                <div id="card-number-field" className="hosted-field"></div>
                <div className="error"></div>
              </div>
              <div className="field-columns">
                <div className="cardExpiry-container field-container">
                  <label htmlFor="card-expiry-field">Expiry Date</label>
                  <div id="card-expiry-field" className="hosted-field"></div>
                  <div className="error"></div>
                </div>
                <div className="cardCvv-container field-container">
                  <label htmlFor="card-cvv-field">CVV</label>
                  <div id="card-cvv-field" className="hosted-field"></div>
                  <div className="error"></div>
                </div>
              </div>
              <button
                className={`btn button ${this.props.buttonClass}`}
                disabled={this.props.submitting ? "disabled" : false}
              >
                {this.props.submitting ? (
                  <div>
                    <span>
                      <Spinner />
                    </span>
                    <span className="ml5">{this.props.spinnerText}</span>
                  </div>
                ) : (
                  <div>
                    <i
                      className={`${
                        this.props.buttonIcon || "ficon-credit-card"
                      } mr10`}
                    />
                    <span>{this.props.buttonText}</span>
                  </div>
                )}
              </button>
              <div className="response"></div>
              <div className="disclaimer">Payment securely processed.</div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
