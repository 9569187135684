import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'

import StepSequence from 'common/components/job_wizard/StepSequence'

import Contact from 'common/components/job_wizard/steps/Contact'
import ContactPreference from 'common/components/job_wizard/steps/ContactPreference'

import coreUtils from 'common/src/utils/coreUtils'

import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import MoreDetails from 'common/components/job_wizard/MoreDetails'

const SiteVisit = {
  displayName: 'SiteVisit',

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion) {
    if (data.preferredOutcome === 'quality') {
      if (data.showSuppressants) {
        advanceToStep(ContactPreference)
      } else {
        advanceToStep(MoreDetails)
      }
    } else {
      onStepProgression()
    }

    function advanceToStep(step) {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', step)
    }
  },

  buttonLayout: function() {
    if (data.preferredOutcome === 'quality') {
      return 'continueButton'
    } else {
      return 'default'
    }
  },

  component: React.createClass({
    getInitialState: function() {
      const job = data;

      return {
        siteVisit:        job.siteVisit,
        siteVisitTime:    job.siteVisitTime
      }
    },

    componentDidMount: function() {
      if (data.preferredOutcome === 'quality') {
        this.hideModalCloseButton()
      }

      $('#job-modal').bootstrapValidator({ /* */ })
      this.validator = $('#job-modal').data('bootstrapValidator')

      $('#site-visit-radio-group').radioButtonGroup({activeRowClass: 'active'})
      $('#site-visit-times').radioButtonGroup({activeRowClass: 'active'})
      this.componentDoesValidate()
    },

    hideModalCloseButton: function() {
      $('#job-modal')
        .find('.modal-header')
        .find('.close')
        .addHidden()
    },

    handleSiteVisitAdvance: function(e) {
      this.siteVisitChanged(e)
      this.autoProgress()
    },

    autoProgress: function() {
      this.props.onStepProgression()
    },

    siteVisitChanged: function(e) {
      actions.updateSiteVisit(e.currentTarget.querySelector('[type="radio"]').value)
      coreUtils.scrollModalTo('#site-visit-times')
      this.retestValidationsToRemoveErrorsOn('site_visit')

      if (data.preferredOutcome === 'quality') {
        if (data.siteVisit === 'weekdays' || data.siteVisit === 'weekends') {
          actions.updateSiteVisitTime(this.state.siteVisitTime)
        }
      }
    },

    siteVisitTimeChanged: function(e) {
      actions.updateSiteVisitTime(e.currentTarget.querySelector('[type="radio"]').value)
      this.retestValidationsToRemoveErrorsOn('site_visit_time')
      this.autoProgress()
    },

    validator: undefined,

    retestValidationsToRemoveErrorsOn: function(fieldName) {
      this.validator.$form.data('bootstrapValidator').revalidateField(fieldName)
    },

    componentDoesValidate: function() {
      const validator = this.validator;

      $(function() { // doc ready
        validator.addField('site_visit',
          {
            validators: {
              notEmpty: {
                message: 'Please choose if you require a "Site Visit"'
              }
            }
          }
        )
        validator.addField('site_visit_time',
          {
            validators: {
              notEmpty: {
                message: 'Please choose a "Preferred Time"'
              }
            }
          }
        )
      }) // end doc ready
    },

    render: function() {
      let radioButtonVisible = "col-xs-2 col-sm-2 circle"
      let radioButtonHidden  = `${radioButtonVisible} hidden`

      return (
        <div>
          <div className="form-group">
            <h3 className="mb15 mt10">If your job needs a site visit, what day(s) would suit you?</h3>
            <div className="mb15">
              <small className="info-text"><i>With some jobs, businesses require a site visit or face to face meeting before providing you an accurate quote</i></small>
            </div>
            <div className="radio-button-box minor-bold" id="site-visit-radio-group">
              <div className={"radio  " + ((this.state.siteVisit === "anytime") ? 'active' : '')} onClick={this.handleSiteVisitAdvance}>
                <div className="row" role="tab" data-toggle="tab" href="#site-visit-anytime">
                  <div className="col-xs-10 col-sm-10">Anytime</div>
                  <div className={(this.state.siteVisit === "anytime") ? radioButtonVisible : radioButtonHidden}>
                    <i className="ficon-circle-x pull-right"></i>
                  </div>
                  <input type="radio" defaultChecked={(this.state.siteVisit === "anytime") ? 'checked' : ''} name="site_visit" value="anytime" className="form-control invisible"/>
                </div>
              </div>
              <div className={"radio  " + ((this.state.siteVisit === "weekdays") ? 'active' : '')} role="tab" data-toggle="tab" href="#site-visit-times" onClick={this.siteVisitChanged}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10" id="weekdays">Weekdays</div>
                  <div className={(this.state.siteVisit === "weekdays") ? radioButtonVisible : radioButtonHidden}>
                    <i className="ficon-circle-x pull-right"></i>
                  </div>
                  <input type="radio" defaultChecked={(this.state.siteVisit === "weekdays") ? 'checked' : ''} name="site_visit" value="weekdays" className="form-control invisible"/>
                </div>
              </div>
              <div className={"radio  " + ((this.state.siteVisit === "weekends") ? 'active' : '')} role="tab" data-toggle="tab" href="#site-visit-times" onClick={this.siteVisitChanged}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Weekend</div>
                  <div className={(this.state.siteVisit === "weekends") ? radioButtonVisible : radioButtonHidden}>
                    <i className="ficon-circle-x pull-right"></i>
                  </div>
                  <input type="radio" defaultChecked={(this.state.siteVisit === "weekends") ? 'checked' : ''} name="site_visit" value="weekends" className="form-control invisible"/>
                </div>
              </div>
              <div className={"radio  " + ((this.state.siteVisit === "no_site_visit_required") ? 'active' : '')} onClick={this.handleSiteVisitAdvance}>
                <div className="row" role="tab" data-toggle="tab" href="#site-visit-no">
                  <div className="col-xs-10 col-sm-10">Strictly NO site visit required</div>
                  <div className={(this.state.siteVisit === "no_site_visit_required") ? radioButtonVisible : radioButtonHidden}>
                    <i className="ficon-circle-x pull-right"></i>
                  </div>
                  <input type="radio" defaultChecked={(this.state.siteVisit === "no_site_visit_required") ? 'checked' : ''} name="site_visit" value="no_site_visit_required" className="form-control invisible"/>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-content" onClick={this.autoProgress}>
            <div role="tabpanel" className={"tab-pane form-group " + ((this.state.siteVisit === "weekdays" || this.state.siteVisit === "weekends") ? "active" : "") } id="site-visit-times">
              <div className="form-group mt15">
                <h3 className="mb15 mt15">Preferred Time?</h3>
                <div className="radio-button-box minor-bold" id="site-visit-time">
                  <div className={"radio  " + ((this.state.siteVisitTime === "business-hours") ? 'active' : '')} onClick={this.siteVisitTimeChanged}>
                    <div className="row">
                      <div className="col-xs-10 col-sm-10" id='business-hours'>Business Hours</div>
                      <div className={(this.state.siteVisitTime === "business-hours") ? radioButtonVisible : radioButtonHidden}><i className="ficon-circle-x pull-right"></i></div>
                      <input type="radio" defaultChecked={(this.state.siteVisitTime === "business-hours") ? 'checked' : ''} name="site_visit_time" value="business-hours" className="form-control invisible" />
                    </div>
                  </div>
                  <div className={"radio  " + ((this.state.siteVisitTime === "evenings") ? 'active' : '')} role="tab" data-toggle="tab" href="#site-visit-evenings" onClick={this.siteVisitTimeChanged}>
                    <div className="row">
                      <div className="col-xs-10 col-sm-10">Evenings 5pm Onwards</div>
                      <div className={(this.state.siteVisitTime === "evenings") ? radioButtonVisible : radioButtonHidden}><i className="ficon-circle-x pull-right"></i></div>
                      <input type="radio" defaultChecked={(this.state.siteVisitTime === "evenings") ? 'checked' : ''} name="site_visit_time" value="evenings" className="form-control invisible" />
                    </div>
                  </div>
                  <div className={"radio  " + ((this.state.siteVisitTime === "anytime") ? 'active' : '')} role="tab" data-toggle="tab" href="#site-visit-anytime" onClick={this.siteVisitTimeChanged}>
                    <div className="row">
                      <div className="col-xs-10 col-sm-10">Anytime</div>
                      <div className={(this.state.siteVisitTime === "anytime") ? radioButtonVisible : radioButtonHidden}><i className="ficon-circle-x pull-right"></i></div>
                      <input type="radio" defaultChecked={(this.state.siteVisitTime === "anytime") ? 'checked' : ''} name="site_visit_time" value="anytime" className="form-control invisible" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" className="tab-pane" id="site-visit-no" />
          </div>
        </div>
      )
    }
  })
}

export default SiteVisit
