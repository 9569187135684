import subcategoryIdQuestions1063 from 'common/components/job_wizard/data/subcategory_id_questions/1063'
import subcategoryIdQuestions1064 from 'common/components/job_wizard/data/subcategory_id_questions/1064'
import subcategoryIdQuestions1250 from 'common/components/job_wizard/data/subcategory_id_questions/1250'
import subcategoryIdQuestions128 from 'common/components/job_wizard/data/subcategory_id_questions/128'
import subcategoryIdQuestions130 from 'common/components/job_wizard/data/subcategory_id_questions/130'
import subcategoryIdQuestions142 from 'common/components/job_wizard/data/subcategory_id_questions/142'
import subcategoryIdQuestions155 from 'common/components/job_wizard/data/subcategory_id_questions/155'
import subcategoryIdQuestions159 from 'common/components/job_wizard/data/subcategory_id_questions/159'
import subcategoryIdQuestions161 from 'common/components/job_wizard/data/subcategory_id_questions/161'
import subcategoryIdQuestions165 from 'common/components/job_wizard/data/subcategory_id_questions/165'
import subcategoryIdQuestions168 from 'common/components/job_wizard/data/subcategory_id_questions/168'
import subcategoryIdQuestions209 from 'common/components/job_wizard/data/subcategory_id_questions/209'
import subcategoryIdQuestions2243 from 'common/components/job_wizard/data/subcategory_id_questions/2243'
import subcategoryIdQuestions2357 from 'common/components/job_wizard/data/subcategory_id_questions/2357'
import subcategoryIdQuestions2371 from 'common/components/job_wizard/data/subcategory_id_questions/2371'
import subcategoryIdQuestions2381 from 'common/components/job_wizard/data/subcategory_id_questions/2381'
import subcategoryIdQuestions2408 from 'common/components/job_wizard/data/subcategory_id_questions/2408'
import subcategoryIdQuestions2501 from 'common/components/job_wizard/data/subcategory_id_questions/2501'
import subcategoryIdQuestions2526 from 'common/components/job_wizard/data/subcategory_id_questions/2526'
import subcategoryIdQuestions2661 from 'common/components/job_wizard/data/subcategory_id_questions/2661'
import subcategoryIdQuestions2702 from 'common/components/job_wizard/data/subcategory_id_questions/2702'
import subcategoryIdQuestions2717 from 'common/components/job_wizard/data/subcategory_id_questions/2717'
import subcategoryIdQuestions526 from 'common/components/job_wizard/data/subcategory_id_questions/526'
import subcategoryIdQuestions541 from 'common/components/job_wizard/data/subcategory_id_questions/541'
import subcategoryIdQuestions578 from 'common/components/job_wizard/data/subcategory_id_questions/578'

export default Object.assign({}, subcategoryIdQuestions1063,
                                 subcategoryIdQuestions1064,
                                 subcategoryIdQuestions1250,
                                 subcategoryIdQuestions128,
                                 subcategoryIdQuestions130,
                                 subcategoryIdQuestions142,
                                 subcategoryIdQuestions155,
                                 subcategoryIdQuestions159,
                                 subcategoryIdQuestions161,
                                 subcategoryIdQuestions165,
                                 subcategoryIdQuestions168,
                                 subcategoryIdQuestions209,
                                 subcategoryIdQuestions2243,
                                 subcategoryIdQuestions2357,
                                 subcategoryIdQuestions2371,
                                 subcategoryIdQuestions2381,
                                 subcategoryIdQuestions2408,
                                 subcategoryIdQuestions2501,
                                 subcategoryIdQuestions2526,
                                 subcategoryIdQuestions2661,
                                 subcategoryIdQuestions2702,
                                 subcategoryIdQuestions2717,
                                 subcategoryIdQuestions526,
                                 subcategoryIdQuestions541,
                                 subcategoryIdQuestions578)