import React from 'react'

class OnPageInlineEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      text: props.text,
      editing: false,
      saving: false,
      icon: props.iconClass,
      flashMessageSuccess: null,
      flashMessageError: null
    }
  }

  handleOnClick = (event) => {
    this.setState({ editing: true })
  }

  onChangeHandler = (event) => {
    this.setState({ text: event.target.value })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.iconClass !== this.props.iconClass) {
      this.setState({ iconClass: prevProps.iconClass })
    }
  }

  saveEditedText = () => {
    let dataParams = {}
    dataParams[this.props.columnName] = this.state.text

    $.ajax({
      beforeSend: () => {
        this.setState({ saving: true })
      },
      url: this.props.url,
      data: dataParams,
      method: this.props.request_method || "POST"
    }).done((response) => {
      if (response.success) {
        this.setState({ editing: false, saving: false, flashMessageSuccess: response.success })
      }
      else {
        this.setState({ editing: false, saving: false, flashMessageError: response.error })
      }
    })
  }

  cancelEditing = () => {
    this.setState({ editing: false })
    this.setState({ text: this.props.text })
    this.setState({ saving: false })
  }

  renderNormal = () => {
    return (
      <span>
        {this.state.flashMessageSuccess &&
          <div className="alert alert-success">
            Successfully Updated
          </div>
        }
        {this.state.flashMessageError &&
          <div className="alert alert-warning">
            Error saving page stat
          </div>
        }
        <a className={this.props.fontStyle} onClick={(e) => { e.preventDefault(); this.handleOnClick()}}>
          { this.props.iconTrue != null ?
              <div>
              { this.state.text == "true" ?
                <i className={`${this.props.iconTrue}`}/>
                :
                <i className={`${this.props.iconFalse}`}/>
              }
              </div>
            :
            this.state.text 
          }
        </a>
      </span>
    )
  }

  renderEditableText = () => {
    return (
      <div>
        <div>
          <textarea
            className={`font-16 sm-font-16 text-copy-2`}
            style={{width: '100%'}}
            value={this.state.text}
            onChange={this.onChangeHandler}>
          </textarea>
        </div>
        <div>
          <div>
            <button
              className="btn-main"
              onClick={this.saveEditedText}>{this.state.saving ? 'Saving...' : 'Save'}
            </button>
            <button
              className="btn-warning"
              onClick={this.cancelEditing}>Cancel
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      this.state.editing ?
        this.renderEditableText()
      :
      this.renderNormal()
    )
  }
}

export default OnPageInlineEditor
