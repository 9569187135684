import { jobData } from 'common/components/job_wizard/data/data'
import { EventEmitter } from 'events'

import dispatcher from 'common/src/dispatcher'

const storeEmitter = new EventEmitter();
const CHANGE_EVENT = 'change'
const store = {
    getCategory() {
        return {
            category:           jobData.data.category,
            category_id:        jobData.data.category_id,
            keyword:            jobData.data.keyword
        }
    },

    getSuburb() {
        return { suburb: jobData.data.suburb, suburb_id: jobData.data.suburb_id }
    },

    getExtraDetailsValue(key) {
        return jobData.data.extraDetails[key]
    },

    addChangeListener(callback) {
        storeEmitter.on(CHANGE_EVENT, callback)
    },

    removeChangeListener(callback) {
        storeEmitter.removeListener(CHANGE_EVENT, callback)
    }
}

dispatcher.register(function(payload) {

    switch (payload.type) {
        case 'job:data:reset':
            jobData.resetData()
            break;
        case 'job:data:resetMobile':
            jobData.resetDataMobile()
            break;
        case 'job:data:location_changed':
            jobData.data.suburb_id = payload.value.id
            jobData.data.suburb = payload.value.value
            break;
        case 'job:data:category_changed':
            jobData.data.category = payload.value.value
            jobData.data.category_id = payload.value.id
            break;
        case 'job:data:categories_reset':
            jobData.data.categories = []
            break;
        case 'job:data:description_changed':
            jobData.data.description = payload.value;
            break;
        case 'job:data:file_uploaded':
            jobData.data.attachments.push(payload.value)
            break;
        case 'job:data:budgetinmind_changed':
            jobData.data.budgetInMind = payload.value;
            if (payload.value === "no") {
                jobData.data.budgetPriceRange = null
                jobData.data.budgetType = null
                jobData.data.budgetSetPriceRange = null
            }
            break;
        case 'job:data:budgetsetpricerange_changed':
            var payloadValue = payload.value.replace(/\$|_/g, '');
            jobData.data.budgetSetPriceRange = payloadValue;
            jobData.data.minBudget = payloadValue;
            jobData.data.maxBudget = payloadValue;
            break;
        case 'job:data:budgettype_changed':
            jobData.data.budgetType = payload.value;

            if (jobData.data.budgetType === "set_price") {
                jobData.data.budgetPriceRange = null
            } else {
                jobData.data.budgetSetPriceRange = null
            }

            break;
        case 'job:data:budgetpricerange_changed':
            jobData.data.budgetPriceRange = payload.value;
            jobData.data.minBudget = payload.value.minimumBudget;
            jobData.data.maxBudget = payload.value.maximumBudget;
            break;
        case 'job:data:sitevisit_changed':
            jobData.data.siteVisit = payload.value;
            jobData.data.siteVisitTime = null;
            break;
        case 'job:data:sitevisittime_changed':
            jobData.data.siteVisitTime = payload.value;
            break;
        case 'job:data:quotetype_changed':
            jobData.data.quoteType = payload.value;
            break;
        case 'job:data:subcategory_added':
            jobData.data.categories.push(payload.value);
            if (jobData.data.category_id == 0) {
                jobData.data.category = payload.value.value
                jobData.data.category_id = payload.value.id;
            }
            break;
        case 'job:data:subcategory_removed':
            var tmpCategories = []

            jobData.data.categories.forEach(function(el) {
                if(el.id !== payload.value.id) {
                    tmpCategories.push(el)
                }
            })

            if (jobData.category_id == payload.value.id) {
                var category_id = (tmpCategories[0] === undefined) ? 0 : tmpCategories[0].id;
                var category = (tmpCategories[0] === undefined) ? "" : tmpCategories[0].value;
                jobData.category_id = category_id;
                jobData.category = category;
            }

            jobData.data.categories = tmpCategories
            break;
        case 'job:data:subcategoryother_changed':
            jobData.data.categoryOther = payload.value;
            break;
        case 'job:data:keyword_changed':
            jobData.data.keyword = payload.value;
            break;
        case 'job:data:contactoptions_update':
            jobData.data.canCall = payload.value
            break;
        case 'job:data:schedule_changed':
            jobData.data.schedule = payload.value

            if (payload.value !== "on_a_particular_date") {
                jobData.data.scheduleDate = ""
                jobData.data.scheduleTime = ""
            }
            break;
        case 'job:data:scheduledate_changed':
            jobData.data.scheduleDate = payload.value
            break;
        case 'job:data:email_changed':
            jobData.data.emailAddress = payload.value.replace(/[\s]/g, '')
            break;
        case 'job:data:extra_details_add':
            var key = Object.keys(payload.value)[0]
            jobData.data.extraDetails[key] = payload.value[key]
            break;
        case 'job:data:extra_details_remove':
            var existingItemIndex = jobData.data.extraDetails.indexOf(payload.value)
            if(existingItemIndex > -1) {
                jobData.data.extraDetails.splice(existingItemIndex, 1)
            }
            break;
        case 'job:data:urgent_product_add':
            jobData.data.products.urgent = payload.value
            break;
        case 'job:data:workmanship_product_add':
            jobData.data.products.workmanship = payload.value
            break;
        case 'job:data:originname_changed':
            jobData.data.originName = payload.value
            break;
        case 'job:data:contact_preference_changed':
            jobData.data.contactPreference = payload.value
            break;
        case 'job:data:quote_estimate_changed':
            jobData.data.quoteEstimate = payload.value
            break;
        case 'job:data:phone_number_update':
            jobData.data.phoneNumber = payload.value
            break;
        case 'job:data:omni_auth_details_update':
            for (var key in payload.value){
              jobData.data.omniAuthDetails[key] = payload.value[key]
            }
            break;
        case 'job:data:omni_auth_details_reset':
            jobData.data.omniAuthDetails = {}
            break;
        case 'job:data:must_create_account_update':
            jobData.data.mustCreateAccount = payload.value
            break;
        case 'job:data:first_name_update':
            jobData.data.firstName = payload.value
            break;
        case 'job:data:back_to_submit_update':
            jobData.data.backToSubmit = payload.value
            break;
        case 'job:data:file_names_update':
            jobData.data.fileNames.push(payload.value)
            jobData.data.fileNames = [...new Set(jobData.data.fileNames.map(a => a))]
            break;
        case 'job:data:omni_auth_provider_update':
            jobData.data.omniAuthProvider = payload.value
            break;
        case 'job:data:consumer_lead_changed':
            var searchKey = Object.keys(payload.value)[0]
            if (jobData.data.consumerLeads.length > 0) {
              for(var n = 0; n <= jobData.data.consumerLeads.length; n++) {
                for(key in jobData.data.consumerLeads[n]) {
                  if (key === searchKey) {
                    jobData.data.consumerLeads[n][key] = payload.value[key]
                  }
                }
              }
            } else {
              jobData.data.consumerLeads.push(payload.value)
            }
            
            break;
        case 'job:data:last_slide_update':
          jobData.data.lastSlide = payload.value
          break;
        case 'job:data:additional_question_update':
          jobData.data.additionalQuestion = payload.value
          break;
        case 'job:data:otp_update':
          jobData.data.otp = payload.value
          break;
        case 'job:data:closure_survey_update': 
          jobData.data.closureSurvey = payload.value
          break;
        case 'job:data:progress_width_update':
          jobData.data.progressWidth = payload.value
          break;
        case 'job:data:preferred_outcome_update':
          jobData.data.preferredOutcome = payload.value
          break;
        case 'job:data:hosted_fields_token_update':
          jobData.data.hostedFieldsToken = payload.value
          break;
        case 'job:data:hosted_fields_user_id_update':
          jobData.data.hostedFieldsUserId = payload.value
          break;
        case 'job:data:premium_listing_amount_update':
          jobData.data.premiumListingAmount = payload.value
          break;
        case 'job:data:environment_update':
          jobData.data.environment = payload.value
          break;
        case 'job:data:popular_jobs_update':
          jobData.data.popularJobs = payload.value
          break;
    }

    storeEmitter.emit('change');
})

export default store;
