const steps = {
  "2501": { //gyprock
    "steps": [
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Is this a renovation or a new building project?",
            "jobDescriptionKey": "Construction type",
            "validation": {
              "groupName": "construction_type",
              "message": "Please select the service you require.",
            },
            "options": [
              {
                "value": "Renovation",
                "text": "Renovation"
              },
              {
                "value": "New Building",
                "text": "New Building"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Is this an installation or repair job?",
            "jobDescriptionKey": "Service type",
            "validation": {
              "groupName": "service_type",
              "message": "Please select the service you require.",
            },
            "options": [
              {
                "value": "Installation",
                "text": "Installation"
              },
              {
                "value": "Repair",
                "text": "Repair"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "What needs to be installed/repaired?",
            "jobDescriptionKey": "Installation requirement",
            "validation": {
              "groupName": "installation_requirement",
              "message": "Please select what needs to be installed/repaired.",
            },
            "options": [
              {
                "value": "Wall/s",
                "text": "Wall/s"
              },
              {
                "value": "Column/s",
                "text": "Column/s"
              },
              {
                "value": "Ceiling/Roof",
                "text": "Ceiling/Roof"
              },
              {
                "value": "Others",
                "text": "Others"
              }
            ]
          }
        ]
      },
      {
        "title": {
          "text": "Job Details",
          "icon": "ficon-quotes"
        },
        "questions": [
          {
            "type": "Radio",
            "label": "Do you also require a painter for the job?",
            "jobDescriptionKey": "Painter requirement",
            "validation": {
              "groupName": "painter_requirement",
              "message": "Please select.",
            },
            "options": [
              {
                "value": "No painting required",
                "text": "No painting required"
              },
              {
                "value": "Painting required",
                "text": "Painting required"
              }
            ]
          }
        ]
      }
    ]
  }
}

export default steps
