import React from 'react'

import UpdateCreditCardButton from 'common/components/credit_card/UpdateCreditCardButton'

const NewCreditCardForm = React.createClass({
  componentDidMount: function() {
    let $form = $(`#${this.props.formId}`)

    $form.bootstrapValidator({
      fields:{
        'purchase_params[purchase_method][credit_card_attributes][name]': {
          validators: {
            notEmpty: {
              message: 'Please provide the name on the card.'
            },
            regexp: {
              regexp: /^[A-Za-z]+\s[A-Za-z\s]+$/,
              message: 'Please enter your full name. Name must only contain letters and spaces.'
            }
          }
        },
        'purchase_params[purchase_method][credit_card_attributes][number]': {
          validators: {
            notEmpty: {
                message: 'Please provide a credit card number.'
            },
            creditCard: {
                message: 'The credit card number is not valid.'
            }
          }
        },
        'purchase_params[purchase_method][credit_card_attributes][month_year]': {
          validators: {
            notEmpty: {
              message: 'Please provide a month and year.'
            },
            callback: {
              message: 'The expiration date is expired.',
              callback: function(value, validator, $field) {
                  //valid formats: MMYY or MM/YY
                  let isValidFormat = (!isNaN(value) && value.length === 4) || value.length === 5 && value.split('/').filter(Boolean).length === 2
                  if (!isValidFormat) {
                    return {valid: false, message: 'The expiration date is not valid and must be MM/YY.'}
                  }

                  let ccMonth          = parseInt(value.substring(0,2), 10)
                  let ccYear           = parseInt('20' + value.slice(-2), 10)
                  let ccExpiryDate     = new Date(ccYear, ccMonth - 1)
                  let currentDate      = new Date()
                  let currentMonthYear = new Date(currentDate.getFullYear(), currentDate.getMonth())
                  let futureYearLimit  = currentMonthYear.getFullYear() + 10

                  function isValidMonth(month) {
                    return month > 0 && month <= 12
                  }

                  if (!isValidMonth(ccMonth) || ccExpiryDate.getFullYear() > futureYearLimit) {
                    return {valid: false, message: 'The expiration date is not valid.'}
                  }

                  if (ccExpiryDate <= currentMonthYear) {
                    return {valid: false, message: 'The expiration date is expired.'}
                  }

                  return true
                }
            }
          }
        },
        'purchase_params[purchase_method][credit_card_attributes][cvv]': {
          validators: {
            notEmpty: {
                message: 'Please provide a security code.'
            },
            cvv: {
                message: 'The security code number is not valid.'
            }
          }
        },
      },

      submitButtons: 'button[type="submit"], button.js-purchase-submit'

    }).on('success.field.bv', onValidInput)

    function onValidInput(e, data) {
      if(anyFieldsInvalid()) {
        disableSubmit();
      }

      function anyFieldsInvalid() {
        return !data.bv.isValid()
      }

      function disableSubmit() {
        data.bv.disableSubmitButtons(true);
      }
    }

    $form.data('bootstrapValidator').disableSubmitButtons(true) //disable submit buttons on first load
  },

  autoFillSlash: function(inputChar){
    let expiryDateField = $('#' + this.props.formId + ' .js-credit-card-expiry')
    let value       = expiryDateField.val()
    let lastChar    = value.slice(-1)

    let canFill =   value.length == 2 &&
                    lastChar !== "/" &&
                    inputChar.key !== "Backspace";

    if (canFill) {
        expiryDateField.val(value + "/")
    }
  },

  render: function(){
    return (
      <div className="row mb20 js-new-credit-card-form new-credit-card-form">
        <div className="col-xs-12">
          {this.props.isUpdatingCreditCard && <UpdateCreditCardButton isUpdatingCreditCard={this.props.isUpdatingCreditCard} toggleCreditCardInput={this.props.toggleCreditCardInput} />}
          <div className="form-group mb10">
            <input type="text" className="form-control js-credit-card-name js-credit-card-field" name="purchase_params[purchase_method][credit_card_attributes][name]" placeholder="Name on Card"/>
          </div>

          <div className="form-group mb10">
            <input type="text" className="form-control card-number-field js-credit-card-number js-credit-card-field" name="purchase_params[purchase_method][credit_card_attributes][number]" placeholder="Card Number"  />
          </div>

          <div className="row">
            <div className="col-xs-6">
              <input type="text" className="form-control js-credit-card-expiry js-credit-card-field" name="purchase_params[purchase_method][credit_card_attributes][month_year]" placeholder="Expiry" onKeyUp={this.autoFillSlash} />
            </div>

            <div className="col-xs-6">
              <input type="text" className="form-control js-credit-card-cvv js-credit-card-field" name="purchase_params[purchase_method][credit_card_attributes][cvv]" placeholder="Code" />
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default NewCreditCardForm
