import React from 'react'

const LoadingSpinner = function (props) {
  return (
    <div className={`${props.textPos || 'text-center'}`}>
      <div role='status'>
        <svg className={`inline ${props.width || 'w-10'} ${props.height || 'h-10'} ${props.color || 'text-secondary'} animate-spin fill-current`} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
          <circle className='stroke-2' cx='12' cy='12' r='10' stroke='currentColor'></circle>
          <path fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z' strokeWidth='2'></path>
        </svg>
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  )
}

export default LoadingSpinner
