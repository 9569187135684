import React from 'react'

import 'jquery-ui/themes/base/core.css'
import 'jquery-ui/themes/base/menu.css'
import 'jquery-ui/themes/base/autocomplete.css'
import 'jquery-ui/themes/base/theme.css'
import 'jquery-ui/ui/widgets/autocomplete'


class LocationInput extends React.Component {
  componentDidMount() {
    this.onAutocomplete()
  }

  onAutocomplete() {
    $(`.location-${this.props.guId}`).autocomplete({
      source: function (request, cb) {
        $.ajax({
          url:     '/api/suburbs/lookup.json',
          method:  'GET',
          data:    { suburb: request.term },
          beforeSend: cb([{ label: "Loading Matches...", loading: true }])
        })
        .done(function(response) {
          let results = response.map( item => ({ value: item.name, id : item.id }) )
          cb(results)
        })
        .fail(function() {
          document.location = "/500";
        })
      },
      select: this.props.handleAutocompleteSelection,
      open: function() {
        $('.ui-autocomplete').off('menufocus hover mouseover mouseenter');
      }
    })
  }

  render() {
    return(
      <input type="text"
        name="location"
        id={`location-${this.props.guId}`}
        className={`form-control location-${this.props.guId} ${this.props.inputClass}`}
        placeholder="Enter Postcode or Suburb"
        value={this.props.suburb}
        data-suburb-id={this.props.suburbId || "0"}
        onChange={this.props.handleLocationChange}
        autoFocus={this.props.autoFocus}
      />
    )
  }
}

export default LocationInput
